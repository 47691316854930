<template>
    <div class="col col-12 pa-0 py-4">
        <div class="layoutTitle">
            <h4 :class="($refs.inputLayout && $refs.inputLayout.hasDetails && $refs.inputLayout.errorBucket.length > 0) ? 'error--text' : ''">Sélection du layout <span v-if="field.props && field.props.required" style="color:red">&nbsp;*</span></h4>
            <v-input
                v-model="model[fieldName]"
                type="hidden"
                v-bind="field.props"
                ref="inputLayout"
                :rules=[validate]
                class="mb-3 ml-3"></v-input>
        </div>
        <div class="layoutSelector">
            <v-card :ripple="false" v-for="(layout, index) in layouts" @click="selectLayout(layout.id)" :key="'layout_'+index" class="mr-4 cardSelect" :class="(model[fieldName] && model[fieldName] == layout.id) ? 'active': ''">
                <v-card-text>
                    <v-img fluid max-width="200" min-height="100" :src="layout.preview"></v-img>
                    <h4 style="border:0;" class="mt-2 pb-0">{{ layout.name }}</h4>
                    <ul class="mt-2 pl-0">
                        <li v-for="(feature, index) in layout.features" :key="feature.id" style="list-style:none">
                            <v-icon v-if="feature" small color="success" class="ml-2">$check_l</v-icon> 
                            <v-icon v-else small color="error" class="ml-2">$close</v-icon>
                            <span class="ml-2">{{index}}</span>
                        </li>
                    </ul>
                    <v-icon small class="iconCheck" v-if="!model[fieldName] || (model[fieldName] && model[fieldName] != layout.id)">$checkboxOff</v-icon>
                    <v-icon small class="iconCheck" color="success" v-if="(model[fieldName] && model[fieldName] == layout.id)">$checkboxOn</v-icon>
                </v-card-text>
            </v-card>
        </div>
    </div>
</template>

<script>
import validate from '../functions/validations'
import GenericDataService from '@/services/GenericDataService'

export default {
  props: ["model", "fieldName", "field"],
  mixins: [validate],
  data() {
    return {
        layouts:[]
    };
  },
  created() {
      GenericDataService.getData('/landingpage/getGenericLayouts').then((response) => {
          this.layouts = response.data.data
      })
  },
  computed: {
      propsForHiddenField(){
          let props = this._.cloneDeep(this.field.props)
          props.hidden = true

          return props
      }
  },
  methods: {
      selectLayout(id){
          if(this.model[this.fieldName] && this.model[this.fieldName] == id){
            this.model[this.fieldName] = null;
          } else {
            this.model[this.fieldName] = id
          }
          
          this.$forceUpdate()
      }
  },
};
</script>

<style lang="scss">
.layoutTitle {
    display:flex;
    h4 {
        border-bottom:0px;
    }
}
.layoutSelector {
    display: flex;
    justify-content: flex-start;
    .cardSelect {
        cursor:pointer;
        border : 1px solid transparent;
        .iconCheck {
            position:absolute;
            right:5px;
            bottom:5px;
        }
        &.active {
            border : 1px solid #5bb85d;
        }
        &:before {
            background:none !important;
        }
    }
}
</style>