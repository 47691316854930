<template>
    <div style="width:100%;">
        <WorkFlowComponent :key="wfKey" :lib="true" style="position:relative;"  v-if="model.config" :workflow="model" :stats="stats" :operationId="false" @saveWorkflows="" :config="model.config" :isFromLibraryPage="isFromLibraryPage"/>
    </div>
</template>
<script>
import GenericDataService from '@/services/GenericDataService'

import WorkFlowComponent from '@/modules/workflow/views/WorkFlowComponent'
export default {
    components: {WorkFlowComponent},
    props: ["model", "fieldName", "field", "isFromLibraryPage"],  
    data() {
        return {
            stats:{},
            wfKey:0
        }
    },  
    created() {
        if(this.$route.params.operation_id && this.model.id){
            this.operationId = this.$route.params.operation_id
            GenericDataService.getData('/workflow/'+this.model.id+'/getStats').then((response) => {
                this.stats = response.data.data
                this.wfKey++
            })
        }
    },
    mounted() {
        if(this.$route.params.operation_id && this.model.id){
            this.operationId = this.$route.params.operation_id
            GenericDataService.getData('/workflow/'+this.model.id+'/getStats').then((response) => {
                this.stats = response.data.data
                this.wfKey++
            })
        }
    },
}
</script>
<style lang="scss">

</style>