<template>
    <div>
        <h2>{{$t("configurationLabel")}}</h2>
        <v-form ref="formTrigger" v-model="valid">
            <div v-for="(property, index) in config.config" :key="'key_'+index" >
                <div v-if="!property.hidden">
                    <span v-if="index != 'is_config' && index != 'to_reconfigure'" class="label"><b>{{$t(index)}}</b></span>
                    <v-text-field v-if="index != 'is_config' && property.type && property.type == 'number'"
                    :rules="[v => (v != '') ? true : $t('requiredFieldError')]" 
                    type="number" solo flat filled dense required :label="$t('choose'+index)"
                    v-model="config.config[index].value" class="input-form"></v-text-field>

                    <v-checkbox v-if="index != 'is_config' && property.type && property.type == 'checkbox' && index != 'to_reconfigure'" v-model="config.config[index].value" :label="$t(index)" class="mt-0"></v-checkbox>

                    <span v-if="index != 'is_config' && index == 'to_reconfigure' && !$route.params.operation_id && $route.params.operationtype_id" class="label"><b>{{$t(index)}}</b></span>
                    <v-checkbox v-if="index != 'is_config' && property.type && property.type == 'checkbox' && index == 'to_reconfigure' && !$route.params.operation_id && $route.params.operationtype_id" v-model="config.config[index].value" :label="$t(index)" class="mt-0"></v-checkbox>
                    <div v-if="index != 'is_config' && property.type && property.type == 'checkboxGroup' && index != 'to_reconfigure'" class="mb-2">
                        <span v-for="(item, subindex) in property.items">
                            <v-checkbox hide-details v-model="config.config[index].items[subindex].value" :label="$t(subindex)" class="mt-0"></v-checkbox>
                        </span>
                    </div>
                    <div v-if="index != 'is_config' && property.type && property.type == 'switch'" class="d-flex align-center">
                        <v-radio-group class="mt-0" v-model="config.config[index].value" >
                            <template v-if="!property.properties">
                                <v-radio
                                    :label="$t(property.falseValue)"
                                    :value="property.falseValue"
                                ></v-radio>
                                <v-radio
                                    :label="$t(property.trueValue)"
                                    :value="property.trueValue"
                                ></v-radio>
                            </template>
                            <template v-else>
                                <v-radio
                                v-for="(property, index) in property.properties"
                                    :label="$t(property)"
                                    :value="property"
                                    :key="'property_'+index+'_'+property"
                                ></v-radio>
                            </template>
                            
                        </v-radio-group>
                    </div>
                    <v-radio-group row v-model="config.config[index].value" v-if="index != 'is_config' && property.type && property.type == 'radio'">
                        <v-radio
                            v-for="val in config.config[index].items"
                            :key="val"
                            :label="$t(val)"
                            :value="val"
                            @change="updateHiddens(val, config.config[index].items)"
                        ></v-radio>
                    </v-radio-group>
                    <v-range-slider
                        v-model="config.config[index].value"
                        v-if="index != 'is_config' && property.type && property.type == 'range'"
                        :min="config.config[index].params.min ? config.config[index].params.min : '0'"
                        :max="config.config[index].params.max == 'NOW' ? (new Date().getFullYear()) : (config.config[index].params.max ? config.config[index].params.max : '9999999')"
                        :step="config.config[index].params.step ? config.config[index].params.step : '1000'"
                        hide-details
                        required
                        class="align-center"
                    >
                        <template v-slot:prepend>
                            <v-text-field
                                :value="config.config[index].value[0]"
                                class="mt-0 pt-0 input-form"
                                hide-details
                                solo flat filled dense
                                single-line
                                readonly
                                style="width: 75px"
                                @change="$set(config.config[index].value, 0, $event)"
                            ></v-text-field>
                        </template>
                        <template v-slot:append>
                            <v-text-field
                                :value="config.config[index].value[1]"
                                class="mt-0 pt-0 input-form"
                                hide-details
                                solo flat filled dense
                                single-line
                                readonly
                                style="width: 75px"
                                @change="$set(config.config[index].value, 1, $event)"
                            ></v-text-field>
                        </template>
                    </v-range-slider>
                    <v-autocomplete :class="config.config[index].required ? 'required input-form' : 'input-form'"    
                        v-else-if="(index != 'is_config' && !property.type) "
                        :rules="config.config[index].required ? [v => (v != '') ? true : $t('mustChooseError')] : []" 
                        :required="config.config[index].required ? true : false"
                        v-model="config.config[index].value" 
                        :multiple="config.config[index].multiple ? config.config[index].multiple : false"
                        solo flat
                        item-value="value"
                        item-text="text"
                        @change="changeSelect(config.config[index])"
                        :disabled="config.config[index].hasOwnProperty('depends_of') && config.config[config.config[index].depends_of].value == ''"
                        :loading="loading"  filled dense :label="$t('choose'+index)" return-object
                        :items="items[index]" @focus="getItems(config.config[index].selectUrl, index)"
                        :no-data-text="$t('no-data-text')">
                        
                        <template v-slot:selection="data">
                            <v-chip outlined small color="primary" v-if="config.config[index].multiple" close close-icon="$close" @click:close="remove(index, data.item)">{{ data.item['to_trad'] ? $t(data.item.text) : data.item.text }}</v-chip>
                            <span v-else>
                                {{ data.item['to_trad'] ? $t(data.item.text) : data.item.text }}
                            </span>
                        </template>
                        <template v-slot:item="{item, attrs, on}">
                            <v-list-item v-on="on" v-bind="attrs"  #default="{ active }">
                                <v-list-item-action v-if="config.config[index].multiple">
                                    <v-checkbox :ripple="false" :input-value="active"></v-checkbox>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-row no-gutters align="center">
                                            {{ item['to_trad'] ? $t(item.text) : item.text }}
                                        </v-row>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>    
                    </v-autocomplete>
                    <span v-else-if="(index != 'is_config' && property.type == '{{depends_type}}' && config.config.operator.value.valueField) ">
                        <v-text-field v-if="config.config.operator.value.valueField == 'number' || config.config.operator.value.valueField == 'text' "
                            :rules="[v => (v != '') ? true : $t('requiredFieldError')]" 
                            :type="config.config.operator.value.valueField"
                            solo flat dense hide-details class="mb-3 input-form required"  :label="property.placeholder ? $t(property.placeholder) : $t('choose'+index)"
                            v-model="config.config[index].value"></v-text-field>
                        <div v-if="config.config.operator.value.valueField == 'select'">
                            <v-autocomplete :items="items[index]" v-model="config.config[index].value" @focus="getItems(config.config.field.value.selectFieldUrl, index)" solo flat dense
                            item-value="value"
                            item-text="text">

                            </v-autocomplete>
                        
                        
                        </div>
                    </span>
                    <v-text-field v-else-if="index != 'is_config' && property.type != 'number' && property.type != 'checkboxGroup' && property.type != 'checkbox' && property.type != 'radio'  && property.type != 'switch' && property.type!='{{depends_type}}'"
                    :rules="[v => (v != '') ? true : $t('requiredFieldError')]" 
                    solo flat dense hide-details class="mb-3 input-form required"  :label="property.placeholder ? $t(property.placeholder) : $t('choose'+index)"
                    v-model="config.config[index].value"></v-text-field>
                </div>
            </div>
        </v-form>
        <v-btn color="primary" v-if="!hideBtn" depressed small @click="recordTriggerParams">Enregistrer</v-btn>
    </div>
</template>
<script>
import GenericDataService from '@/services/GenericDataService'
import { copyFile } from 'fs';
export default {
    name: "WorkflowConfigTrigger",
    props: ['config', 'hideBtn', 'workflow'],
    data() {
        return {
            loading:false,
            valid:false,
            items:{},
        }
    },
    created() {
        if(this.config.config && this.config.config.hasOwnProperty('is_config')){
            for (const property in this.config.config) {
                if (property != 'is_config') {
                    const element = this.config.config[property];
                    if(element.value != ""){
                        this.items[property] = []
                        this.items[property].push(element.value)
                    }
                    if(element.selectUrl && !element.depends_type){
                        this.getItems(element.selectUrl, property)
                    }
                }
            }
            //this.items.push(this.config.config.operation.value)
        }
    },
    methods: {
        changeSelect(select){
            
            if(select.clearOnChange){
                select.clearOnChange.forEach(element => {
                    this.config.config[element].value = ""
                });
            }
            if(select.hideField && select.value.value == select.hideField.value){
                select.hideField.fields.forEach(element => {
                    this.config.config[element].hidden = true
                });
            } else {
                if(select.hideField){
                    select.hideField.fields.forEach(element => {
                        this.config.config[element].hidden = false
                    });
                }
                
            }
        },
        updateHiddens(val, items){           
            items.forEach((element) => {
                if(element == val){
                    this.config.config[element].hidden = false
                } else {
                    this.config.config[element].hidden = true
                }
            })
        },
        getItems(url, index){
            //test si {{workflow_id}}
            if(this.config.config[index].hasOwnProperty('depends_type') && url && url.includes('{{depends_type}}')){
                url = url.replace('{{depends_type}}', (this.config.config[this.config.config[index].depends_type].value.type ? this.config.config[this.config.config[index].depends_type].value.type : this.config.config[this.config.config[index].depends_type].value.value))
            }
            if(this.config.config[index].hasOwnProperty('depends_of')){
                url = url.replace('{{depends_id}}', this.config.config[this.config.config[index].depends_of].value.value)
            }
            if(url && url.includes('{{workflow_id}}')){
                url = url.replace('{{workflow_id}}', this.workflow.id)
            } 
            this.loading = 'primary'
            GenericDataService.getData(url).then((response) => {
                this.items[index] = response.data.data
                this.$forceUpdate()
                this.loading = false
            })
        },
        remove(index, item){
            const indexToRemove = this.config.config[index].value.findIndex((e) => e.value == item.value)
            if(indexToRemove >=0) 
                this.config.config[index].value.splice(indexToRemove, 1)
        },
        recordTriggerParams(){
            if(this.$refs.formTrigger.validate()){
                this.config.config.is_config = true
                if(!this.$route.params.operation_id && this.config.event == "advert_auto" && this.config.config.to_reconfigure.value){
                    this.config.config.is_config = false
                }
                this.$emit('recordTriggerConf');
            }
        },
        // checkIfConfig(){
        //     to_config = false
        //     if(this.config.config){
        //         for (const key in this.config.config) {
        //             if(key != "is_config"){
        //                 const element = this.config.config[key];
        //                 if(element.value){
        //                     if(element.value.text && element.value.text == 'to_config'){
        //                         to_config = true
        //                     } else if( element.value && element.value == 'to_config'){
        //                         to_config = true
        //                     }
        //                 }
        //             }
        //         }
        //     }
        //     return to_config
        // }
    },
}
</script>
<style lang="scss">
    
</style>