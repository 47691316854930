<template>
    <div class="pb-4 entrypointsAdd">
        <div class="d-flex align-center">
            <h5 class="mb-0 pb-0 mr-4">{{ $t('entrypoints') }}</h5>
            <v-menu offset-y left  v-if="$func.hasRight('operationtype/update')">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on"  depressed class="bg-gradient">
                        <v-icon dark small>$plus_l</v-icon>
                        {{ $t('add') }}
                    </v-btn>
                </template>
                <v-list style="z-index:100000">
                    <v-list-item link v-for="(ep_type, index) in entrypoint_type" :key="'entrypoint_'+index" dense @click="openForm('create', createUrl + ep_type, ep_type)">
                        <v-list-item-title>{{ $t(ep_type) }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </div>
        <v-row class="mt-2">
            <v-col cols="4" v-for="(ep, index) in this.model[this.fieldName]" :key="index">
                <v-card>
                    
                    <v-card-text class="d-flex align-center">

                        <v-icon color="primary" v-if="ep.type =='entryApi'" class="entryIcon mr-4 flex-shrink-0">
                            $bracketscurly_d
                        </v-icon>
                        <v-icon color="primary" v-if="ep.type =='entryLanding'" class="entryIcon mr-4 flex-shrink-0">
                            $browser_d
                        </v-icon>
                        <v-icon color="primary" v-if="ep.type =='entryCallIn'" class="entryIcon mr-4 flex-shrink-0">
                            $phone_d
                        </v-icon>
                        <div  style="width:100%;">
                            <h6 style="width:100%;" class="d-flex justify-space-between align-center">
                                {{ $t(ep.type) }}
                                <div>
                                    <v-btn @click="openForm('edit', editUrl + ep.id)" v-if="$func.hasRight('operationtype/update')" text depressed class="square">
                                        <v-icon dark small>$pencil_d</v-icon>
                                    </v-btn>
                                    <!-- Demande #1188904298 => Ne pas mettre la possibilité d'update le modèle de LP -->
                                    <!-- <v-btn v-if="ep.type=='entryLanding' && ep.config.landingpage && ep.config.landingpage.id && $func.hasRight('operationtype/update')" @click="editModel(ep.config.landingpage.id)" text depressed class="square">
                                        <v-icon dark small>$pencil_paintbrush_d</v-icon>
                                    </v-btn> -->
                                    <v-btn v-if="$func.hasRight('operationtype/update')" text class="square" small @click="openConfirm(ep.id)">
                                        <v-icon small>$trash_d</v-icon>
                                    </v-btn>
                                </div>
                            </h6>
                            <p class="mb-0" v-if="ep.config && ep.config.subdomain && ep.config.domain">{{ $t('domain') }} : <b>{{(ep.config.subdomain +"."+ ep.config.domain)}}</b></p>
                            <p class="mb-0" v-if="ep.config && ep.config.demand_type_generated">{{ $t('demand_type') }} : <b>{{$t(ep.config.demand_type_generated)}}</b></p>
                            <p class="mb-0" v-if="ep.config && ep.config.who_manage_new_leads">{{ $t('who_manage_new_leads') }} : <b>{{ $t(ep.config.who_manage_new_leads) }}</b></p>
                            <p class="mb-0" v-if="ep.config && ep.config.online_appointment_booking">
                                {{ $t('online_appointment_booking') }} : 
                                <b>{{ $t(ep.config.online_appointment_booking) }}<span v-if="ep.config.auto_appointment_confirm">{{$t('confirmed')}}</span></b>
                            </p>
                            <!-- <p class="mb-0" v-if="ep.config && ep.config.district">{{ $t('district') }} : <b>{{ ep.config.district }}</b></p> -->
                            <p class="mb-0" v-if="ep.config && ep.config.redirect_number">{{ $t('redirect_number') }} : <b>{{ $t(ep.config.redirect_number) }}</b></p>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <FormDialogComponent v-if="form" ref="componentFormEntryPoint" :form="form" :action="'SET_DATA'" :key="formKey" :isFromLibraryPage="isFromLibraryPage"/>
        <FormDialogComponent v-if="form" ref="componentForm" :form="form" :action="'SET_DATA'"  :isFromLibraryPage="isFromLibraryPage"/>
        <LibraryDialogComponent v-if="formLib" :form="formLib" :libType="libType" @cancel="formLib = null;" @selectModel="selectLP" :libParams="{operation_type_id : model.id}" :showLibraryDialog="showLibraryDialog" @hideLibraryDialog="showLibraryDialog = false;" :isFromLibraryPage="isFromLibraryPage" />
        <v-dialog width="600" v-model="dialogConfirm">
            <v-card>
                <v-toolbar flat dark color="primary" class="toolbar bg-gradient" :style="'height:63px;'">
                    <v-toolbar-title>{{$t('confirmDeleteLabel')}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn depressed outlined color="white" class="mr-3" @click="closeDialog">
                        {{$t('cancelLabel')}}
                    </v-btn>
                    <v-btn depressed outlined color="white" @click="deleteItem">
                        {{$t('confirmLabel')}}
                    </v-btn>
                </v-toolbar>
                <v-card-text class="pt-3">
                    {{$t('confirmDeleteRetroplanningStepText')}}
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import getForm from "@/mixins/mixins";
import GenericDataService from '@/services/GenericDataService'

export default {
    mixins: [getForm],
    components: {
        FormDialogComponent: () => import("@/components/dialogs/FormDialogComponent"), 
        LibraryDialogComponent: () => import("@/components/dialogs/LibraryDialogComponent"),
    },
    props:['model', 'fieldName', 'field', 'isFromLibraryPage'],
    data() {
        return {
            form:null,
            formKey:0,
            entrypoint_type:[],
            createUrl: '/operationtype/' + this.model.id + '/getFormEntryPoints?type=',
            editUrl: '/operationtype/' + this.model.id+ '/getFormEntryPoints?id=',
            showLibraryDialog: false,
            formLib:null,
            libType: "LANDINGPAGE",
            dialogConfirm: false,
            idToDelete: 0,
        }
    },
    created() {
        GenericDataService.getData('/operation/getEntryPointsTypes').then((response) => {
            this.entrypoint_type = response.data.data
        })
    },
    methods: {
        editModel(id){
            this.getForm2('/library/getForm?formName=' + this.libType + '&id=' + id+'&step=["conception"]');
        },
        selectLP(lp){
            this.getForm(this.createUrl+'entryLanding&model='+lp.id+'&opetype='+this.model.id);
        },
        openForm(formType, formUrl, type = null) {
            if(type == 'entryLanding'){
                GenericDataService.getData("/library/getFilters?libType=" + this.libType).then((response) => {
                    this.formLib = response.data.data;
                    this.showLibraryDialog = true;
                    //this.libType = btn.openLibraryDialog;
                    //this.$emit("hideGlobalAddDialog");
                });
            } else {
                this.formType = formType;
                this.formUrl = formUrl;

                this.getForm(formUrl);
            }
        },
        getForm2(url, arrayIds = false, action = false, apiUrl = null, mutation = null, displayForm = true, forceCustomRefresh = false) {
            let payload = {}
            if(arrayIds){
                payload.ids = arrayIds
            }
            this.formUrl = url
            this.$store
              .dispatch("forms/GET_FORM_DATA", {
                url: this.formUrl,
                payload: payload
              })
              .then((e) => {
                this.form = this.$store.state.forms.forms[e.reference];
                if(displayForm) {
                  setTimeout(() => {
                    this.displayForm2(this.form.params.title, action, e.reference, apiUrl, mutation, forceCustomRefresh);
                  }, 100);  
                }
              });
        },
        async displayForm2(title, action, reference, apiUrl = null, mutation = null, forceCustomRefresh = false) {
          await this.$refs.componentFormEntryPoint.open(this.$t(title)).then((response) => {
            //if(response && response.data && response.data.data)
                //this.model[this.fieldName] = response.data.data
          })
            
        },
        closeDialog(){
            this.idToDelete = 0;
            this.dialogConfirm = false;
        },
        deleteItem(){
            GenericDataService.getData('/operationtype/' + this.model.id + '/deleteEntryPoint?id=' + this.idToDelete).then((response) => {
                this.idToDelete = 0;
                this.dialogConfirm = false;
                this.model[this.fieldName] = response.data.data;
            })
        },
        customRefresh(response){
            this.model[this.fieldName] = response.data.data;
        },
        openConfirm(itemId){
            this.idToDelete = itemId;
            this.dialogConfirm = true;
        }
    }
}
</script>
<style lang="scss">
    .entrypointsAdd {
        width:100%;
    }
    .entryIcon {
        border:2px solid var(--v-primary-base); 
        height:40px; 
        width:40px; 
        border-radius:50%;
        .fa-network-wired {
            font-size:18px !important;
        }
    }
</style>