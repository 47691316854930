<template>
    <v-card height="350" class="barComponent" @mouseover="hover = true" @mouseout="hover = false">
        <v-card-title style="height:20%; justify-content:space-between;">
            <h2>{{$t(data.label)}}</h2>
            <v-btn v-show="innerFilters.length > 0" small depressed dark :color="innerFilters.length > 0 ? 'primary' : 'darkgrey'" @click="addFilter">{{$t('filter')}}</v-btn>
        </v-card-title>
        <v-card-text style="height:80%">
            <BarComponent :dataSet="graphData" @selectItem="selectItem" v-if="data.type == 'BarComponent'" style="height:100%; width:100%;"/>
            <LineComponent :dataSet="graphData" @selectItem="selectItem" v-else-if="data.type == 'LineComponent'" style="height:100%; width:100%;"/>
            <HorizontalBarComponent :dataSet="graphData" @selectItem="selectItem" v-else style="height:100%; width:100%;"/>
        </v-card-text>
    </v-card>
</template>
<script>
import BarComponent from '@/components/graphs/BarComponent'
import HorizontalBarComponent from '@/components/graphs/HorizontalBarComponent'
import LineComponent from '@/components/graphs/LineComponent'

export default {
    name:"barContactStatsComponent",
    components:{ BarComponent, HorizontalBarComponent, LineComponent },
    props:['data' , 'filters'],
    data() {
        return {
            hover: false,
            default_colors:['#3366CC','#DC3912','#FF9900','#109618','#990099','#3B3EAC','#0099C6','#DD4477','#66AA00','#B82E2E','#316395','#994499','#22AA99','#AAAA11','#6633CC','#E67300','#8B0707','#329262','#5574A6','#3B3EAC'],
            backgroundRefs:[],
            innerFilters:[],
            graphData:{},
        }
    },
    mounted() {
        this.defineColors();
    },
    created() {
        this.defineColors();
    },
    methods: {
        defineColors() {
            let color;
            if(this.data.backgroundColor) {
                color = this.data.backgroundColor
            } else {
                color = this.default_colors[Math.floor(Math.random()*this.default_colors.length)]
            }
            let backgroundColors = [];
            this.labels.forEach((label, index) => {
                backgroundColors.push(color) 
                if(this.filters && this.filters.includes(label) && !this.innerFilters.includes(index)){
                    this.innerFilters.push(index)
                } 
            });
            this.backgroundRefs = backgroundColors
            this.updateColors();
        },
        selectItem(index){
            let tmpIndex = Object.keys(this.data.datas)[index];

            if(!this.data.datas.notFilterable && !this.data.datas[tmpIndex].notFilterable) {
                if(this.innerFilters && this.innerFilters.includes(index)) {
                    let indexToRemove = this.innerFilters.findIndex((e) => e == index)
                    this.innerFilters.splice(indexToRemove, 1);
                } else {
                    this.innerFilters.push(index);
                }
                this.updateColors();
            }
        },
        updateColors(){
            this.backgroundRefs.forEach((color, index) => {
                if(this.innerFilters.length > 0){
                    if(this.innerFilters.includes(index)){
                        this.backgroundRefs[index] = color.substring(0,7)
                    } else {
                        this.backgroundRefs[index] = color.substring(0,7) + "55"
                    }
                } else {
                    this.backgroundRefs[index] = color.substring(0,7)
                }
            });
            this.graphDataFct();
        },
        graphDataFct(){
            let graphData = {}
            graphData.datasets = [];

            if(this.data.datas.datasets) {
                graphData = this.data.datas;
            } else {
                let dataset = {};
                dataset.label = this.data.datasetLabel ? this.$t(this.data.datasetLabel) : "";
                dataset.data = Object.values(this.data.datas).map(function (obj) { return obj.value });
                dataset.backgroundColor =  this.backgroundRefs;
                graphData.datasets.push(dataset);
                graphData.labels = this.labels;
            }

            this.graphData = graphData
           
        },
        addFilter() {
            this.$emit('addFilter', this.data.label, this.innerFilters);
            this.innerFilters = [];
        }
    },
    computed: {
        labels() {
            return Object.values(this.data.datas).map(function (obj) { return obj.to_trad ? this.$t(obj.label) : obj.label; }, this);
        }
    },
}
</script>
<style lang="scss">
    .barComponent {
        h2 {
            margin-bottom:0;
            align-items: center;
        }
    }
</style>