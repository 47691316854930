<template>
  <div class="col col-12 pa-0 input-form">
    <span v-if="field.title" class="label"><b>{{ $t(field.title) }}</b><span v-if="field.props && field.props.required"
        style="color:red">&nbsp;*</span></span>
    <v-menu v-model="modal" allow-overflow min-width="auto" min-height="auto" :close-on-content-click="false" offset-y
      style="flex-shrink:0; flex-grow:0;">
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on" @click="modal = true" style="width:48%;">
          <v-text-field solo dense clearable validate-on-blur :props="field.props" :rules="[validate]"
            ref="textareaDate" flat v-model="dateComputed" class="pt-0 mt-0"
            style="min-height:38px; flex-shrink:0; flex-grow:0;" :label="$t('dateLabel')">
            <template v-slot:prepend-inner>
              <v-icon small color="primary">
                $calendar_d
              </v-icon>
            </template>
          </v-text-field>
        </span>
      </template>
      <v-card class="width:auto; ">
        <v-card-text>
          <vc-date-picker :attributes="attributes" update-on-input @input="okHandler" :minDate="minDate" :maxDate="maxDate"  v-model='date' :model-config="modelConfig" ref="rangePicker" class="pa-2"
            style="background-color:transparent; border:none;" :key="'calendar_' + this.fieldName + '_' + this.calendarKey" />
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import validate from '../functions/validations'
import { format, parse } from 'date-fns'
import dateManipulation from '@/mixins/dateManipulation'
import infoTooltip from '@/components/infoTooltip';

const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd';
const DEFAULT_SHOWING_FORMAT = 'dd/MM/yyyy';
const DEFAULT_RETURN_FORMAT = 'yyyy-MM-dd';

export default {
  props: ["model", "fieldName", "field"],
  components: { infoTooltip },
  mixins: [validate, dateManipulation],
  data() {
    return {
      datePickerProps: {},
      modal: false,
      date: '',
      minDate: "",
      maxDate: "",
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD', // Uses 'iso' if missing
      },
      calendarKey: 0,
      attributes: [
        {
          key: 'today',
          highlights: 'red',
          dates: new Date(),
        }
      ]
    };
  },
  created() {
    let datePickerProps = {
      locale: this.lang
    };

    if (this.field && this.field.datePickerProps && this.field.datePickerProps.allowedDates && this.model[this.field.datePickerProps.depends_of] && this.model[this.field.datePickerProps.depends_of] != "" && this.model[this.field.datePickerProps.depends_of] != null && !this.model[this.fieldName]) {
      datePickerProps["allowed-dates"] = val => val >= this.model[this.field.datePickerProps.depends_of].split(' ')[0];
    }
    if (this.field && this.field.props && this.field.props.datePickerProps && this.field.props.datePickerProps.minDate) {
      this.minDate = this.field.props.datePickerProps.minDate
    }
    if (this.field && this.field.props && this.field.props.datePickerProps && this.field.props.datePickerProps.maxDate) {
      this.maxDate = this.field.props.datePickerProps.maxDate
    }
    if (this.field && this.field.props && this.field.props.datePickerProps) {
      this.datePickerProps = Object.assign({}, datePickerProps, this.field.props.datePickerProps);
    }
  },
  mounted() {
    this.init();
  },
  watch: {
    model: {
      deep: true,
      handler(val, oldval) {
        if ((val[this.fieldName] && oldval[this.fieldName]) && (val[this.fieldName] !== oldval[this.fieldName])) {
          this.date = this.model[this.fieldName].split(' ')[0];
        }
        if (this.field.datePickerProps && this.field.datePickerProps.allowedDates && this.model[this.field.datePickerProps.depends_of] && this.model[this.field.datePickerProps.depends_of] != "" && this.model[this.field.datePickerProps.depends_of] != null) {
          if (!this.field.datePickerProps.noInit && (!this.model[this.fieldName] || (new Date(this.model[this.field.datePickerProps.depends_of]) > new Date(this.model[this.fieldName])))) {
            this.date = this.model[this.field.datePickerProps.depends_of].split(' ')[0];
            this.model[this.fieldName] = this.model[this.field.datePickerProps.depends_of].split(' ')[0];
          }
          this.minDate = this.model[this.field.datePickerProps.depends_of].split(' ')[0];
          let datePickerProps = {
            "allowed-dates": val => val >= this.model[this.field.datePickerProps.depends_of].split(' ')[0]
          }
          this.datePickerProps = Object.assign({}, datePickerProps, this.field.props.datePickerProps);
        }
        this.calendarKey++;
      }
    }
  },
  methods: {
    init() {
      if (!this.model[this.fieldName]) {
        return;
      }

      let initDate;

      if (this.model[this.fieldName] instanceof Date) {
        initDate = this.model[this.fieldName];
      } else if (typeof this.model[this.fieldName] === 'string' || this.model[this.fieldName] instanceof String) {
        initDate = new Date(this.model[this.fieldName])
      }

      this.date = format(initDate, DEFAULT_DATE_FORMAT);
    },
    okHandler() {
      this.modal = false;
      this.$set(this.model, this.fieldName, this.formattedReturnDate);
      this.$refs.textareaDate.validate();
    },
    clearHandler() {
      this.modal = false;
      this.$set(this.model, this.fieldName, null);
    },
  },
  computed: {
    // dateComputed() {
    //   let date = ''
    //   if(this.date != ''){
    //     date = this.parseUsDateToFr(this.date) 
    //   }
    //   return date
    // },
    dateComputed: {
      get: function () {
        let date = ''
        if (this.date != '' && this.date != null) {
          date = this.parseUsDateToFr(this.date)
        }
        return date
      },
      set: function (newValue) {
        //console.log(newValue)
        if (!newValue) {
          this.date = null
        } else {
          this.date = this.parseFrDateToUs(newValue)
        }
        this.$set(this.model, this.fieldName, this.date);
        //this.date = this.parseFrDateToUs(newValue)
      }
    },
    lang() {
      if (this.$store.state.auth.currentUser.parameters.lang) {
        return this.$store.state.auth.currentUser.parameters.lang
      } else {
        return this.$store.state.auth.currentUser.profile.lang
      }
    },
    dateShowingFormat() {
      return this.field.props.showingFormat ? this.field.props.showingFormat : DEFAULT_SHOWING_FORMAT
    },
    dateReturnFormat() {
      return this.field.props.returnFormat ? this.field.props.returnFormat : DEFAULT_RETURN_FORMAT
    },
    formattedShowingDate() {
      return this.selectedDate ? format(this.selectedDate, this.dateShowingFormat) : ''
    },
    formattedReturnDate() {
      return this.selectedDate ? format(this.selectedDate, this.dateReturnFormat) : ''
    },
    selectedDate() {
      if (this.date)
        return parse(this.date, DEFAULT_DATE_FORMAT, new Date());

      return null;
    },
  }
};
</script>

<style lang="scss">
.v-application--is-ltr .v-text-field .v-input__prepend-inner {
  padding-right: 15px;
}
</style>