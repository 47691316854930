<template>
        <v-card>
            <v-card-text @mouseover.stop="hover = true" @mouseleave.stop="hover = false">
                <div v-if="hover" class="hoverCar d-flex flex-column align-center justify-center">
                    <v-btn v-if="from === 'customLibrarySelect'" depressed color="pink" dark class="mb-4" @click="$emit('unselectModel', model)">{{ $t('unselect_model') }}</v-btn>
                    <v-btn v-else depressed color="pink" dark class="mb-4" @click="$emit('selectModel', model)">{{ $t('select') }}</v-btn>
                    <v-btn @click="previewCar = true">{{ $t('preview') }}</v-btn>
                </div>
                <v-img class="thumbCar" @error="model.photo_1 = default_car" shaped :src="model.photo_1 && model.photo_1 != '' ? model.photo_1 : default_car" :lazy-src="default_car"></v-img>
                <div class="d-flex justify-space-between">
                    <div class="mt-2">
                        <b>{{model.brand}} {{model.model}}</b>
                        <div>{{ model.finish }}</div>
                    </div>
                    <div class="cardPrice">
                        <div :class="model.financing.diff_payment < 0 ? 'reduction pa-2 py-1 success' : 'reduction pa-2 py-1 black'" v-if="((formModel && formModel.priceDisplay && formModel.priceDisplay == 'price_payment'  && formModel.alert_id) || (specData && specData.priceDisplay && specData.priceDisplay == 'price_payment')) && model.financing.diff_payment"><h4 class="mb-0 pb-0 white--text">{{ model.financing.diff_payment }} €</h4></div>
                        <span class="d-flex align-end" v-if="((formModel && formModel.priceDisplay && formModel.priceDisplay == 'price_payment') || (specData && specData.priceDisplay && specData.priceDisplay == 'price_payment')) && model.financing.payment"><h2 class="mb-1">{{ model.financing.payment }}€</h2>/{{ $t('month') }}</span>
                        <span class="d-flex align-end" v-if="((formModel && formModel.priceDisplay && formModel.priceDisplay == 'price_ttc') || (specData && specData.priceDisplay && specData.priceDisplay == 'price_ttc'))"><h2 class="mb-1">{{ model.price_TTC }}€</h2></span>
                        
                    </div>
                </div>
            </v-card-text>
            <v-dialog width="800" v-model="previewCar">
                <v-card color="extralightgrey" class="cardOpportunity">
                    <v-card-text class="pa-0">
                        <v-row>
                            <v-col cols="12" class="pa-8">
                            <div class="d-flex align-start">
                                <div style="width:60%">
                                    <h4 class="primary--text d-flex align-center">
                                        {{ model.brand+ " " +model.model }}
                                        <v-chip  class="ml-2" small color="success">{{ model.status == "catalogue" ? $t('inCatalogue') : $t('inStock') }}</v-chip>
                                    </h4>
                                    <ul>
                                        <li v-if="model.version">{{$t('version')}} : <span class="black--text">{{model.version}}</span></li>
                                        <li v-if="model.motorisation">{{$t('motorisation')}} : <span class="black--text">{{model.motorisation}}</span></li>
                                        <li v-if="model.finish">{{$t('finition')}} : <span class="black--text">{{model.finish}}</span></li>
                                        <li v-if="model.body">{{$t('carrosserie')}} : <span class="black--text">{{model.body}}</span></li>
                                        <li v-if="model.color">{{$t('color')}} : <span class="black--text">{{model.color}}</span></li>
                                        <li v-if="model.plate">{{$t('immat')}} : <span class="black--text">{{model.plate}}</span></li>
                                        <li v-if="model.energy">{{$t('energy')}} : <span class="black--text">{{model.energy}}</span></li>
                                        <li v-if="model.gear_type">{{$t('geartype')}} : <span class="black--text">{{model.gear_type}}</span></li>
                                        <li v-if="model.fiscal_power">{{$t('puissanceFiscale')}} : <span class="black--text">{{model.fiscal_power}} CV</span></li>
                                        <li v-if="model.real_power">{{$t('real_power')}} : <span class="black--text">{{model.real_power}} CV</span></li>
                                        <li v-if="model.mileage">{{$t('km')}} : <span class="black--text">{{model.mileage}} km</span></li>
                                        <li v-if="model.date_MEC && model.date_MEC != '1970-01-01'">{{$t('dateMEC')}} : <span class="black--text">{{parseUsDateToFr(model.date_MEC)}}</span></li>
                                        <li v-else-if="model.year">{{$t('dateMEC')}} : <span class="black--text">{{model.year}}</span></li>
                                    </ul>
                                
                                </div>
                                <div class="px-4"  style="width:40%">
                                    <div class="proposalImg mb-6">
                                        <v-img  @error="model.photo_1 = default_car" :src="model.photo_1 ? model.photo_1 : default_car"></v-img>
                                    </div>
                                    <v-card color="lightgrey" width="auto" class="no-shadow d-block py-4">
                                        <v-card-text class="d-flex align-end justify-center pa-2">
                                            <div :class="model.financing.diff_payment < 0 ? 'reduction pa-2 py-1 success' : 'reduction pa-2 py-1 black'" v-if="((formModel && formModel.priceDisplay && formModel.alert_id && formModel.priceDisplay == 'price_payment') || (specData && specData.priceDisplay && specData.priceDisplay == 'price_payment')) && model.financing.diff_payment"><h4 class="mb-0 pb-0 white--text">{{ model.financing.diff_payment }} €</h4></div>
                                            <h1 class="mb-0" v-if="((formModel && formModel.priceDisplay && formModel.priceDisplay == 'price_payment') || (specData && specData.priceDisplay && specData.priceDisplay == 'price_payment')) && model.financing.payment" style="height:auto; min-height:unset; ">
                                                <b>{{model.financing.payment}} €</b>
                                            </h1>
                                            <span v-if="((formModel && formModel.priceDisplay && formModel.priceDisplay == 'price_payment') || (specData && specData.priceDisplay && specData.priceDisplay == 'price_payment')) && model.financing.payment" class="black--text">/{{ $t('month') }}</span>
                                            <h1 class="mb-0" v-if="((formModel && formModel.priceDisplay && formModel.priceDisplay == 'price_ttc') || (specData && specData.priceDisplay && specData.priceDisplay == 'price_ttc'))" style="height:auto; min-height:unset; "><b>{{model.price_TTC}} €</b></h1>
                                        </v-card-text>
                                    </v-card>
                                </div>
                            </div>
                        </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-card>
       
</template>

<script>
import dateManipulation from '@/mixins/dateManipulation'
import getForm from "@/mixins/mixins"

export default {
    name: "CCar",
    props: ["model", "from", "heightCard", "formModel", "specData"],
    mixins: [dateManipulation, getForm],
    components: {
    },
    data() {
        return {
            form: null,
            default_car : "https://cdn.media.upyourbizz.com/data/DEMO/00/7a/8e/30c288d97b-168249937116.jpeg",
            hover:false, 
            previewCar: false
        };
    },
    methods: {
        imageUrlAlt(event) {
            event.target.src = this.default_car
        }
    },
}
</script>
<style lang="scss">
    .thumbCar {
        border-radius:5px;
    }
    .hoverCar {
        position:absolute;
        z-index:15;
        top:0;
        left:0;
        width:100%;
        height:100%;
        background-color: rgba(255,255,255,0.6)
    }
    .cardPrice {
        position:relative;
        .reduction {
            position:absolute;
            border-radius:10px;
            //background-color: var(--v-success-base);
            top:-15px;
            right:0px;
            h4 {
                font-size: 14px;
                font-weight:bold !important;
                height:auto; 
                min-height:unset; 
                border-bottom:0;
            }
        }
    }
    
    .cardOpportunity {
        overflow:hidden;
        h1 {
            line-height: 32px !important;
        }
        h2 {
            border-bottom:0;
            text-transform: none;
            height:auto;
            min-height:unset;
            font-size:22px;
        }
        h4 {
            border-bottom:0;
            text-transform: none;
            height:auto;
            min-height:unset;
            margin-bottom : 5px;
        }
        ul {
            padding-left:0;
            list-style:none;
            li{
                margin-bottom:5px;
            }
        }
        .proposalImg {
            border-radius:10px;
            overflow:hidden;
        }
        .reduction {
            position:absolute;
            border-radius:10px;
            top:-10px;
            right:10px;
            h4 {
                font-size: 18px;
                font-weight:normal !important;
                height:auto; 
                min-height:unset; 
            }
        }
        .input-form .v-input__slot {
            background-color: #FFF !important;
        }
    }
</style>
