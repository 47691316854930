<template>
    <div class="col col-12 pa-0 input-form">
        <span v-if="field.title" class="label">
            <b>{{$t(field.title)}}</b>
            <span v-if="field.props && field.props.required" style="color:red">&nbsp;*</span>
            <infoTooltip v-if="field.tooltip" :tooltip="field.tooltip" />
        </span>
        <div class="row">
            <div :class="(otherField.props.hideLabel ? 'hideLabel' : '') + ' col-' + (otherField.props.col ? otherField.props.col : (12 / Object.keys(field.fields).length)) + (index === Object.keys(field.fields)[0] ? ' pr-0 br-0' : ' pl-0 bl-0')" v-for="(otherField, index) in field.fields" :key="index">
                <FormComponentList :field="otherField" :fieldName="index" :model="model" />
            </div>
        </div>  
    </div>
</template>

<script>
import infoTooltip from '@/components/infoTooltip';

export default {
  components: {
    FormComponentList: () => import('@/components/forms/FormComponentList'),
    infoTooltip
  },
  props: ["model", "fieldName", "field"],
  data() {
    return {
    };
  },
  created() {
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style lang="scss">
    .br-0 {
        .v-input__slot {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
    .bl-0 {
        .v-input__slot {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
    .hideLabel {
        .label {
            display: none;
        }
    }
</style>