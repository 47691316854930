<script>
import { Doughnut, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  extends: Doughnut,
  props: ['dataSet', 'legend', 'percent_color', 'disableTooltip', 'percent_total'],
  data() {
      return {
          options: {
            responsive:true,
            maintainAspectRatio: false, 
            onClick:this.handle,
            layout: {
                padding: {
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0
                }
            },
            legend: {
                display: this.legend == false ? false : true,
                position: 'bottom',
                labels: {
                    usePointStyle: true
                },
                onClick: this.handleLegend,
                onHover: function(event, legendItem) {
                   var chart = this.chart;
                    var index = legendItem.index;
                    var segment = chart.getDatasetMeta(0).data[index];
                    chart.tooltip._active = [segment]
                    chart.tooltip.update()
                    chart.draw()
                },
                onLeave: function(event, legendItem) {
                    var chart = this.chart;
                    var index = legendItem.index;
                    chart.tooltip._active = []
                    chart.tooltip.update()
                    chart.draw()
                }
            },
            tooltip: {
                enabled: this.disableTooltip ? false : true
            },
            title:{
                display: false,
                text: "test"
            },
            animation: {
                duration:0,
            },
            scales: {
                
            },
            tooltips: {
                enabled: this.disableTooltip ? false : true,
                callbacks: {
                    label: function(tooltipItem, data) {
                        if(data['datasets'][0]['data'][tooltipItem['index']] == 100)
                            return data['labels'][tooltipItem['index']];
                        else 
                            return data['labels'][tooltipItem['index']] + " : " + data['datasets'][0]['data'][tooltipItem['index']];
                    }
                },
            }
          }
      }
  },
  watch: {
    //   chartData : {
    //       handler(val){
    //         this.renderChart(this.chartData, this.options) 
    //       }
    //   }
  },
  computed: {
      chartData: function() {
          const data = this.dataSet;
          data.datasets.forEach(dataset => {
            let newColors = []
            dataset.backgroundColor.forEach(color => {
                let newColor;
                if(this.$vuetify.theme.themes.light[color]){
                    newColor = this.$vuetify.theme.themes.light[color]
                } else {
                    newColor = color
                }
                newColors.push(newColor)
            })
            dataset.backgroundColor = newColors 
          });
            
          return data
      }
  },
  methods: {
    reRender(){
        this.renderChart(this.chartData, this.options)
    },
    handleLegend(event, legendItem, legend){
        this.$emit('selectItem', legendItem);
    },
    handle (point, event) {
       
    	const item = event[0]
        if(item && item._chart){
            this.$emit('selectItem', item._index);
        }
         
        // this.$emit('on-receive', {
        //     index: item._index,
        //     backgroundColor: item._view.backgroundColor,
        //     value: this.values[item._index]
        // })
    },
    test(chart, total, progression){
        var width = chart.chart.width;
        var height = chart.chart.height;
        var legendHeight = chart.legend.height;
        var ctx = chart.chart.ctx;

        ctx.restore();
        var fontSize = this.percent_color ? (height / 110).toFixed(2) : (height / 150).toFixed(2);
        ctx.font = fontSize + "em sans-serif";
        ctx.textBaseline = "middle";
        ctx.fillStyle = this.percent_color ? this.percent_color : "#000";
        
        var text = (total) + (this.percent_color ? "%" : "");
        var textMeasure = ctx.measureText(text);
        let textHeight = textMeasure.actualBoundingBoxAscent + textMeasure.actualBoundingBoxDescent
        var textX = Math.round((width - ctx.measureText(text).width) / 2);
        if(progression || progression == 0){
            var textY = ((height - legendHeight) / 2) - (textHeight / 2) + 4;
        } else {
            var textY = ((height - legendHeight) / 2); 
        }
        

        ctx.fillText(text, textX, textY);
        ctx.save();
        if(progression || progression == 0){
            
            ctx.restore();
            var fontSize = (height / 250).toFixed(2);
            ctx.font = fontSize + "em sans-serif";
            ctx.textBaseline = "middle";
            let textProgress = "";
            if(progression > 0){
                textProgress = "▲ +"+progression+"%"
                ctx.fillStyle = "#5bb85d";
            } else if (progression < 0){
                textProgress = "▼ "+progression+"%"
                ctx.fillStyle = "#d9544f";
            } else if (progression == 0) {
                textProgress = "► +"+progression+"%"
                ctx.fillStyle = "#000000";
            }
            
            let textProgressX =  Math.round((width - ctx.measureText(textProgress).width) / 2);
            ctx.fillText(textProgress, textProgressX, textY + textHeight + 3);
            ctx.save();
        }
    }
  },
  created() {
      window.addEventListener('resize', this.reRender);
  },
  destroyed() {
      window.removeEventListener('resize', this.reRender);
  },
  mounted () {
     
      if(this.dataSet && this.dataSet.hasOwnProperty('total')){
            let vm = this
            this.addPlugin({
                id: 'my-plugin',
                beforeDraw: function(chart) {
                    vm.test(chart, (vm.percent_total ? vm.percent_total : vm.dataSet.total), vm.dataSet.progression)
                } 
            })
      }
        
        setTimeout(() => {
            if(this.chartData.label && this.chartData.label !=""){
                this.options.title.text = this.chartData.label
            } else {
                this.options.title.text = "";
            }
            if(this.$refs.canvas)
                this.renderChart(this.chartData, this.options)  
        }, 50);
        
  },
  
}
</script>

<style lang="scss">
    
</style>