<template>
    <div>
        <div v-for="variable in variables" :key="variable.text">
            <span v-if="!variable.name">
                <v-checkbox hide-details v-model="variable.selected" @change="$emit('varsHaveChanged')" class="ml-6" :label="variable.text+' : '+variable.value"></v-checkbox>
            </span>
            <span v-else>
                <v-expansion-panels depressed class="no-shadow">
                    <v-expansion-panel class="no-shadow">
                        <v-expansion-panel-header class="pt-6 pb-0" style="min-height:18px; text-transform:unset;"><h3 style="text-transform:unset; font-weight:normal; font-size:16px;">{{variable.name}}</h3></v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <TemplateVariableRecursiveComponent v-on="$listeners" :variables="variable.items"/>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </span>
        </div>
    </div>
</template>
<script>
export default {
    name:"TemplateVariableRecursiveComponent",
    props:['variables'],
    data() {
        return {

        }
    }
}
</script>
<style lang="scss">
    
</style>