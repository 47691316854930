<template>
    <div class="col col-12 pa-0 vCustomVariableModel">
    <v-row >
        <v-col cols="6">
            <span class="label black--text"><b>{{$t('searchspecificvariable')}}</b></span>
            <v-text-field v-model="searchInList" @input="filterList" :placeholder="$t('searchspecificvariable')" solo flat dense class="input-form mt-2"></v-text-field>
            <div class="mt-2 listFields">
                <div class="listFieldsContent">
                    <div v-for="(categoryContent, category) in data" class="mb-4">
                        <span class="label black--text"><b>{{$t(categoryContent.name)}}</b></span>
                        <template v-for="(field, index) in categoryContent.items">
                            <v-checkbox v-if="!field.hasOwnProperty('items')"  v-show="!field.hide" :key="'field_'+categoryContent.name+'_'+field.value" hide-details v-model="field.selected" :label="field.text+' : '+field.value" @change="toggleValue(field)" dense></v-checkbox>
                            <div class="ml-2" v-else>
                                <div class="label black--text mt-2" v-if="searchInList.length == 0">{{$t(field.name)}}</div>
                                <template v-for="(field, index) in field.items">
                                    <v-checkbox v-if="!field.hasOwnProperty('items')" v-show="!field.hide" :key="'field_'+categoryContent.name+'_'+field.value" hide-details v-model="field.selected" :label="field.text+' : '+field.value" @change="toggleValue(field)" dense></v-checkbox>
                                    <div class="ml-2" v-else>
                                        <div class="label black--text mt-2" v-if="searchInList.length == 0">{{$t(field.name)}}</div>
                                        <template v-for="(field, index) in field.items">
                                            <v-checkbox v-if="!field.hasOwnProperty('items')" v-show="!field.hide" :key="'field_'+categoryContent.name+'_'+field.value" hide-details v-model="field.selected" :label="field.text+' : '+field.value" @change="toggleValue(field)" dense></v-checkbox>
                                            <div v-else>
                                                <div class="label black--text mt-2" v-if="searchInList.length == 0">{{$t(field.name)}}</div>
                                            <template v-for="(field, index) in field.items">
                                                <v-checkbox v-if="!field.hasOwnProperty('items')" v-show="!field.hide" :key="'field_'+categoryContent.name+'_'+field.value" hide-details v-model="field.selected" :label="field.text+' : '+field.value" @change="toggleValue(field)" dense></v-checkbox>
                                                <div class="ml-2" v-else>

                                                </div>
                                            </template>
                                            </div>
                                        </template>
                                    </div>
                                </template>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </v-col>
        <v-col cols="6"  :key="'variableSelected_'+variablesKey">
            <template v-if="model[fieldName] && model[fieldName].length > 0" :class="dragedElem ? 'dragging' : ''">
                <div 
                :class="handleDropClasses(field)"
                v-for="(field, index) in model[fieldName]" 
                :key="field"
                >
                <div class="selectElement list__elem pa-2 mb-1" :class="{ 'selectElement list__elem--is-dragged': dragedElem && field.value === dragedElem.value }">
                    <span>
                        <v-icon small class="mr-2">$gripvertical_s</v-icon>
                        {{getVarStringSelected(field)}}
                    </span>
                    <v-icon small class="mr-2" @click="removeSelected(field)">$close</v-icon>
                </div>
            </div>
            </template>
            
            <v-alert v-else class="mt-3" border="left" color="info" text>
                {{$t('selectcolumnstoexport')}}
            </v-alert>
        </v-col>
    </v-row>
</div>
</template>

<script>
import GenericDataService from '@/services/GenericDataService';

export default {
    name: "CustomVarComponent",
    props: ["model", "fieldName", "field"],
    data() {
        return {
            checkboxes: {},
            searchInList: "",
            dragedElem: null,
            overElem: null,
            columns: {},
            variables: [],
            variablesGlobal: [],
            variablesKey:0
        }
    },
    created() {
        GenericDataService.getData("/template/getAvailableVariables").then((response) => {
            this.columns = response.data.data;

            let variablesGlobal = response.data.data;
            let model = this.model;
            let fieldName = this.fieldName;

            if(model && model[fieldName] && model[fieldName]) {
                const callback_func = function(variables) {
                    variables.map(function(variable) {
                        if(variable.hasOwnProperty("items")) {
                            callback_func(variable.items);
                        } else {
                            variable.selected = Object.keys(model[fieldName].values).includes(variable.value.replace('{{', '').replace('}}', ''));
                        }
                    });
                }

                callback_func(variablesGlobal);
            }

            this.variablesGlobal = variablesGlobal;
            this.model[this.fieldName] = this.getVariablesSelected();
        });

        if(!this.model[this.fieldName])
            this.model[this.fieldName] = [];

        // this.model[this.fieldName].forEach(function(item) {
        //     this.checkboxes["field_" + item.value] = true;
        // }, this);
    },
    computed: {
        data() {
            let returnColumns = this.columns;
            // for (const [category, columns] of Object.entries(this.columns)) {
            //     for (const column of columns) {
            //         if(!returnColumns[category])
            //             returnColumns[category] = [];

            //         let text = "";
            //         if(column.includes('customUser.')) {
            //             let tmpText = column.split('.', 3);
            //             text = tmpText[2];
            //         } else {
            //             text = this.$t('export_' + category + '.' + column);
            //         }

            //         returnColumns[category].push({
            //             text: text,
            //             value: category + '.' + column,
            //             hide: false
            //         });
            //     }
            // }

            return returnColumns;
        },
        handleDropClasses(item) {
            return item => {
                if (!this.overElem || !this.overElem.value) {
                    return "";
                }
                if (
                    this.overElem.value === item.value &&
                    item.order < this.dragedElem.order
                ) {
                    return "drop-place drop-place--before";
                }
                if (
                this.overElem.value === item.value &&
                item.order > this.dragedElem.order
                ) {
                    return "drop-place drop-place--after";
                }
            };
        }
    },
    methods: {
        getVarStringSelected(value) {
            return '{{'+value+'}}'
        },
        getVariablesSelected() {
            let array = [];
            let variablesGlobal = this.data;

            const callback_func = function(variables) {
                variables.map(function(variable) {
                    if(variable.hasOwnProperty("items")) {
                        callback_func(variable.items);
                    } else {
                        if(variable.selected)
                            array.push(variable.value.replace('{{', '').replace('}}', ''));
                    }
                });
            }

            callback_func(variablesGlobal);

            return array;
        },
        filterList(){
            if(this.searchInList.length > 2){
                this.hideRecursive(this.data);
            } else {
                this.resetHidding(this.data);
            }
        },
        hideRecursive(items){
            items.forEach(element => {
                if(element.hasOwnProperty('items'))
                    this.hideRecursive(element.items)
                else {
                    if(!element.text.toLowerCase().includes(this.searchInList.toLowerCase()) && !element.value.toLowerCase().includes(this.searchInList.toLowerCase())){
                        element.hide = true
                    } else {
                        element.hide = false
                    }
                }
                
            });
        },
        resetHidding(items){
            items.forEach(element => {
                if(element.hasOwnProperty('items'))
                    this.resetHidding(element.items)
                else {
                    element.hide = false
                }
                
            });
        },
        toggleValue(field){
            //const name = "field_"+field.value;
            this.model[this.fieldName] = this.getVariablesSelected();
            this.variablesKey++;
            // if(!field.selected){
            //     field.selected = true
            //     field.order = this.model[this.fieldName].length;
            //     this.model[this.fieldName] = this.getVariablesSelected();
            // } else {
            //     field.selected = false
            //     delete field.order;
            //     //this.recalculateOrder();
            //     this.model[this.fieldName] = this.getVariablesSelected();
            // }
        },
        removeSelected(fieldValue){
            this.unselectRecursive(this.data, fieldValue)
            this.model[this.fieldName] = this.getVariablesSelected();
            this.variablesKey++;
        },
        unselectRecursive(items, fieldValue){
            items.forEach(element => {
                if(element.hasOwnProperty('items'))
                    this.unselectRecursive(element.items, fieldValue)
                else {
                    if(element.value.replace('{{', '').replace('}}', '') == fieldValue)
                        element.selected = false
                }
            });
        },
        recalculateOrder(){
            this.model[this.fieldName].forEach((element, index) => {
                element.order = index + 1
            });
        },
        dragEndClear() {
            this.dragedElem = null;
            this.overElem = null;
        },
        pickupElem(event, elem, index) {
            event.dataTransfer.dropEffect = "move";
            this.dragedElem = { ...elem };
        },
        showDropPlace(event, elem, index) {
            if (elem.value !== this.dragedElem.value) {
                this.overElem = { ...elem };
            } else {
                this.overElem = null;
            }
        },
        moveElem(event, elem, index) {
            this.model[this.fieldName] = this.model[this.fieldName].filter((e) => e.value !== this.dragedElem.value);
            this.model[this.fieldName].splice(index, 0, { ...this.dragedElem });
            this.model[this.fieldName].forEach((item, index) => (item.order = index + 1));
        },
        canShowCategory(fields) {
            if (fields.some(e => e.hide === false))
                return true;

            return false;
        }
    },
}
</script>

<style lang="scss">
.vCustomVariableModel{
    .dragging {
    }
    .selectElement {
        background-color: #EEE;
        border-radius:5px;
        color: black;
        display:flex;
        align-items:center;
        justify-content:space-between;
    }
    .listFieldsContent {
        .v-expansion-panel-content__wrap {
            padding-left:5px;
            padding-right:5px;
        }
    }
    .list__elem {
        cursor: default !important;
        border: 1px solid transparent;
        padding: 5px;
        margin-bottom: 5px;
        transition: all 0.3s ease;
    }
    .list__elem--is-dragged {
        opacity: 0.7;
        border: 1px dashed skyblue;
    }
    .listFields {
        max-height:600px;
        overflow-y:scroll;
        .listFieldsContent {
            overflow:hidden;
        }
    }
    .drop-place {
        position: relative;
        transition: all 0.3s ease;
    }
    .drop-place--before {
        padding-top: 40px;
    }
    .drop-place--after {
        padding-bottom: 40px;
    }
    .drop-place--before:before {
        position: absolute;
        top: 5px;
        left: 0;
        text-align: center;
        border-radius: 5px;
        content: "Déposez ici";
        width: 100%;
        padding: 7px 0;
        opacity: 0.7;
        border: 1px dashed skyblue;
        box-shadow: inset 0px 0px 5px 2px var(--v-success-base);
    }
    .drop-place--after:after {
        position: absolute;
        bottom: 5px;
        left: 0;
        border-radius: 5px;
        text-align: center;
        content: "Déposez ici";
        width: 100%;
        padding: 7px 0;
        opacity: 0.7;
        border: 1px dashed skyblue;
        box-shadow: inset 0px 0px 5px 2px var(--v-success-base);
    }

}
</style>