<template>
    <div class="pb-4 workflowAdd">
        <div class="d-flex align-center">
            <h5 class="mb-0 pb-0 mr-4">{{ $t('workflow') }}</h5>
            <v-btn @click="addWF" depressed class="bg-gradient" v-if="$func.hasRight('operationtype/update')">
                <v-icon dark small>$plus_l</v-icon>
                {{ $t('add') }}
            </v-btn>
        </div>
        <v-row class="mt-2" :key="'workflowContainer_'+workflowKey">
            <v-col cols="4" v-for="(wf, index) in this.model[this.fieldName]" :key="index">
                <v-card>
                    
                    <v-card-text class="d-flex align-center">
                        <v-icon color="primary" class="entryIcon mr-4 flex-shrink-0">
                            $networkwired_d
                        </v-icon>
                        <div style="width:100%;">
                            <h6 class="d-flex align-center justify-space-between">
                                {{ wf.name }}
                                <div>
                                    <v-btn v-if="$func.hasRight('operationtype/update')" @click="previewWFModel(wf)" text depressed class="square">
                                        <v-icon dark small>$eye_d</v-icon>
                                    </v-btn>
                                    <v-btn v-if="$func.hasRight('operationtype/update')" text class="square" small @click="openConfirm(wf.id)">
                                        <v-icon small>$trash_d</v-icon>
                                    </v-btn>
                                </div>
                            </h6>
                            <p class="mb-0" v-if="wf.description">{{ $t('description') }} : <b v-html="wf.description"></b></p>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog width="600" v-model="dialogConfirm">
            <v-card>
                <v-toolbar flat dark color="primary" class="toolbar bg-gradient" :style="'height:63px;'">
                    <v-toolbar-title>{{$t('confirmDeleteLabel')}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn depressed outlined color="white" class="mr-3" @click="closeDialog">
                        {{$t('cancelLabel')}}
                    </v-btn>
                    <v-btn depressed outlined color="white" @click="deleteItem">
                        {{$t('confirmLabel')}}
                    </v-btn>
                </v-toolbar>
                <v-card-text class="pt-3">
                    {{$t('confirmDeleteRetroplanningStepText')}}
                </v-card-text>
            </v-card>
        </v-dialog>
        <LibraryDialogComponent 
            v-if="formLib" 
            :form="formLib" 
            :libType="libType" 
            @cancel="formLib = null;" 
            @selectModel="selectModel"
            :libParams="{operation_type_id : model.id}" 
            :showLibraryDialog="showLibraryDialog" 
            @hideLibraryDialog="showLibraryDialog = false;"
            :isFromLibraryPage="isFromLibraryPage" />
        <LibraryPreviewComponent v-if="previewModel" :libType="libType" :model="previewModel" :openPreviewDialog="openPreviewDialog" @close="openPreviewDialog = false"/>
    </div>
</template>
<script>
import getForm from "@/mixins/mixins";
import GenericDataService from '@/services/GenericDataService'

export default {
    mixins: [getForm],
    props:['model', 'fieldName', 'field', 'isFromLibraryPage'],
    components: {
        LibraryDialogComponent: () => import("@/components/dialogs/LibraryDialogComponent"),
        LibraryPreviewComponent: () => import("@/components/LibraryPreviewComponent"),
    },
    data() {
        return {
            form:null,
            showLibraryDialog: false,
            formLib:null,
            libType: "WORKFLOW",
            dialogConfirm: false,
            idToDelete: 0,
            previewModel: false,
            openPreviewDialog: false,
            workflowKey:0
        }
    },
    methods: {
        previewWFModel(model){
            this.previewModel = model
            this.openPreviewDialog = true;
        },
        addWF(){
            GenericDataService.getData("/library/getFilters?libType=" + this.libType).then((response) => {
                this.formLib = response.data.data;
                this.showLibraryDialog = true;
            });
        },
        selectModel(model){
            GenericDataService.postData("operationtype/"+this.model.id+"/addWorkflow", model).then((response)=> {
                this.model[this.fieldName] = response.data.data;
                this.workflowKey++;
            })
        },
        
        closeDialog(){
            this.idToDelete = 0;
            this.dialogConfirm = false;
        },
        deleteItem(){
            GenericDataService.getData('/operationtype/' + this.model.id + '/deleteWorkflow?id=' + this.idToDelete).then((response) => {
                this.idToDelete = 0;
                this.dialogConfirm = false;
                this.model[this.fieldName] = response.data.data;
                this.workflowKey++;
            })
        },
        openConfirm(itemId){
            this.idToDelete = itemId;
            this.dialogConfirm = true;
        }
    },
}
</script>
<style lang="scss">
    .workflowAdd{
        width:100%;
    }
</style>