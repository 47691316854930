<template>
    <v-card min-height="350" class="donutComponent" @mouseover="hover = true" @mouseout="hover = false">
        <v-card-title style="height:20%; justify-content:space-between;">
            <h2>{{$t(data.label)}}</h2>
            <v-btn v-show="innerFilters.length > 0" small depressed dark :color="innerFilters.length > 0 ? 'primary' : 'darkgrey'" @click="addFilter">{{$t('filter')}}</v-btn>
        </v-card-title>
        <v-card-text style="padding: 0;" v-if="graphData.datasets[0].data && graphData.datasets[0].data.length">
            <DonutComponent :dataSet="graphData" :legend="false" @selectItem="selectItem" style="height:200px;"/>
        </v-card-text>
        <v-card-text v-if="graphData.datasets[0].data && graphData.datasets[0].data.length">
            <div class="legend mr-6">
                <div v-for="(legend, index) in this.labels" @click="selectItem(index)" style="cursor:pointer" :key="'legend_'+index">
                    <span :style="'width: 20px; height:10px; display:inline-block; background-color:'+backgroundRefs[index]"></span> {{legend}}
                </div>
            </div>
        </v-card-text>
        <v-card-text v-else>
            <v-alert border="left" color="info" text>
                {{ $t('emptyItemArrayInfoMessage') }}
            </v-alert>
        </v-card-text>
    </v-card>
</template>

<script>
import DonutComponent from '@/components/graphs/DonutComponent'
export default {
    name:"donutContactStatsComponent",
    components:{DonutComponent},
    props:['data', 'filters'],
    data() {
        return {
            hover: false,
            default_colors: ["#FAEBD7","#00FFFF","#7FFFD4", "#F5F5DC","#FFE4C4","#000000","#FFEBCD","#0000FF","#8A2BE2","#A52A2A","#DEB887","#5F9EA0","#7FFF00","#D2691E","#FF7F50","#6495ED","#FFF8DC","#DC143C","#00FFFF","#00008B","#008B8B","#B8860B","#A9A9A9","#006400","#BDB76B","#8B008B","#556B2F","#FF8C00","#9932CC","#8B0000","#E9967A","#8FBC8F","#483D8B","#2F4F4F","#00CED1","#9400D3","#FF1493","#00BFFF","#696969","#1E90FF","#B22222","#FFFAF0","#228B22","#FF00FF","#DCDCDC","#F8F8FF","#FFD700","#DAA520","#ADFF2F","#F0FFF0","#FF69B4","#CD5C5C","#4B0082","#FFFFF0","#F0E68C","#E6E6FA","#FFF0F5","#7CFC00","#FFFACD","#ADD8E6","#F08080","#E0FFFF","#FAFAD2","#90EE90","#D3D3D3","#FFB6C1","#FFA07A","#20B2AA","#87CEFA","#778899","#B0C4DE","#FFFFE0","#00FF00","#32CD32","#FAF0E6","#FF00FF","#66CDAA","#0000CD","#BA55D3","#9370DB","#3CB371","#7B68EE","#00FA9A","#48D1CC","#C71585","#191970","#F5FFFA","#FFE4E1","#FFE4B5","#FFDEAD","#000080","#FDF5E6","#808000","#6B8E23","#FFA500","#FF4500","#DA70D6","#EEE8AA","#98FB98","#AFEEEE","#DB7093","#FFEFD5","#FFDAB9","#CD853F","#FFC0CB","#DDA0DD","#B0E0E6","#FF0000","#BC8F8F","#4169E1","#8B4513","#FA8072","#F4A460","#2E8B57","#FFF5EE","#A0522D","#C0C0C0","#87CEEB","#6A5ACD","#708090","#FFFAFA","#00FF7F","#4682B4","#D2B48C","#008080","#D8BFD8","#FF6347","#40E0D0","#EE82EE","#F5DEB3","#FFFFFF","#F5F5F5","#FFFF00","#9ACD32"],
            backgroundRefs: [],
            innerFilters: [],
            graphData: {},
        }
    }, //"#F0FFFF","#F0F8FF",
    mounted() {
        this.defineColors();
    },
    created() {
        this.defineColors();
    },
    methods: {
        defineColors() {
            let backgroundColors = [];
            this.labels.forEach((label, index) => {
                let indexColor = Math.floor(Math.random()*this.default_colors.length)
                backgroundColors.push(this.default_colors[indexColor]) 
                this.default_colors.splice(indexColor, 1);
                if(this.filters && this.filters.includes(label) && !this.innerFilters.includes(index)){
                    this.innerFilters.push(index)
                } 
            });
            if(this.data.backgroundColor){
                this.backgroundRefs = this.data.backgroundColor
            } else {
                this.backgroundRefs = backgroundColors
            }
            this.updateColors();
        },
        selectItem(index){
            let tmpIndex = Object.keys(this.data.datas)[index];

            if(!this.data.datas[tmpIndex].notFilterable) {
                if(this.innerFilters && this.innerFilters.includes(index)){
                    let indexToRemove = this.innerFilters.findIndex((e) => e == index)
                    this.innerFilters.splice(indexToRemove, 1);
                } else {
                    this.innerFilters.push(index);
                }
                this.updateColors();
            }
        },
        updateColors(){
            this.backgroundRefs.forEach((color, index) => {
                if(this.innerFilters.length > 0){
                    if(this.innerFilters.includes(index)){
                        this.backgroundRefs[index] = color.substring(0,7)
                    } else {
                        this.backgroundRefs[index] = color.substring(0,7)+"55"
                    }
                } else {
                    this.backgroundRefs[index] = color.substring(0,7)
                }
            });
            this.graphDataFct();
        },
        graphDataFct(){
            let graphData = {}
            graphData.datasets = [];

            let dataset = {};
            dataset.label = "";
            dataset.data = Object.values(this.data.datas).map(function (obj) { return obj.value });
            dataset.backgroundColor =  this.backgroundRefs;
            graphData.datasets.push(dataset);
            graphData.labels = this.labels;

            this.graphData = graphData;
        },
        addFilter() {
            this.$emit('addFilter', this.data.label, this.innerFilters);
            this.innerFilters = [];
        }
    }, 
    computed: {
        labels() {
            return Object.values(this.data.datas).map(function (obj) { return this.data.to_trad || obj.to_trad ? this.$t(obj.label) : obj.label; }, this);
        }
    },
}
</script>
<style lang="scss">
    .donutComponent {
        h2 {
            margin-bottom:0;
            align-items: center;
        }
        .legend {
            max-height: 100%;
        }
    }
</style>