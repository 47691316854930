<template>
    <div class="d-flex justify-center col-12">
        <div class="col col-7 pa-0 input-form">
            <div class="banner  mb-4">
                <div class="imgContent mr-6">
                    <v-img
                            :src= "publicPath+'img/marketing-direct.png'"
                            height="120"
                            max-width="120"
                            contain
                    ></v-img>
                </div>

                <div class="content-text">
                    <p class="bannerTitle">{{$t('contactListStepTitle')}}</p>
                    <p class="mb-0" v-html="$t('contactListStepComment')" ></p>
                </div>
            </div>
            <v-btn  depressed class="bg-gradient mb-4" @click="displayUpload = true">
                <v-icon small color="white" class="mr-3">$plus_l</v-icon>
                {{$t('ImportContact')}}
            </v-btn>
            <importContactComponent @closeModal="closeModal" :displayUpload="displayUpload" :operationId="model['operation_id']" />
            <retroplanningContactListComponent v-if="retro.hasOwnProperty('name')" :retroplanning="retro" @addList="addList($event, retro)" @removeListFromLP="removeListFromLP($event, retro)"/>
            <addListDialogComponent :operationId="model.operation_id ? model.operation_id : false" :displayAddList="displayAddList" :retroplanning="retroplanningToUpdate" :excludedIds="listIdToExclude" :entities="entities" @addToList="addToList" @close="closeAddListDialog" />
        </div>
    </div>
</template>
<script>
import GenericDataService from "@/services/GenericDataService";
import axiosHttp from "@/http-common";
import validate from "@/components/forms/functions/validations";
import retroplanningContactListComponent from '@/modules/operations/operationsComponents/retroplanningContactListComponent'
import addListDialogComponent from '@/modules/operations/operationsComponents/addListDialogComponent'
import importContactComponent from '@/components/importContactComponent'
export default {
    props: ["model", "fieldName", "field"],
    mixins: [validate],
    components: {
        retroplanningContactListComponent,
        addListDialogComponent,
        importContactComponent
    },
    data() {
        return {
            publicPath: process.env.BASE_URL,
        loadingData: null,
        items: [],
        formUrl: "",
        form: null,
        totalContact: 0,
        targetedContact: 0,
        plannedContact: 0,
        retro : {},
        retroplanningToUpdate: {},
        displayAddList: false,
        listIdToExclude: [],
        entities: "",
        displayUpload:false,
        };
    },
    watch: {
        'model': {
        handler: function(val, oldVal) {
            this.loadStats();
        },
        deep: true
        },
    },
    mounted() {
        if(this.field.addForm && this.field.addForm.formUrl) this.formUrl = this.field.addForm.formUrl;
        if (this.field.props && this.field.props.items) this.items = this.field.props.items;
        this.loadData();
    },
    methods: {
        addList(contactlist, retro) {
            this.retroplanningToUpdate = retro
            this.listIdToExclude = retro.config.hasOwnProperty('contact_list') ? retro.config.contact_list : []
            this.displayAddList = true
        },
        refreshDataContactsList(){
            this.loading = true
            GenericDataService.getData('/retroplanning/' + this.model['id'] + '/get').then((response) => {
                this.retro = response.data.data
                GenericDataService.getData('operation/' + this.model['operation_id'] + '/getEntitySelectList2').then((response) => {
                    this.entities = response.data.data
                    this.loading = false
                })
                
            })
        },
        removeListFromLP(listId, retro) {
             this.loading = true
            let newRetroplanning = this._.cloneDeep(retro)
            newRetroplanning.config.contact_list.splice(newRetroplanning.config.contact_list.findIndex((e) => e == listId), 1);
            GenericDataService.postData('operation/'+this.model['operation_id']+'/setConfigRetroPlanning', {campaign : newRetroplanning}).then((response) => {
                this.refreshDataContactsList()
                 this.loading = false
            })
        },
        addToList($event, retroplanning){
            let newRetroplanning = this._.cloneDeep(retroplanning)
            if(newRetroplanning.config.hasOwnProperty('contact_list')){
                $event.forEach(id => {
                    newRetroplanning.config.contact_list.push(id)
                });
            } else {
                newRetroplanning.config.contact_list = $event;
            }
            if(this.model.hasOwnProperty('config.contact_list')){
                $event.forEach(id => {
                    this.model['config.contact_list'].push(id);
                });
            } else {
                this.model['config.contact_list'] = $event;
            }
            GenericDataService.postData('operation/'+this.model['operation_id']+'/setConfigRetroPlanning', {campaign : newRetroplanning}).then((response) => {
                this.refreshDataContactsList()
                this.retroplanningToUpdate = {}
                this.listIdToExclude = []
                this.displayAddList = false
            })
        },
        closeAddListDialog(){
            this.retroplanningToUpdate = {}
            this.listIdToExclude = []
            this.displayAddList = false
        },
        closeModal(reload = false) {
            this.displayUpload = false;
        },
        loadData() {
            GenericDataService.getData('/operation/'+this.model['operation_id']+'/getEntitySelectList2').then((response) => {
                this.entities = response.data.data
                GenericDataService.getData('/retroplanning/' + this.model['id'] + '/get').then((response) => {
                    this.retro = response.data.data
                });
            })
            
            // if (this.field.selectDataUrl && (this.loadingData === null || this.field.selectDataUrl.match(/{{(.*?)}}/g))) {
            //     this.items = [];
            //     this.loadingData = true;

            //     axiosHttp.http
            //     .get(this.field.selectDataUrl.replace(/{{(.*?)}}/g, this.replaceFilter))
            //     .then((response) => {
            //         this.loadingData = false;
            //         if (response.data && response.data.data) {
            //         for (let item of response.data.data) {
            //             if (item.hasOwnProperty("text") && item.hasOwnProperty("value")) {
            //             if (this.field["to_trad"]) item.text = this.$t(item.text);
            //             this.items.push(item);
            //             }
            //         }
            //         }

            //         // Permet de sélectionner le / les items au retour de l'ajax
            //         var modelItem = this.items.find(function(item) {
            //         if(typeof this.model[this.fieldName] !== "undefined") {
            //             if(this.field.props.multiple){
            //             if(this.model[this.fieldName].includes(item.value))
            //                 return true;
            //             } else if (this.field.props['return-object']) {
            //             if(item.value === this.model[this.fieldName].value)
            //                 return true;
            //             } else {
            //             if(item.value === this.model[this.fieldName])
            //                 return true;
            //             }
            //         }
            //         }, this);

            //         if (!modelItem) {
            //         delete this.model[this.fieldName]
            //         }
            //     })
            //     .catch(function (error) {
            //     });
            // }
        },
        // Permet de remplacer les filtres dans l'URL Ajax par les valeurs du model
        replaceFilter(match, fieldName) {
            if(this.model[fieldName])
                return this.model[fieldName]
            return '0';
        },
    },
}
</script>