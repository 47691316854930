<template>
    <v-dialog fullscreen scrollable v-model="showTemplateModal" v-if="template && template.template">
        <v-card ref="cardVariable">
            <v-toolbar flat dark color="primary" class="toolbar bg-gradient">
                <v-toolbar-title>{{$t('selectVariableLabel')}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn depressed outlined color="white" class="mr-3" @click="closeDialog">
                    {{$t('cancelLabel')}}
                </v-btn>
                <v-btn depressed outlined color="white" @click="recordVariables">
                    {{$t('confirmLabel')}}
                </v-btn>
            </v-toolbar>
            <v-card-text class="pt-3 fill-height" height="100%">
                <v-container fluid>
                    <v-row fluid>
                        <v-col cols="6" :style="'min-height:'+window.height+'px'">
                            <embed v-if="template.template.pdf && template.template.pdf.url" :src="template.template.pdf.url + '?#view=FitH&scrollbar=0&toolbar=0&navpanes=0'" width="100%" :height="window.height+'px'" type="application/pdf">
                        </v-col>
                        <v-col cols="6" v-if="active">
                            <v-expansion-panels depressed class="no-shadow" v-for="variableCat in variablesGlobal" :key="variableCat.name">
                                <v-expansion-panel class="no-shadow">
                                    <v-expansion-panel-header><h4>{{variableCat.name}}</h4></v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <TemplateVariableRecursiveComponent :variables="variableCat.items"/>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
import GenericDataService from '@/services/GenericDataService';
import TemplateVariableRecursiveComponent from '@/modules/templates/views/TemplateVariableRecursiveComponent';

export default {
    name:"TemplateVariableComponent",
    components:{TemplateVariableRecursiveComponent},
    props:['template', 'templateDuplicated', 'showTemplateModal'],
    data() {
        return {
            publicPath: process.env.BASE_URL,
            maxHeight: "",
            window: {
                height: 0
            },
            active: false,
            variables: [],
            variablesGlobal: [],
        }
    },
    created() {
        GenericDataService.getData("/template/getAvailableVariables?template_id=" + this.template.id).then((response) => {
            this.variables = response.data.data;

            let variablesGlobal = response.data.data;
            let template = this.templateDuplicated ? this.templateDuplicated : this.template;

            if(template && template.variables && template.variables.values) {
                const callback_func = function(variables) {
                    variables.map(function(variable) {
                        if(variable.hasOwnProperty("items")) {
                            callback_func(variable.items);
                        } else {
                            variable.selected = Object.keys(template.variables.values).includes(variable.value.replace('{{', '').replace('}}', ''));
                        }
                    });
                }

                callback_func(variablesGlobal);
            }

            this.variablesGlobal = variablesGlobal;

            this.active = true;
        });
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    computed: {},
    methods: {
        getVariablesSelected() {
            let array = [];
            let variablesGlobal = this.variables;

            const callback_func = function(variables) {
                variables.map(function(variable) {
                    if(variable.hasOwnProperty("items")) {
                        callback_func(variable.items);
                    } else {
                        if(variable.selected)
                            array.push(variable.value.replace('{{', '').replace('}}', ''));
                    }
                });
            }

            callback_func(variablesGlobal);

            return array;
        },
        handleResize() {
            this.window.height = window.innerHeight - 110;
        },
        recordVariables(){
            let variables = this.getVariablesSelected();
            let postData = {"variables":  variables};
            GenericDataService.postData("/template/" + this.template.id + "/refreshVariablesMAILING", postData).then((response) => {
                this.$emit('closeTemplateModal', true);
            });
        },
        closeDialog(){
            this.$emit('closeTemplateModal')
        }
    },
}
</script>
<style lang="scss">
    
</style>