<template>
    <v-menu
        offset-x
        :close-on-content-click="false"
        content-class="elevation-2"
        class="picker"
        v-model="test"
        >
        <template v-slot:activator="{ on, attrs }">
            <a
            text
            v-bind="attrs"
            v-on="on"
            style="display:flex"
            >
            <v-color-picker
                dot-size="22"
                hide-canvas
                hide-inputs
                v-if="color != ''"
                v-model="color"
                swatches-max-height="200"
                class="simple-picker mr-4"
            ></v-color-picker>
            <div v-else class="no-color">
                <div class="red-border"></div>
            </div>
            <v-text-field filled hide-details :label="$t(label)" dense @change="changeColor" v-model="colorText">

            </v-text-field>
            
           
            </a>
        </template>
        <v-list elevation="0">
            <v-list-item>
                <v-color-picker
                    dot-size="22"
                    v-if="!useTransparent"
                    v-model="color"
                    swatches-max-height="200"
                ></v-color-picker>
                <v-color-picker
                    dot-size="22"
                    v-if="useTransparent"
                    v-model="temp"
                    swatches-max-height="200"
                    @input="checkAndSend"
                ></v-color-picker>
                <div class=" btn-zone" v-if="useTransparent">
                    <div class="emptySelector" @click="setColorToNull">
                        <div class="red-border"></div>
                    </div>
                </div>
            </v-list-item>
        </v-list>
    </v-menu>
</template>
<script>
export default {
    name:"colorPicker",
    props:["value", "label", "useTransparent"],
    data() {
        return {
            test:false,
            temp:"#FFFFFF",
            colorText: "",
        }
    },
    created() {
        this.colorText = this.value
    },
    computed: {
        color: {
            get:function(){
                return this.value
            },
            set: function(newVal){
                if(this.colorText != newVal){
                    this.colorText = newVal
                }
                this.$emit('input', newVal)
                this.$emit('change')
            }
        }
    },
    methods: {
        changeColor: function(){
            this.color = this.colorText
        },
        checkAndSend: function(){
            //if(this.value == ""){
                this.$emit('setColor', this.temp)
            //} 
        },
        setColorToNull: function(){
            if(this.value != ""){
                this.$emit('setColor', "")
                this.test = false
            } 
        }
    },
}
</script>
<style lang="scss">
.primary-picker a {
    display:flex !important;
}
    .no-color {
        margin-right:0 !important;
        margin-left:10px;
        width:53px;
        height:53px;
        border-radius:5px !important;
        border: 1px solid var(--v-darkgrey-base) !important;
        background-color: #FFF;
        display:flex;
        justify-content: center;
        align-items: center;
        .red-border {
            width: 80px;
            height:2px;
            background-color: red ;
            transform: rotate(45deg)
        }
    }
    .btn-zone {
        height:100%;
    }
    .picker {
        .v-menu__content {
            min-width: 380px !important;
            .v-list-item {
                align-items:flex-start;
            }
        }
    }
    
    .emptySelector {
        width:40px;
        height:40px;
        border-radius:5px !important;
        border: 1px solid var(--v-darkgrey-base) !important;
        background-color: #FFF;
        display:flex;
        justify-content: center;
        align-items: center;
        margin-left:10px;
        .red-border {
            width: 80px;
            height:2px;
            background-color: red ;
            transform: rotate(45deg)
        }
    }
    .simple-picker {
        .v-color-picker__controls {
            padding:0;
        }
        .v-color-picker__dot {
            margin-right:0 !important;
            margin-left:10px;
            width:53px;
            height:53px;
            border-radius:5px !important;
            border: 1px solid var(--v-darkgrey-base) !important;
        }
        .v-color-picker__sliders {
        display:none !important;
        }
    }
</style>