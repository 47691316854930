<template>
  <div class="col col-12 pa-0 v-file-input">
    <span v-if="field.title" class="label">
        <b>{{$t(field.title)}}</b>
        <span v-if="field.props && field.props.required" style="color:red">&nbsp;*</span>
        <infoTooltip v-if="field.tooltip" :tooltip="field.tooltip" />
    </span>
    
    <div class="thumbnailInput my-4" @click="openDialogMediatheque()">
      <span class="activeHover">
        <div v-if="!model[fieldName] || Object.keys(model[fieldName]).length === 0" class="uploadInput">
          <v-icon large>$paperclip_l</v-icon>
        </div>
      </span>
      <div v-if="model[fieldName] && Object.keys(model[fieldName]).length !== 0" class="uploadInput">
        <embed v-if="model[fieldName] && model[fieldName].url" :src="model[fieldName].url + '?#view=FitH&scrollbar=0&toolbar=0&navpanes=0'" width="100%" :height="window.height+'px'" type="application/pdf">
      </div>
    </div>

    <MediathequeDialogComponent :multiple="field.props.multiple" v-model="model[fieldName]" :openDialogMediatheque="dialogMediatheque" @close="closeDialogMediatheque" :prefilters="prefilters" :availableFilters="availableFilters" :upload="true" :accept="field.props && field.props.accept ? field.props.accept : null" />
  </div>
</template>

<script>
import validate from "../functions/validations";
import MediathequeDialogComponent from "@/components/dialogs/MediathequeDialogComponent";
import infoTooltip from '@/components/infoTooltip';

export default {
  components: { MediathequeDialogComponent, infoTooltip },
  props: ["model", "fieldName", "field"],
  mixins: [validate],
  data() {
    return {
      dialogMediatheque: false,
      prefilters: {},
      availableFilters: [],
      publicPath: process.env.BASE_URL,
      maxHeight: "",
      window: {
        height: 0
      },
    };
  },
  created() {
    this.prefilters = this.field.prefilters ? this.field.prefilters : {};
    this.availableFilters = this.field.availableFilters
      ? this.field.availableFilters
      : [];

    
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  methods: {
    openDialogMediatheque() {
      this.dialogMediatheque = true;
    },
    closeDialogMediatheque: function () {
      this.dialogMediatheque = false;
    },
    handleResize() {
      this.window.height = window.innerHeight - 110;
    },
  },
};
</script>

<style lang="scss">
.thumbnailInput {
  height: 100vh;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
  border-radius: 4px;
  //border: 1px solid #cccccc;
  position:relative;
  .activeHover {
    width: 100%;
    height: 100%;
    position:absolute;
    .upload {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #f0f0f0;
      position:absolute;   
    }
  }
  .uploadInput {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f0f0f0;
    position:absolute;   
  }
  &:hover {
    .activeHover {
      .upload {
        background: #e0e0e0;
      }
    }
  }
}
.listItems {
  list-style: none;
  padding-left:0 !important;
  li {
    padding-left:10px;
    padding-bottom:7px;
    border-bottom:1px solid rgba(0, 0, 0, 0.2);
  }
}
.specifications {
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
</style>