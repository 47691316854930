<template>
  <div class="col col-12 pa-0 input-form">
    <span v-if="field.title" class="label">
        <b>{{$t(field.title)}}</b>
        <span v-if="field.props && field.props.required" style="color:red">&nbsp;*</span>
        <infoTooltip v-if="field.tooltip" :tooltip="field.tooltip" />
    </span>
    <v-autocomplete
      :label="field.title ? $t(field.title) : ''"
      :class="field.props && field.props.required ? '' : ''"
      v-model="model[fieldName]"
      v-bind="field.props"
      :clearable="field.props.clearable == false ? false : true"
      :rules=[validate]
      :no-data-text="$t('no-data-text')"
    >
      <template v-slot:selection="data" v-if="field.props.multiple">
          <v-chip
            v-bind="data.attrs"
            :input-value="data.selected"
            small
            outlined
            color="primary"
            close
            close-icon="$close"
            @click:close="remove(data.item)"
          >
            {{ data.item.text }}
          </v-chip>
        </template>
    </v-autocomplete>
  </div>
</template>

<script>
import validate from '../functions/validations'
import infoTooltip from '@/components/infoTooltip';

export default {
  props: ["model", "fieldName", "field"],
  components: { infoTooltip },
  mixins: [validate],
  data() {
    return {};
  },
  created() {
  },
  methods: {
    remove(item){
      const index = this.model[this.fieldName].findIndex((e) => e == item.value)
      if(index >=0) 
        this.model[this.fieldName].splice(index, 1)
    },
  },
};
</script>

<style lang="scss">
.input-form {
  .v-input__slot {
    background: var(--v-lightgrey-base) !important;
  }
  .label {
    color: #000;
    font-size: 15px;
    display:inline-block;
    margin-bottom:5px;
  }
}
</style>