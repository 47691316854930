<template>
  <div class="col col-12 pa-0 input-form">
    <span v-if="field.title" class="label">
        <b>{{$t(field.title)}}</b>
        <span v-if="field.props && field.props.required" style="color:red">&nbsp;*</span>
        <infoTooltip v-if="field.tooltip" :tooltip="field.tooltip" />
    </span>
      
    <v-textarea 
      :label="field.title ? $t(field.title) : ''"
      v-model="model[fieldName]"
      v-bind="field.props"
      :rules=[validate]
      ></v-textarea>
  </div>
</template>

<script>
import validate from '../functions/validations'
import infoTooltip from '@/components/infoTooltip';

export default {
  props: ["model", "fieldName", "field"],
  components: { infoTooltip },
  mixins: [validate],
  data() {
    return {};
  },
};
</script>

<style lang="scss">
</style>