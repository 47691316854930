<template>
  <div>
    <v-container fluid>
      <v-alert :icon="$store.state.alerts.type === 'success' ? '$check_s' : '$delete'" dense text :type="$store.state.alerts.type" class="mb-0">
        <span v-html="$store.state.alerts.message"></span>
      </v-alert>
    </v-container>
  </div>
</template>

<script>
export default {
  name:"SuccessErrorAlertComponent",
}
</script>

<style lang="scss">

</style>
