<template>
  <div class="col col-12 pa-0  input-form date-input">
    <span v-if="field && field.title" class="label">
        <b>{{ $t(field.title) + (field.tmpTitle ? (' (' + field.tmpTitle + ')') : '') }}</b>
        <span v-if="field.props && field.props.required" style="color:red">&nbsp;*</span>
        <infoTooltip v-if="field.tooltip" :tooltip="field.tooltip" />
    </span>
      
    
    <div class="d-flex" style="justify-content:space-between">
      <v-menu v-model="shown" offset-y min-width="auto" min-height="auto" :close-on-content-click="false" style="flex-shrink:0; flex-grow:0;">
              <template v-slot:activator="{ on, attrs }">
                  <span  v-bind="attrs" v-on="on" @click="shown = true" style="width:48%;">
                  <v-text-field :ref="fieldName+'_date'" :hide-details="hideDetails ? hideDetails : false" solo @input="tryValidate"  :rules="[validate]" flat v-model="dateComputed"  class="pt-0 mt-0" style="min-height:38px; flex-shrink:0; flex-grow:0;" :label="$t('dateLabel')">
                      <template v-slot:prepend-inner>
                          <v-icon small color="primary">
                              $calendar_d
                          </v-icon>
                      </template>
                  </v-text-field>
                  </span>
              </template>
              <v-card class="width:auto; ">
                <v-card-text>
                  <vc-date-picker :attributes="attributes" update-on-input :minDate="minDate" :maxDate="maxDate" @input="inputTest"  v-model='date' :model-config="modelConfig" ref="rangePicker" class="pa-2" style="background-color:transparent; border:none;"/>
                </v-card-text>
              </v-card>
      </v-menu>
      <v-autocomplete :ref="fieldName+'_hour'" :items="hours" :hide-details="hideDetails ? hideDetails : false" v-model="hour" @change="tryValidate" :rules="[validate]" required  clearable solo flat :label="$t('hourLabel')" style="width:48%; flex-grow:0; flex-shrink:0; " :no-data-text="$t('no-data-text')">
        <template v-slot:prepend-inner>
            <v-icon small color="primary">
                $alarm_d
            </v-icon>
        </template>
      </v-autocomplete>
    </div>
  </div>
</template>

<script> 
import validate from '../functions/validations'
import dateManipulation from '@/mixins/dateManipulation'
import infoTooltip from '@/components/infoTooltip';

export default {
  props: ["model", "fieldName", "field", "hideDetails"],
  components: { infoTooltip },
  mixins: [validate, dateManipulation],
  data() {
    return {
      shown:false,
      date:"",
      minDate: "",
      maxDate: "",
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD', // Uses 'iso' if missing
      },
      hour: "",
      hours: [],
      startHour: 5,
      endHour: 23,
      step: 15,
      attributes: [
        {
          highlights: true,
          dates: new Date(),
        }
      ]
    };
  },
  created() {
    if(this.field && this.field.props && this.field.props.datePickerProps && this.field.props.datePickerProps.minDate){
      this.minDate = this.field.props.datePickerProps.minDate
    }
    if(this.field && this.field.props && this.field.props.datePickerProps && this.field.props.datePickerProps.maxDate){
      this.maxDate = this.field.props.datePickerProps.maxDate
    }
    
    if(this.field && this.field.props && this.field.props.timePickerProps && this.field.props.timePickerProps.startHour && this.field.props.timePickerProps.endHour && this.field.props.timePickerProps.step){
      this.startHour = this.field.props.timePickerProps.startHour
      this.endHour = this.field.props.timePickerProps.endHour
      this.step = this.field.props.timePickerProps.step
    }
    
    this.createHours();
  },
  mounted() {
    if(this.model && this.model[this.fieldName] && this.model[this.fieldName] != '') {
      this.date = this.model[this.fieldName].split(' ')[0];
      if(this.model[this.fieldName].split(' ')[1].length > 5)
        this.hour = this.model[this.fieldName].split(' ')[1].slice(0, -3);
      else
        this.hour = this.model[this.fieldName].split(' ')[1];
    } else if(this.model && this.model[this.fieldName] && this.model[this.fieldName] == ''){
      this.date = "";
      this.hour = "";
    }
      
  },
  computed: {
    dateComputed: {
        get: function(){
          let date = ''
          if(this.date != ''){
            date = this.parseUsDateToFr(this.date) 
          }
          return date
        },
        set: function(newValue){
            this.date = this.parseFrDateToUs(newValue)
        }
    },
    fullDate(){
      return this.date+" "+this.hour
    }
  },
  methods: {
    tryValidate(){
      if(this.validate()){
        this.$refs[this.fieldName+"_hour"].errorBucket = []
        this.$refs[this.fieldName+"_date"].errorBucket = []
      }
    },
    createHours(){
      for (let index = this.startHour; index < this.endHour; index++) {
        let minutesStep = 60 / this.step;
        for (let multiplicateur = 0; multiplicateur < minutesStep; multiplicateur++) {
          let newHour = (index < 10 ? '0' : '')+index+":"+((multiplicateur*this.step < 10) ? "0" : "")+multiplicateur*this.step;
          this.hours.push(newHour);
        }
      }
      this.hours.push((this.endHour < 10 ? '0' : '')+this.endHour+":00");
    },
    inputTest(){
      if(this.date != ''){
        this.$refs[this.fieldName+"_date"].errorBucket = []
        if(this.validate()){
          this.$refs[this.fieldName+"_hour"].errorBucket = []
          this.$refs[this.fieldName+"_date"].errorBucket = []
        }
      }
      this.shown = false
    }
  },
  watch: {
    fullDate(val){
      if(this.date && this.hour && this.date != '' && this.hour != ""){
        this.$set(this.model, this.fieldName, val ? this.parseJsDateToDatetimeSql(new Date(val)) : "")
      } else {
        this.$set(this.model, this.fieldName, null)
      }
    },
    model: {
      deep:true,
      immediate: true,
      handler(val, oldval) {
        if(this.model[this.fieldName]) {
          this.date = this.model[this.fieldName].split(' ')[0];
          this.hour = this.model[this.fieldName].split(' ')[1].slice(0, -3);
        }

        if(this.field.datePickerProps && this.field.datePickerProps.allowedDates && this.model[this.field.datePickerProps.depends_of] && this.model[this.field.datePickerProps.depends_of] != "" && this.model[this.field.datePickerProps.depends_of] != null){
          if(!this.field.datePickerProps.noInit && (!this.model[this.fieldName] || this.model[this.fieldName] < this.model[this.field.datePickerProps.depends_of])) {
            this.date = this.model[this.field.datePickerProps.depends_of].split(' ')[0];
            this.hour = this.model[this.field.datePickerProps.depends_of].split(' ')[1].slice(0, -3);
          }
          this.minDate = this.model[this.field.datePickerProps.depends_of].split(' ')[0];
        }
      }
    }
  }
};
</script>

<style lang="scss">
.date-input {
  .v-input__slot{
    min-height:36px;
  }
}
</style>