<template>
  <v-dialog
    :retain-focus="false"
    no-click-animation
    :width="1200"
    transition="fade-transition"
    scrollable
    persistent
    @keydown.esc="$emit('cancel')"
    content-class="vdialogForm"
    v-model="dialog"
  >
    <v-card tile>
      <v-toolbar dark flat class="bg-gradient" height="64">
        <v-btn x-large depressed color="transparent" class="ml-2" @click="$emit('cancel')"><v-icon dark small class="mr-2" >$prev</v-icon> {{ $t('back') }}</v-btn>
      </v-toolbar>
      <v-card-text class="pa-12 d-flex justify-center" style="overflow:hidden">
          <v-card v-for="(item, index) in items" :key="index" rounded="lg" :class="'ma-3 col-'+(12/items.length)" @click="$emit('actionForm', item)">
              <v-card-text>
                  <div class="row">
                      <div class="col-12 d-flex justify-center mb-2" v-if="item.img">
                              <v-img max-height="150" contain :src="'/img/'+item.img"></v-img>
                      </div>
                      <div class="col-12">
                          <div class="row">
                              <div class="col-12  d-flex align-center justify-center">
                                  <h2 class="text-center">
                                      {{ $t(item.label) }}
                                  </h2>
                              </div>
                          </div>
                          <div class="row" v-if="item.text1">
                              <div class="col-12 text-center black--text d-flex align-center justify-center">
                                  {{ $t(item.text1) }}
                              </div>
                          </div>
                          <div class="row" v-if="item.text2">
                              <div class="col-12 text-center d-flex align-center justify-center">
                                  {{ $t(item.text2) }}
                              </div>
                          </div>
                      </div>
                  </div>
              </v-card-text>
          </v-card>
      </v-card-text>
      <div style="flex: 1 1 auto"></div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ActionMenusDialogComponent",
  props: ['items'],
  data() {
    return {
        dialog: true,
        publicPath: process.env.BASE_URL ,
    };
  },
  created() {
  },
  methods: {},
};
</script>

<style lang="scss">
</style>