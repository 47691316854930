<template>
  <div class="row input-form">
    <div class="col-3 pr-0" v-if="field.fields && field.fields.type">
      <span v-if="field.fields.type.title" class="label"><b>{{$t("type")}}</b><span v-if="field.props && field.props.required" style="color:red">&nbsp;*</span></span>
      <v-select
        :label="field.fields.type.title ? $t(field.fields.type.title) : ''"
        v-model="model[fieldName]['type']"
        v-bind="field.fields.type.props"
        solo flat
        :item-text="text"
        :no-data-text="$t('no-data-text')"
      ></v-select>
    </div>
    <div class="col-9" v-if="field.fields && field.fields.number">
      <span v-if="field.fields.number.title" class="label"><b>{{$t(field.fields.number.title)}}</b><span v-if="field.fields.number.props && field.fields.number.props.required" style="color:red">&nbsp;*</span></span>
      <vue-tel-input-vuetify
        :label="''"
        :class="'phone-number ' + (field.fields.number.props && field.fields.number.props.required ? 'required' : '')"
        v-model="phone"
        v-bind="field.fields.number.props"
        placeholder=""
        mode="international"
        :input-options="options"
        v-on="{ ...{}, input }"
        defaultCountry="fr"
        :error="error"
        :error-messages="errorMessage"
        :single-line="false"
        :rules=[validate]
      />
    </div>
  </div>
</template>

<script>
import VueTelInputVuetify from "vue-tel-input-vuetify/lib/vue-tel-input-vuetify";

export default {
  components: {
    VueTelInputVuetify,
  },
  props: ["model", "fieldName", "field", "fromArray"],
  data() {
    return {
      phone: "",
      options: {
        showDialCode: true,
        tabIndex: 0,
      },
      error: false,
      errorMessage: "",
    };
  },
  created() {
    if(!this.model[this.fieldName])
      this.model[this.fieldName] = {};

    if(this.model[this.fieldName])
      this.model[this.fieldName].type = this.model[this.fieldName].type ? this.model[this.fieldName].type : "nc"
  },
  mounted() {
    if(this.model[this.fieldName] && this.model[this.fieldName]['number'])
      this.phone = this.model[this.fieldName]['number'];
  },
  watch: {
    model: function (val, oldval) {
      if((val[this.fieldName] && oldval[this.fieldName]) && (val[this.fieldName] !== oldval[this.fieldName]))
        this.phone = val[this.fieldName] && val[this.fieldName]['number'] ? val[this.fieldName]['number'] : '+33';

      if(this.model[this.fieldName])
        this.model[this.fieldName].type = this.model[this.fieldName].type ? this.model[this.fieldName].type : "nc";
    },
  },
  methods: {
    text(item) {
      return this.field.fields.type["to_trad"] ? this.$t(item.text) : item.text;
    },
    input(value, { number, valid, country }) {
      const re = /^[()\-+0-9\s]*$/;
      var isNotValid = !re.test(number.input);
      
      this.$emit('changeBtnState', true);

      if(((isNotValid || !valid) && (this.phone !== '+44' && this.phone !== '+33' && this.phone !== '+39' && this.phone !== '+31' && this.phone !== '+352' && this.phone !== '+32'))) {
        if(value === "" && this.fromArray !== true) {
          this.error = false;
          this.errorMessage = "";
          this.model[this.fieldName]['number'] = number.e164;
          this.$emit('changeBtnState', false);
        } else {
          this.error = true;
          this.errorMessage = this.$t('errorPatternPhoneNumber');
        }
      } else {
        this.error = false;
        this.errorMessage = "";
        this.model[this.fieldName]['number'] = number.e164;
        this.$emit('changeBtnState', false);
      }
    },
    validate() {
      if(this.field.fields.number.props.required && !this.model[this.fieldName].number) // Si le champ est requis
        return this.$t('requiredField');
      return !this.error;
    }
  },
};
</script>

<style lang="scss">
.phone-number {
  .v-input {
    .v-input__slot {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      min-height:38px !important;
        max-height:38px;
    }
  }
  .country-code {
    .v-select {
      .v-input__slot{
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
        border-top-left-radius: 4px !important;
        border-bottom-left-radius: 4px !important;
        padding:0 12px;
        min-height:38px !important;
        max-height:38px;
      }
      fieldset {
        border-right: none;
      }
      .v-input__append-inner {
        margin-top:7px !important;
      }
    }
  }
  .v-text-field--outlined:not(.v-select) {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    fieldset {
      border-left: none;
    }
  }
  .v-text-field--filled:not(.v-select) {
    border-top-left-radius: 0px;
  }
  .v-text-field--filled.v-input--dense.v-text-field--single-line > .v-input__control > .v-input__slot {
    min-height: 52px;
  }
  .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--single-line .v-input__append-inner {
    margin-top: 14px;
  }
  .vti__flag {
    margin-left: auto !important;
  }
}
</style>