<template>
    <div style="width:100%;">
        <htmlEditorComponent :dialog="true" :currentLanding="model[fieldName].landing" :idLanding="model['id'] ? model['id'] : false" :css="model[fieldName].css" ref="htmlEditor"/>
    </div>
</template>
<script>
import GenericDataService from '@/services/GenericDataService'
import htmlEditorComponent from '@/components/htmlEditorComponent'
export default {
    components: {htmlEditorComponent},
    props: ["model", "fieldName", "field"], 
    methods: {
        
    },   
}
</script>
<style lang="scss">
    
</style>