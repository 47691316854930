<template>
  <div class="v-input--radio">
    <v-radio 
      :label="field.title ? $t(field.title) : ''"
      on-icon="$dot_circle_r" 
      off-icon="$circle_r"
      :class="field.props && field.props.required ? 'required' : ''"
      v-model="model[fieldName]"
      v-bind="field.props"
    ></v-radio>
  </div>
</template>

<script>
import validate from '../functions/validations'

export default {
  props: ["model", "fieldName", "field"],
  mixins: [validate],
  data() {
    return {};
  },
};
</script>

<style lang="scss">
</style>