<template>
    <v-card style="height:100%;">
        <v-card-title @mouseover.stop="hover = true" @mouseleave.stop="hover = false" class="d-flex flex-column">
            <div v-if="hover" :id="'thumbHover_'+model.id" class="hoverThumb d-flex flex-column align-center justify-center">
                <!-- <v-btn v-if="from === 'customLibrarySelect'" depressed color="pink" dark class="mb-4" @click="$emit('unselectModel', model)">{{ $t('unselect_model') }}</v-btn> -->
                <v-btn small :color="'pink'" dark :class="' mb-4 mr-2'" @click="$emit('selectModel', model)">{{ $t('selectCoach') }}</v-btn>
                <v-btn @click="$emit('openPreview', model)" small color="primary" dark class="mb-4 mr-2">{{ $t('preview') }}</v-btn>
            </div>
            <div class="headerCoach mb-2 mt-2">
                <v-avatar size="100">
                    <v-img :src="api+'/user/'+model.id+'/getAvatar?token='+$store.state.auth.idToken+'&nocache=true'" ></v-img>
                </v-avatar>
                <div class="coachGlobalRating" v-if="model.moyenne" >
                    <div class="coachStarRating">
                        <b class="rating white--text">{{ model.moyenne }}</b>
                        <v-icon large color="#ffc107">$star_s</v-icon>
                    </div>
                </div>
            </div>
            <span class=" coachName">{{ model.name +" "+model.firstname }}</span>
            <p class="mb-0" v-if="model.catch_phrase">"{{ model.catch_phrase }}"</p>
            <div v-if="model.skills && model.skills.length > 0" class="text-center mt-3 customChip">
                <v-chip small v-for="(index) in 3" color="#efedff" v-show="model.skills[index - 1]" :key="'skill_'+index" class="mr-2 mb-2">{{ model.skills[index - 1] }}</v-chip>
                <v-chip v-if="model.skills.length > 3" small color="#efedff">+{{ model.skills.length - 3 }}</v-chip>
            </div>
        </v-card-title> 
    </v-card>
</template>
<script>
export default {
    name: "CCoach",
    props: ["model", "from", "heightCard", "formModel", "specData"],
    data() {
        return {
            api:process.env.VUE_APP_API_URL,
            hover: false,
        }
    }
}
</script>
<style lang="scss">
.hoverThumb {
    position:absolute;
    z-index:110;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color: rgba(255,255,255,0.6);
    .v-btn__content {
        font-size:14px !important;
    }
    .disabled {
        pointer-events: none;
    }
}
.customChip {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}
</style>