<template>
    <v-dialog v-model="showLibraryDialog" width="1600" persistent style="overflow:hidden;" :content-class="contentClasses">
        <v-card tile height="800">
            <v-toolbar flat class="bg-gradient" height="64">
                <div style="display:flex; width:100%; height:64px; align-items:center;">
                    <v-btn x-large depressed color="transparent" style="position:absolute;" class="ml-2" dark @click.native="$emit('cancel')" ><v-icon small class="mr-2">$prev</v-icon> {{ $t("back") }}</v-btn>
                    <v-toolbar-title v-if="libType != 'COACH'" class="d-flex align-center justify-center toolbarTitle">{{ $t('select_your_model') }}</v-toolbar-title>
                    <v-toolbar-title v-else class="d-flex align-center justify-center toolbarTitle">{{ $t('select_your_coach') }}</v-toolbar-title>
                </div> 
            </v-toolbar>

            <v-card-text class="pa-8" style="height: calc(100% - 64px);">
                <LibraryComponent :libType="libType" :formLib="form" :coachNumber="coachNumber" :pickerForm="pickerForm" :isFromPopup="true" class="pa-4" v-on="$listeners" :operationCreatedId="operationCreatedId" :libParams="libParams" :isFromLibraryPage="isFromLibraryPage" />
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import LibraryComponent from "@/components/LibraryComponent.vue";

export default {
    name: "LibraryDialogComponent",
    components: { LibraryComponent },
    props: ["form", "libType", "showLibraryDialog", "libParams", "pickerForm", "isFromLibraryPage", "coachNumber", "operationCreatedId", "contentClasses"],
    data() {
        return {
        };
    },
    methods: {},
};
</script>

<style lang="scss">
    .v-dialog__content:has(> .fromExchangeFormComponent) {
        //z-index: 204 !important;
    }
</style>
