<template>
  <div class="col col-12 pa-0">
    <v-checkbox
      :label="field.title ? $t(field.title) : ''"
      :class="field.props && field.props.required ? 'required mt-0' : 'mt-0'"
      v-model="model[fieldName]"
      v-bind="field.props"
      :rules=[validate]
      ></v-checkbox>
  </div>
</template>

<script>
import validate from '../functions/validations'

export default {
  props: ["model", "fieldName", "field"],
  mixins: [validate],
  data() {
    return {};
  },
};
</script>

<style lang="scss">
    .text-transform-none {
        text-transform: none;
    }
</style>