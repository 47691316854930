<template>
  <div class="col col-12 pa-0 input-form v-custom-select" :style="field.props.hidden ? 'display:none; ' : ''">
    <span v-if="field.title" class="label">
        <b>{{$t(field.title)}}</b>
        <span v-if="field.props && field.props.required" style="color:red">&nbsp;*</span>
        <infoTooltip v-if="field.tooltip" :tooltip="field.tooltip" />
    </span>
      
    <v-autocomplete
      :label="field.title ? $t(field.title) : ''"
      :class="field.props && field.props.required ? '' : ''"
      v-model="model[fieldName]"
      v-bind="field.props"
      :clearable="field.props.clearable == false ? false : true"
      :items="this.items"
      :item-value="field.valueField ? field.valueField : 'value'"
      :search-input.sync="search"
      cache-items
      @focus="!loadingData ? loadData : ''"
      @mousedown="!loadingData ? loadData : ''"
      @keydown.enter="launchSearch"
      :loading="loadingData"
      :rules=[validate]
      :no-data-text="$t('no-data-text')"
    >
      <template v-slot:prepend-inner v-if="field.prepend_inner">
        {{ field.prepend_inner.to_trad ? $t(field.prepend_inner.text) : field.prepend_inner.text }}
      </template>
      <template v-slot:prepend-item v-if="formUrl">
        <v-list-item ripple @click="getFormToAdd">
          <v-list-item-content>
            <v-list-item-title> {{ $t("createNew") }} </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mt-2"></v-divider>
      </template>
      <template v-slot:selection="data">
          <v-chip
            v-bind="data.attrs"
            :input-value="data.selected"
            small
             v-if="field.props.multiple"
            outlined
            color="primary"
            close
            close-icon="$close"
            @click:close="remove(data.item)"
          >
            <span>{{ data.item.text }}</span>
          </v-chip>
          <span v-else>
             {{ (field['to_trad'] && !field.selectDataUrl) || data.item.to_trad ? $t(data.item.text) : (data.item.text ?  data.item.text : data.item) }}
          </span>
        </template>
        <template v-slot:item="{item, attrs, on}">
            <v-list-item v-on="on" v-bind="attrs">
                <v-list-item-content>
                    <v-list-item-title>
                        <v-row no-gutters align="center" class="d-flex justify-space-between">
                               {{ (field['to_trad'] && !field.selectDataUrl) || item.to_trad ? $t(item.text) : (item.text ?  item.text : item) }}
                               <v-chip outlined dense small color="error" v-if="item.hasOwnProperty('confirmed') && item.confirmed">{{ $t('inMission') }}</v-chip>
                               <v-chip outlined dense small color="warning" v-else-if="item.hasOwnProperty('assigned') && item.assigned">{{ $t('reserved') }}</v-chip>
                               <v-chip outlined dense small color="black" v-else-if="item.hasOwnProperty('indispo') && item.indispo">{{ $t('unavailable') }}</v-chip>
                        </v-row>
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </template>
    </v-autocomplete>
    <v-container class="pa-0">
      <FormDialogComponent
        v-if="form"
        ref="anotherForm"
        :form="form"
        :action="'SET_DATA'"
      />
    </v-container>
  </div>
</template>

<script>
import axiosHttp from "@/http-common";
import validate from '../functions/validations'
import infoTooltip from '@/components/infoTooltip';

export default {
  props: ["model", "fieldName", "field"],
  mixins: [validate],
  components: {
    infoTooltip,
    FormDialogComponent: () =>
      import("@/components/dialogs/FormDialogComponent"),
  },
  data() {
    return {
      loadingData: null,
      items: [],
      formUrl: "",
      form: null,
      search:"",
      ajaxSearch: false,
      timeoutAjax: null
    };
  },
  mounted() {
    if(this.field.addForm && this.field.addForm.formUrl) this.formUrl = this.field.addForm.formUrl;
    if (this.field.props && this.field.props.items) this.items = this.field.props.items;
    if (this.field.props && !this.field.props.items) this.field.props.items = [];
    this.loadData();
  },
  watch: {
    search(val){
      clearTimeout(this.timeoutAjax);
      //this.timeoutAjax = null
      this.launchSearch()
    }
  },
  methods: {
    launchSearch(){
      this.timeoutAjax = setTimeout(() => {
        if(this.field.props && this.field.props.searchAjax && !this.ajaxSearch) {
          this.ajaxSearch = true
          this.loadData({}, this.search)
        } 
      }, 500);
    },
    remove(item){
      const index = this.model[this.fieldName].findIndex((e) => e == item.value || e.value == item.value)
      if(index >=0) 
        this.model[this.fieldName].splice(index, 1)

    },
    loadData($evt, searchText = null) {
      if (this.field.selectDataUrl && (this.loadingData === null || (!this.loadingData && searchText) || this.field.selectDataUrl.match(/{{(.*?)}}/g)) && !this.field.dontLoadData) {
        this.items = [];
        this.field.props.items = [];
        this.loadingData = true;
        let filters = {};
        if(searchText) {
          filters.search = searchText
        }
        axiosHttp.http
          .post(this.field.selectDataUrl.replace(/{{(.*?)}}/g, this.replaceFilter), {filters : filters})
          .then((response) => {
            this.loadingData = false;
            this.ajaxSearch = false;
            if (response.data && response.data.data) {
                let items = response.data.data.hasOwnProperty('items') ? response.data.data.items : response.data.data;

                if(items) {
                    for (let item of items) {
                        if (item.hasOwnProperty("text") && item.hasOwnProperty("value") || item.hasOwnProperty("header")) {
                            if (this.field["to_trad"] && item.hasOwnProperty("text"))
                                item.text = this.$t(item.text);
                            else if(this.field["to_trad"] && item.hasOwnProperty("header"))
                                item.header = this.$t(item.header);
                            this.items.push(item);
                            this.field.props.items.push(item);
                        } else {
                            this.items.push({ 'text': item, 'value': item });
                            this.field.props.items.push({ 'text': item, 'value': item });
                        }
                    }

                    if(response.data.data.hasOwnProperty('selected') && response.data.data.selected.length > 0) {
                        this.model[this.fieldName] = response.data.data.selected;
                    } else if(items.length === 1 && this.field.props.required) {
                        if(this.field.props.multiple)
                            this.model[this.fieldName] = [this.field.props['return-object'] ? response.data.data[0] : response.data.data[0].value];
                        else {
                            this.$set(this.model, this.fieldName, this.field.props['return-object'] ? response.data.data[0] : response.data.data[0].value);
                        }
                    }
                }
            }

            // Permet de sélectionner le / les items au retour de l'ajax
            var modelItem = this.items.find(function(item) {
              if(typeof this.model[this.fieldName] !== "undefined") {
                if(this.field.props.multiple){
                    var tmpValue = this.model[this.fieldName];

                    if(this.field.props['return-object'])
                        tmpValue = tmpValue.map(function(item) { return item.value });

                    if(tmpValue.includes(item.value))
                        return true;
                } else if (this.field.props['return-object']) {
                  if(item.value === this.model[this.fieldName].value)
                    return true;
                } else if (this.field.valueField){
                  if(item[this.field.valueField] === this.model[this.fieldName].value)
                    return true;
                } else {
                  if(item.value === this.model[this.fieldName])
                    return true;
                }
              }
            }, this);

            if (!modelItem) delete this.model[this.fieldName];
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    // Permet de remplacer les filtres dans l'URL Ajax par les valeurs du model
    replaceFilter(match, fieldName) {
      if(this.field.searchInObject) {
        let searchKeys = fieldName.split('.');
        if(searchKeys.length === 2) // J'ai pas trouvé mieux ^^
          return (this.model[searchKeys[0]] && this.model[searchKeys[0]][searchKeys[1]]) ? this.model[searchKeys[0]][searchKeys[1]] :( this.model[searchKeys[0]] ? this.model[searchKeys[0]] : '0');
      } else if(this.model[fieldName]) {
        return this.model[fieldName];
      }
      return '0';  // Attention problème si id = 0
    },
    //Initialisation de la popup d'add
    getFormToAdd() {
      this.$store
        .dispatch("forms/GET_FORM_DATA", {
          url: this.formUrl.replace(/{{(.*?)}}/g, this.replaceFilter),
        })
        .then((e) => {
          this.form = this.$store.state.forms.forms[e.reference];
        });
      setTimeout(() => {
        this.crtFromAnotherForm();
      }, 200);
    },
    async crtFromAnotherForm() {
      let response = await this.$refs.anotherForm.open(this.form.params.title);
      
      if (response && response.data.data["__selectValue"]) {
        this.items.push(response.data.data["__selectValue"]);
        this.field.props.items.push(response.data.data["__selectValue"]);

        if(this.field.props && this.field.props.multiple) {
          if(!this.model[this.fieldName]){
            this.model[this.fieldName] = []
          }
          this.model[this.fieldName].push(response.data.data["__selectValue"].value);
        } else {
          this.model[this.fieldName] = response.data.data["__selectValue"].value;
        }
      }
    },
  },
};
</script>

<style lang="scss">
  .v-custom-select {
    .v-input__prepend-inner {
      min-width: fit-content;
    } 
  }
</style>