<template>
    <v-dialog v-model="dialog" width="1600" persistent @keydown.esc="cancel" style="overflow:hidden;">
        <v-card tile height="800">
            <v-toolbar flat class="bg-gradient" height="64">
                <v-btn x-large depressed color="transparent" class="ml-2" dark @click.native="cancel" ><v-icon small class="mr-2">$prev</v-icon> {{ $t("back") }}</v-btn>
                <v-spacer></v-spacer>
                <v-toolbar-title><h3 class="white--text">{{title}}</h3></v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text class="pa-8" style="height: calc(100% - 64px);">
                <v-row style="height:100%; overflow: hidden;">
                    <v-col cols="3"  style="height:100%; overflow-y: auto;">
                        <v-card>
                            <v-card-text class="pa-0">
                                <FilterFormComponent v-if="dialog" :form="form" @confirmAction="confirmAction" ref="formComponent" :action="'SET_DATA'" :saveAtInput="true" />
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="9" style="height:100%; overflow-y: auto;" v-scroll.self="scrollTest">
                        <v-alert v-if="!models.data || !models.data.length" border="left" color="info" text>
                            {{$t('noModelLabel')}}
                        </v-alert>
                        <v-row v-else id="innerMedia">
                            <v-col cols="4" v-for="(model, key) in models.data" :key="'lib_'+key">
                                <LibraryComponentList :model="model" :formModel="form.model" :heightCard="280" :component="models.component" :key="key" @selectModel="selectModel" />
                            </v-col>
                            <v-col cols="12" id="loaderCol" class="d-flex justify-center">
                                <v-progress-circular
                                v-if="loading" 
                                :size="50"
                                :width="7"
                                color="primary"
                                indeterminate
                                ></v-progress-circular>
                            </v-col>
                        </v-row>
                    </v-col>
                    
                </v-row>
            </v-card-text>
            <div style="flex: 1 1 auto"></div>
            <div class="LoaderFull" v-if="loadingFull">
                <v-progress-circular
                    :size="50"
                    :width="7"
                    color="primary"
                    indeterminate
                    ></v-progress-circular>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
    name: "VehicleDialogComponent",
    components: {
        FilterFormComponent: () => import("@/components/forms/FilterFormComponent"),
        LibraryComponentList: () => import("@/components/libraryComponent/LibraryComponentList"),
    },
    props: ["form"],
    data() {
        return {
            dialog: false,
            title: null,
            resolve: null,
            models: [],
            page:1,
            loading:false,
            endScroll: false,
            loadingFull: false
        };
    },
    methods: {
        ...mapActions("alerts", ["setAlert"]),
        scrollTest($evt){
            // var test =  document.getElementById('loaderCol').getBoundingClientRect();
            // var elementTop = document.getElementById('innerMedia').getBoundingClientRect().top;
            // var elementBot = document.getElementById('innerMedia').getBoundingClientRect().bottom;
            // var elementHeight = document.getElementById('innerMedia').getBoundingClientRect().height;
            // var outerSize = $evt.srcElement.clientHeight
            if(this.isInViewport('loaderCol') && this.loading == false && !this.endScroll){
                this.loading = true;
                this.page++
                this.confirmAction(false, false, true);
            }
        },
        isInViewport(id) {
            const rect = document.getElementById(id).getBoundingClientRect();
            return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
        },
        open(title) {
            this.dialog = true;
            this.title = title;
            this.confirmAction();
            return new Promise((resolve) => {
                this.resolve = resolve;
            });
        },
        addResult(response){
            this.models.data = this.models.data.concat(response.data.data.data);
            if(this.models.data.length%12 != 0){
                this.endScroll = true
            }

        },
        submit(response) {
            this.models = response.data.data;
            this.loadingFull = false
            if(this.models.data.length%12 != 0){
                this.endScroll = true
            }
        },
        cancel() {
            this.resolve(false);
            this.dialog = false;
            this.models = [];
            this.$store.dispatch("forms/CLEAR_FORM", this.form.reference);
        },
        selectModel($evt){
            this.$emit('selectModel', $evt);
            this.dialog = false;
        },
        confirmAction(saveAndClose = false, url = false, infiniteScroll = false){
            if(!infiniteScroll){
                this.endScroll = false
                this.loadingFull = true
                this.page = 1 
            }
            let payload = this._.cloneDeep(this.form.model)
            payload.page = this.page
            this.$store
                .dispatch('SET_DATA', {
                    url: url ? url : this.form.schema.options.posturl,
                    data: payload,
                })
                .then((response) => {
                    
                    if(infiniteScroll){
                        this.addResult(response);
                    } else {
                        this.submit(response);
                    }

                    setTimeout(() => {
                        this.disableConfirm = false
                        this.loading = false
                    }, 400);
                    
                }).catch((e) =>  {
                    if(e.data && e.data.errors) {
                    for (let errorKey in e.data.errors) {
                        for (let sectionKey in this.form.schema.sections) {
                        for (let fieldKey in this.form.schema.sections[sectionKey].fields) {
                            if(e.data.errors[errorKey].field === fieldKey) {
                            this.form.schema.sections[sectionKey].fields[fieldKey].props['error'] = true;
                            this.form.schema.sections[sectionKey].fields[fieldKey].props['error-messages'] = this.$t(e.data.errors[errorKey].message);
                            }
                        }
                        }
                    }
                    this.key += 1;
                    } else {
                    let errorMessage = e.data && e.data.errorMessage ? e.data.errorMessage : this.$t('errorAPIform');
                    this.setAlert({
                        message: errorMessage,
                        type: "error",
                        timeout: 3000
                    });
                    }
                    setTimeout(() => {
                    this.disableConfirm = false
                    }, 200);
                });

        },
    },
};
</script>

<style lang="scss">
    .LoaderFull{
        position:absolute;
        top:64px;
        left:0;
        width:100%;
        height:calc(100% - 64px);
        display:flex;
        align-items:center;
        justify-content:center;
        background-color:rgba(255,255,255,0.7);
    }
</style>
