<template>
  <div class="col col-12 pa-0">
    <v-autocomplete
      :label="field.title ? $t(field.title) : ''"
      :class="field.props && field.props.required ? 'required' : ''"
      v-model="model[fieldName]"
      v-bind="field.props"
      :clearable="field.props.clearable == false ? false : true"
      :items="this.items"
      :search-input.sync="searchData"
      :loading="loadingData"
      :rules=[validate]
      :no-data-text="noDataText"
    >
      <template v-slot:prepend-item v-if="formUrl">
        <v-list-item ripple @click="getFormToAdd">
          <v-list-item-content>
            <v-list-item-title> {{ $t("createNew") }} </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mt-2"></v-divider>
      </template>
    </v-autocomplete>

    <v-container class="pa-0">
      <FormDialogComponent
        v-if="form"
        ref="anotherForm"
        :form="form"
        :action="'SET_DATA'"
      />
    </v-container>
  </div>
</template>

<script>
import axiosHttp from "@/http-common";
import validate from '../functions/validations'

export default {
  props: ["model", "fieldName", "field"],
  mixins: [validate],
  components: {
    FormDialogComponent: () =>
      import("@/components/dialogs/FormDialogComponent"),
  },
  data() {
    return {
      loadingData: null,
      items: [],
      formUrl: "",
      form: null,
      searchData: "",
      noDataText: ""
    };
  },
  watch: {
    searchData: function(newVal){
      this.loadData()
    }
  },
  mounted() {
    if(this.field.addForm && this.field.addForm.formUrl) this.formUrl = this.field.addForm.formUrl;
    if (this.field.props && this.field.props.items) this.items = this.field.props.items;
    this.noDataText = this.$t('searchForResultLabel')
    this.loadData();
  },
  methods: {
    loadData($event) {
      
      if (this.searchData && this.searchData.length > 1 && this.field.selectDataUrl) {
        
        this.loadingData = true;
        let url = this.field.selectDataUrl.replace(/{{(.*?)}}/g, this.replaceFilter);
        if (url.includes('?')){
          url = url+"&filter[search]="+this.searchData
        } else {
          url = url+"?filter[search]="+this.searchData
        }
        axiosHttp.http
          .get(url)
          .then((response) => {
            this.loadingData = false;
            this.items = [];
            if (response.data && response.data.data) {
              for (let item of response.data.data) {
                if (item.hasOwnProperty("text") && item.hasOwnProperty("value")) {
                  if (this.field["to_trad"]) item.text = this.$t(item.text);
                  this.items.push(item);
                }
              }
            }

            // Permet de sélectionner le / les items au retour de l'ajax
            var modelItem = this.items.find(function(item) {
              if(this.field.props.multiple){
                if(this.model[this.fieldName].includes(item.value))
                  return true;
              } else {
                if(item.value === this.model[this.fieldName])
                  return true;
              }
            }, this);

            if (!modelItem) delete this.model[this.fieldName]
            if(this.items.length == 0){
              this.noDataText = this.$t('noResultLabel')
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        if(this.model[this.fieldName] == null){
          this.items = []
        }
        this.noDataText = this.$t('searchForResultLabel')
      }
    },
    // Permet de remplacer les filtres dans l'URL Ajax par les valeurs du model
    replaceFilter(match, fieldName) {
      if(this.model[fieldName])
        return this.model[fieldName]
      return '0';
    },
    //Initialisation de la popup d'add
    getFormToAdd() {
      this.$store
        .dispatch("forms/GET_FORM_DATA", {
          url: this.formUrl.replace(/{{(.*?)}}/g, this.replaceFilter),
        })
        .then((e) => {
          this.form = this.$store.state.forms.forms[e.reference];
        });
      setTimeout(() => {
        this.crtFromAnotherForm();
      }, 200);
    },
    async crtFromAnotherForm() {
      let response = await this.$refs.anotherForm.open(this.form.params.title);
      if (response && response.data.data["__selectValue"]) {
        this.items.push(response.data.data["__selectValue"]);

        if(this.field.props && this.field.props.multiple) {
          this.model[this.fieldName].push(response.data.data["__selectValue"].value);
        } else {
          this.model[this.fieldName] = response.data.data["__selectValue"].value;
        }
      }
    },
  },
};
</script>

<style lang="scss">
</style>