<template>
  <v-container class="template" fluid v-if="items">
    <v-row>
        <v-col cols="12" v-if="model.config.canal === 'MAILING'">
            <h2>{{model.config.name}}</h2>
        </v-col>
      <v-col cols="12" class="itemsContainer" >
        <div :class="'create itemTemplate ' + model.config.canal" @click="getFormToAdd('/template/getForm?retroplanning_id=' + model.id + '&formName=' + model.config.canal, ['EMAIL', 'MAILING', 'ENVELOPE'].includes(model.config.canal) ? true : false, model)">
          <v-icon color="primary" large>$plus_l</v-icon>
          <p>{{ $t("addTemplate" + model.config.canal + "Label") }}</p>
        </div>
        <div v-for="item in items" :key="item.id" @click="displayTemplate(item)" :class="model[fieldName] && model[fieldName] == item.id ? 'itemTemplate selected ' + model.config.canal : 'itemTemplate ' + model.config.canal">
          <div class="selectedOverlay">
            <v-icon color="success" class="mr-3 mb-1" small>$check_l</v-icon>
          </div>
          <div class="overlayItem">
            <v-icon color="white">$search_plus_r</v-icon>
          </div>
          <div v-if="item.canal == 'SMS' || item.canal == 'TEL'">
            <div class="itemPreview" v-if="item.template"><span  class="template-span" v-html="item.template.text"></span></div>
            <div class="itemName"><b>#T{{ item.num }} - {{ item.version }} - {{ item.name }}</b></div>
          </div>
          <div v-if="item.canal == 'MMS'">
            <div class="itemPreview MMS" v-if="item.template">
              <span style="position: absolute; bottom: 30px; left: 10px">{{ item.template.text }}</span>
              <img v-if="item.template.image" :src="item.template.image.thumb + '?#view=FitH&scrollbar=0&toolbar=0&navpanes=0'" width="100%" height="541" />
            </div>
            <div class="itemName"> <b>#T{{ item.num }} - {{ item.version }} - {{ item.name }}</b></div>
          </div>
          <div v-if="item.canal == 'VMS'">
            <div class="itemPreview MMS" v-if="item.template && item.template.mp3">
              <span style="position: absolute; bottom: 30px; left: 10px"><strong>{{$t('title')}} : </strong>{{ item.template.mp3.title }}</span>
              <audio v-if="item.template.mp3 && item.template.mp3.url" controls controlsList="nodownload" :src="item.template.mp3.url" style="width: 80%;"></audio>
            </div>
            <div class="itemName"> <b>#T{{ item.num }} - {{ item.version }} - {{ item.name }}</b></div>
          </div>
          <div v-if="item.canal == 'EMAIL'">
            <div class="itemPreview email" v-if="item.template"><span class="template-span" v-html="item.template.html"></span> </div>
            <div class="itemName"><b>#T{{ item.num }} - {{ item.version }} - {{ item.name }}</b></div>
          </div>
          <div v-if="item.canal == 'MAILING'" class="pdf">
            <div class="itemPreview email" v-if="item.template">
              <embed v-if="item.template.pdf && item.template.pdf.url" :src="item.template.pdf.url + '?#view=FitH&scrollbar=0&toolbar=0&navpanes=0'" width="100%" height="541" type="application/pdf" />
            </div>
            <div class="itemName"><b>#T{{ item.num }} - {{ item.version }} - {{ item.name }}</b></div>
          </div>
        </div>
      </v-col>

        <!-- AJOUT DE LA PARTIE ENVELOPPE SI LA CAMPAGNE EST MAILING -->
        <v-col cols="12" v-if="model.config.canal === 'MAILING'">
            <h2>{{model.config.name}} ({{ $t('envelope') }})</h2>
        </v-col>
        <v-col cols="12" class="itemsContainer" v-if="model.config.canal === 'MAILING'">
            <div :class="'create itemTemplate ENVELOPE'" @click="getFormToAdd('/template/getForm?retroplanning_id=' + model.id + '&formName=ENVELOPE', true)">
                <v-icon color="primary" large>$plus_l</v-icon>
                <p>{{ $t("addTemplateENVELOPELabel") }}</p>
            </div>
            <div v-for="item in itemsEnvelope" :key="item.id" @click="displayTemplate(item)" :class="model.config.template_envelope && model.config.template_envelope == item.id ? 'itemTemplate selected ENVELOPE' : 'itemTemplate ENVELOPE'">
                <div class="selectedOverlay">
                    <v-icon color="success" class="mr-3 mb-1" small>$check_l</v-icon>
                </div>
                <div class="overlayItem">
                    <v-icon color="white">$search_plus_r</v-icon>
                </div>
                <div class="pdf">
                    <div class="itemPreview email" v-if="item.template">
                        <embed v-if="item.template.pdf && item.template.pdf.url" :src="item.template.pdf.url + '?#view=FitH&scrollbar=0&toolbar=0&navpanes=0'" width="100%" height="541" type="application/pdf" />
                    </div>
                    <div class="itemName"><b>#T{{ item.num }} - {{ item.version }} - {{ item.name }}</b></div>
                </div>
            </div>
        </v-col>
        <!-- FIN -->
    </v-row>

    <v-dialog fullscreen scrollable v-model="templateDialog">
      <v-card>
        <v-toolbar tile flat dark :style="'background-image:url(' + publicPath + 'img/blue-background.jpg); background-size:cover;'">
          <v-toolbar-title>{{ $t("template") }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn outlined dark depressed @click.native="closeTemplateDetailDialog" class="ml-3">{{$t('cancelButton')}}</v-btn>
          <v-btn outlined dark depressed @click="unselectTemplate" v-if="(model[fieldName] && model[fieldName] == templateSelected.id) || (templateSelected.canal === 'ENVELOPE' && model.config.template_envelope === templateSelected.id)" class="ml-3" >{{$t('unselect')}}</v-btn>
          <v-btn outlined dark depressed @click="selectTemplate" v-else class="ml-3">{{$t('select')}}</v-btn>
        </v-toolbar>
        <v-card-text class="pa-6">
          <templateDetailComponent :template="templateSelected" :campaign="model" @getForm="getFormToAdd" />
        </v-card-text>
      </v-card>
    </v-dialog>
 
    <FormDialogComponent v-if="form" ref="anotherForm" :form="form" :action="'SET_DATA'" />
    <dialogTemplateEmail v-if="templateEmailDialog" :templateId="templateEmailId" :templateDuplicatedId="templateDuplicatedEmailId" :openTemplateEmailDialog="templateEmailDialog" @recordTemplateDialog="closeTemplateDialog" @closeTemplateDialog="closeTemplateDialog" />
    <TemplateVariableComponent v-if="templateForVariable" :showTemplateModal="openTemplateVariableModal" :template="templateForVariable" :templateDuplicated="templateDuplicatedForVariable" @closeTemplateModal="closeTemplateVariable"/>
  </v-container>
</template>

<script>
import GenericDataService from "@/services/GenericDataService";
import validate from "@/components/forms/functions/validations";
import templateDetailComponent from "@/components/templates/templateDetailComponent";
import dialogTemplateEmail from '@/components/dialogs/dialogTemplateEmail';
import TemplateVariableComponent from '@/modules/templates/views/TemplateVariableComponent';

export default {
  props: ["model", "fieldName", "field"],
  components: { 
    templateDetailComponent, 
    dialogTemplateEmail,
    TemplateVariableComponent,
    FormDialogComponent: () => import("@/components/dialogs/FormDialogComponent"), 
  },
  mixins: [validate],
  data() {
    return {
      publicPath: process.env.BASE_URL,
      dialog: false,
      items: [],
      itemsEnvelope: [],
      templateDialog: false,
      templateSelected: {},
      form: null,
      templateEmailDialog: false,
      templateEmailId: 0,
      templateDuplicatedEmailId:0,
      openTemplateVariableModal: false,
      templateForVariable: null,
      templateDuplicatedForVariable: null,
    };
  },
  mounted() {
    this.refreshTemplateList();
  },
  methods: {
    closeTemplateDetailDialog() {
        this.templateDialog = false;
        this.templateEmailId = 0;
        this.templateDuplicatedEmailId = 0;
        this.templateForVariable = null;
        this.templateDuplicatedForVariable = null;
        this.templateSelected = {};
    },
    closeTemplateVariable(refresh = false){
      this.openTemplateVariableModal = false;
      this.templateDialog = false;
      this.templateSelected = {};
      this.templateForVariable = null;
      this.templateDuplicatedForVariable = null;
    },
    displayTemplate(item){
      if(!this.templateSelected || this.templateSelected.id !== item.id) {
        GenericDataService.getData("/template/get?id=" + item.id).then((response) => {
          this.templateSelected = response.data.data;
          this.templateDialog = true;
        });
      } else {
        this.templateDialog = true;
      }
    },
    closeTemplateDialog(){
      this.refreshTemplateList()
      this.templateEmailDialog = false;
      this.templateDialog = false;
      this.templateSelected = {};
      this.templateEmailId = 0;
      this.templateDuplicatedEmailId = 0;
    },
    refreshTemplateList() {
      GenericDataService.postData("/retroplanning/" + this.model.id + "/getTemplateSelectList").then((response) => {
        this.items = response.data.data.data;
      });
      if(this.model.config.canal === "MAILING") {
        GenericDataService.postData("/retroplanning/" + this.model.id + "/getTemplateSelectList?canal=ENVELOPE").then((response) => {
            this.itemsEnvelope = response.data.data.data;
        });
      }
    },
    selectTemplate() {
        if(this.templateSelected.canal === 'ENVELOPE')
            this.model.config.template_envelope = this.templateSelected.id;
        else 
            this.model[this.fieldName] = this.templateSelected.id;
        this.templateDialog = false;
    },
    unselectTemplate(){
        if(this.templateSelected.canal === 'ENVELOPE')
            this.model.config.template_envelope = null;
        else
            this.model[this.fieldName] = null;
        this.templateDialog = false;
    },
    getFormToAdd(url, createForm = false) {
      this.$store.dispatch("forms/GET_FORM_DATA", { url: url, payload: {} }).then((e) => {
        this.form = this.$store.state.forms.forms[e.reference];
      });
      setTimeout(() => {
        this.crtFromAnotherForm(this.form.params.title, createForm);
      }, 200);
    },
    async crtFromAnotherForm(title, createForm) {
      await this.$refs.anotherForm.open(this.$t(title)).then((response) => {
        if(response && response.data && response.data.success) {
          if(createForm) {
            switch (response.data.data.canal) {
              case 'EMAIL':
                this.templateEmailId = response.data.data.id;
                this.templateDuplicatedEmailId = this.templateSelected.id;
                this.templateEmailDialog = true;
                break;
              case 'MAILING':
              case 'ENVELOPE':
                if(response.data.data.template.pdf){
                  this.templateForVariable = response.data.data;
                  this.templateDuplicatedForVariable = this.templateSelected;
                  this.openTemplateVariableModal = true;
                }
                break;
            }
          }
          

            if(response.data.data.canal === 'ENVELOPE') {
                this.model.config.template_envelope = response.data.data.id;
                //this.itemsEnvelope.push(response.data.data);
            } else {
                this.model[this.fieldName] = response.data.data.id;
                //this.items.push(response.data.data);
            }

            this.refreshTemplateList()
            this.templateDialog = false;
            this.templateSelected = {};
        }
      })  
    },
  },
};
</script>

<style lang="scss">
  .template {
    .itemsContainer {
      display: flex;
      flex-wrap: wrap;
      .pdf {
        overflow: hidden;
      }
    }
    .itemTemplate {
      position: relative;
      height: 200px;
      width: 23%;
      margin-right:1%;
      margin-bottom:15px;
      border: 1px solid rgba(0, 0, 0, 0.13);
      background-color: rgba(0, 0, 0, 0.03);
      cursor: pointer;
      &.EMAIL, &.MAILING, &.ENVELOPE {
        height:300px;
        .itemPreview {
          height: 269px;
        }
      }
      &.create {
        border: 1px solid rgba(9, 87, 125, 0.2);
        background-color: rgba(9, 87, 125, 0.1);
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        p {
          margin-bottom:0;
          text-transform: uppercase;
          margin-top:10px;
          font-weight:bold;
        }
      }
      .itemPreview {
        height: 169px ;
        padding:30px;
        display:flex;
        justify-content:center;
        align-items:center;
        position:relative;
        overflow: hidden;
        &.email {
          padding:0;
          align-items: flex-start;
          overflow:hidden;
          embed {
            overflow: hidden;
          }
        }
        &.MMS {
          padding:0px;
          overflow:hidden;
          img {
            width:100%;
            height:auto;
          }
        }
        .template-span {
            top: 0;
            position: absolute;
        }
      }
      .itemName {
        height:30px;
        padding: 5px;
        border-top: 1px solid rgba(0, 0, 0, 0.13);
        border-bottom: 1px solid rgba(0, 0, 0, 0.13);
        background-color: white ;
      }
      .overlayItem {
        position:absolute;
        width:100%;
        height:100%;
        z-index:2;
        display:none;
        background-color: rgba(9, 87, 125, 0.6);
      }
      .selectedOverlay {
        position:absolute;
        display:none; 
        align-items: flex-end;
        justify-content: flex-end;
        z-index:2;
        width:100%;
        height:100%;
        background-color: rgba(91, 184, 93, 0.4);
        border: 1px solid rgba(91, 184, 93, 1);
      }
      &.selected {
        .selectedOverlay {
          display:flex;
        }
        &:hover {
          .overlayItem {
            display:none;
          }
        }           
      }
      &:hover {
        .overlayItem {
          display:flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
</style>