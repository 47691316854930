<template>
    <v-row>
        <v-col cols="6">
            <span class="label black--text"><b>{{$t('searchspecificcolumn')}}</b></span>
            <v-text-field v-model="searchInList" @input="filterList" :placeholder="$t('searchspecificcolumn')" solo flat dense class="input-form mt-2"></v-text-field>
            <div class="mt-2 listFields">
                <div class="listFieldsContent">
                    <div v-for="(categoryContent, category) in data" class="mb-4">
                        <span class="label black--text" v-show="canShowCategory(data[category])"><b>{{$t(category)}}</b></span>
                        <v-checkbox v-for="(field, index) in data[category]" v-show="!field.hide" :key="'field_'+field.value" hide-details v-model="checkboxes['field_'+field.value]" :label="field.text" @change="toggleValue(field)" dense></v-checkbox>
                    </div>
                </div>
            </div>
        </v-col>
        <v-col cols="6" v-if="model[fieldName] && model[fieldName].length > 0" :class="dragedElem ? 'dragging' : ''">
            <div 
                :class="handleDropClasses(field)"
                v-for="(field, index) in model[fieldName]" 
                :key="field.value"
                draggable
                @dragstart.self="pickupElem($event, field, index);"
                @dragover.prevent="showDropPlace($event, field, index);"
                @dragenter.prevent
                @drop="moveElem($event, field, index);"
                @dragend.prevent="dragEndClear();">
                <div class="selectElement list__elem pa-2 mb-1" :class="{ 'selectElement list__elem--is-dragged': dragedElem && field.value === dragedElem.value }">
                    <span>
                        <v-icon small class="mr-2">$gripvertical_s</v-icon>
                        {{(field.text)}}
                    </span>
                    <v-icon small class="mr-2" @click="removeSelected(field)">$close</v-icon>
                </div>
            </div>
        </v-col>
        <v-col cols="6" v-else>
            <v-alert class="mt-3" border="left" color="info" text>
                {{$t('selectcolumnstoexport')}}
            </v-alert>
        </v-col>
    </v-row>
</template>

<script>
import GenericDataService from '@/services/GenericDataService';

export default {
    name: "exportModelComponent",
    props: ["model", "fieldName", "field"],
    data() {
        return {
            checkboxes: {},
            searchInList: "",
            dragedElem: null,
            overElem: null,
            columns: {}
        }
    },
    created() {
        GenericDataService.getData("/export_model/getColumns?module_source=" + this.model['module_source'] + "&module_target=" + this.model['module_target']).then((response) => {
            this.columns = response.data.data;
        });

        if(!this.model[this.fieldName])
            this.model[this.fieldName] = [];

        this.model[this.fieldName].forEach(function(item) {
            this.checkboxes["field_" + item.value] = true;
        }, this);
    },
    computed: {
        data() {
            let returnColumns = {};
            for (const [category, columns] of Object.entries(this.columns)) {
                for (const column of columns) {
                    if(!returnColumns[category])
                        returnColumns[category] = [];

                    let text = "";
                    if(column.includes('customUser.')) {
                        let tmpText = column.split('.', 3);
                        text = tmpText[2];
                    } else {
                        text = this.$t('export_' + category + '.' + column);
                    }

                    returnColumns[category].push({
                        text: text,
                        value: category + '.' + column,
                        hide: false
                    });
                }
            }

            return returnColumns;
        },
        handleDropClasses(item) {
            return item => {
                if (!this.overElem || !this.overElem.value) {
                    return "";
                }
                if (
                    this.overElem.value === item.value &&
                    item.order < this.dragedElem.order
                ) {
                    return "drop-place drop-place--before";
                }
                if (
                this.overElem.value === item.value &&
                item.order > this.dragedElem.order
                ) {
                    return "drop-place drop-place--after";
                }
            };
        }
    },
    methods: {
        filterList(){
            if(this.searchInList.length > 2){
                for(const category in this.data){
                    this.data[category].forEach((field, indexField) => {
                        if(!field.text.toLowerCase().includes(this.searchInList.toLowerCase()) && !field.value.toLowerCase().includes(this.searchInList.toLowerCase()))
                            field.hide = true;
                        else
                            field.hide = false;
                    })
                }
            } else {
                this.resetHidding();
            }
        },
        resetHidding(){
            for( const category in this.data){
                this.data[category].forEach((field, indexField) => {
                    field.hide = false;
                })
            }
        },
        toggleValue(field){
            const name = "field_"+field.value;
            if(this.checkboxes[name]){
                this.model[this.fieldName].push(field);
                field.order = this.model[this.fieldName].length;
            } else {
                let index = this.model[this.fieldName].findIndex((e) => e.value == field.value);
                delete field.order;
                this.recalculateOrder();
                this.model[this.fieldName].splice(index, 1);
            }
        },
        removeSelected(field){
            const name = "field_"+field.value;
            this.checkboxes[name] = false;
            let index = this.model[this.fieldName].findIndex((e) => e.value == field.value);
            delete field.order;
            this.model[this.fieldName].splice(index, 1);
            this.recalculateOrder();
        },
        recalculateOrder(){
            this.model[this.fieldName].forEach((element, index) => {
                element.order = index + 1
            });
        },
        dragEndClear() {
            this.dragedElem = null;
            this.overElem = null;
        },
        pickupElem(event, elem, index) {
            event.dataTransfer.dropEffect = "move";
            this.dragedElem = { ...elem };
        },
        showDropPlace(event, elem, index) {
            if (elem.value !== this.dragedElem.value) {
                this.overElem = { ...elem };
            } else {
                this.overElem = null;
            }
        },
        moveElem(event, elem, index) {
            this.model[this.fieldName] = this.model[this.fieldName].filter((e) => e.value !== this.dragedElem.value);
            this.model[this.fieldName].splice(index, 0, { ...this.dragedElem });
            this.model[this.fieldName].forEach((item, index) => (item.order = index + 1));
        },
        canShowCategory(fields) {
            if (fields.some(e => e.hide === false))
                return true;

            return false;
        }
    },
}
</script>

<style lang="scss">
    .dragging {
        cursor: grabbing !important;
    }
    .selectElement {
        background-color: #EEE;
        border-radius:5px;
        color: black;
        display:flex;
        align-items:center;
        justify-content:space-between;
    }
    .list__elem {
        cursor: grab;
        border: 1px solid transparent;
        padding: 5px;
        margin-bottom: 5px;
        transition: all 0.3s ease;
    }
    .list__elem--is-dragged {
        cursor: grabbing !important;
        opacity: 0.7;
        border: 1px dashed skyblue;
    }
    .listFields {
        max-height:600px;
        overflow-y:scroll;
        .listFieldsContent {
            overflow:hidden;
        }
    }
    .drop-place {
        position: relative;
        transition: all 0.3s ease;
    }
    .drop-place--before {
        padding-top: 40px;
    }
    .drop-place--after {
        padding-bottom: 40px;
    }
    .drop-place--before:before {
        position: absolute;
        top: 5px;
        left: 0;
        text-align: center;
        border-radius: 5px;
        content: "Déposez ici";
        width: 100%;
        padding: 7px 0;
        opacity: 0.7;
        border: 1px dashed skyblue;
        box-shadow: inset 0px 0px 5px 2px var(--v-success-base);
    }
    .drop-place--after:after {
        position: absolute;
        bottom: 5px;
        left: 0;
        border-radius: 5px;
        text-align: center;
        content: "Déposez ici";
        width: 100%;
        padding: 7px 0;
        opacity: 0.7;
        border: 1px dashed skyblue;
        box-shadow: inset 0px 0px 5px 2px var(--v-success-base);
    }
</style>