<script>
import { Line, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  extends: Line,
  props: ['dataSet'],
  data() {
      return {
          options: {
            responsive:true,
            maintainAspectRatio: false, 
            legend: {
                display: true,
                position: 'top',
            },
            tooltip: {
                enabled: false
            },
            title:{
                display: false,
                text: "test"
            },
            scales: {
                yAxes: [{
                    display:true,
                    ticks: {
                        beginAtZero: true
                    }
                }],
                xAxes: [{
                    display: true,
                    scaleLabel: {
                        display: true
                    }
                }]
            }
          }
      }
  },
  computed: {
      chartData: function() {
          const data = this.dataSet;
            data.datasets.forEach(element => {
                element.label = this.$t(element.label)
            });
          return data
      }
  },
  mounted () {
      this.options.title.text = this.chartData.label
      setTimeout(() => {
          if(this.$refs.canvas)
            this.renderChart(this.chartData, this.options)    
        }, 50);
  },
  
}
</script>

<style>
</style>