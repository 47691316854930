<template>
    <v-dialog persistent v-model="templateSelect" scrollable fullscreen :retain-focus="false"
    no-click-animation>
        <v-card class="templateSelect">
            <v-toolbar tile flat dark class="bg-gradient">
                <v-toolbar-title style="display:flex; justify-content: space-between; width:100%;">{{$t('templateSelect')}} <v-btn @click.stop="$emit('close')" text color="white" class="square"><v-icon >$close</v-icon></v-btn></v-toolbar-title>
            </v-toolbar>
            <v-card-text class="pt-6 itemsContainer" >
                <div :class="'create itemTemplate ' + type" @click="getFormToAdd('/template/getForm?formName=' + type +'&operation_id='+$route.params.operation_id, type == 'EMAIL' ? true : false,)" v-if="$route.params.operation_id">
                    <v-icon color="primary" large>$plus_l</v-icon>
                    <p>{{ $t("addTemplate" + type + "Label") }}</p>
                </div>
                 <div v-for="item in templates" :key="item.id" @click="displayTemplate(item)" :class="selectedId == item.id ? 'itemTemplate selected ' + type : 'itemTemplate ' + type">
                    <div class="selectedOverlay">
                        <v-icon color="success" class="mr-3 mb-1" small>$check_l</v-icon>
                    </div>
                    <div class="overlayItem">
                        <v-icon color="white">$search_plus_r</v-icon>
                    </div>
                    <div v-if="item.canal == 'SMS' || item.canal == 'TEL'">
                        <div class="itemPreview" v-if="item.template"><span  class="template-span" v-html="item.template.text"></span></div>
                        <div class="itemName"><b>#T{{ item.num }} - {{ item.version }} - {{ item.name }}</b></div>
                    </div>
                    <div v-if="item.canal == 'MMS'">
                        <div class="itemPreview MMS" v-if="item.template">
                        <span style="position: absolute; bottom: 30px; left: 10px">{{ item.template.text }}</span>
                        <img v-if="item.template.image" :src="item.template.image.thumb + '?#view=FitH&scrollbar=0&toolbar=0&navpanes=0'" width="100%" height="541" />
                        </div>
                        <div class="itemName"> <b>#T{{ item.num }} - {{ item.version }} - {{ item.name }}</b></div>
                    </div>
                    <div v-if="item.canal == 'VMS'">
                        <div class="itemPreview MMS" v-if="item.template && item.template.mp3">
                        <span style="position: absolute; bottom: 30px; left: 10px"><strong>{{$t('title')}} : </strong>{{ item.template.mp3.title }}</span>
                        <audio v-if="item.template.mp3 && item.template.mp3.url" controls controlsList="nodownload" :src="item.template.mp3.url" style="width: 80%;"></audio>
                        </div>
                        <div class="itemName"> <b>#T{{ item.num }} - {{ item.version }} - {{ item.name }}</b></div>
                    </div>
                    <div v-if="item.canal == 'EMAIL'">
                        <div class="itemPreview email" v-if="item.template"><span class="template-span" v-html="item.template.html"></span> </div>
                        <div class="itemName"><b>#T{{ item.num }} - {{ item.version }} - {{ item.name }}</b></div>
                    </div>
                </div>
            </v-card-text>
            <v-dialog fullscreen scrollable v-model="templateDialog">
                <v-card>
                    <v-toolbar tile flat dark :style="'background-image:url(' + publicPath + 'img/blue-background.jpg); background-size:cover;'">
                    <v-toolbar-title>{{ $t("template") }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn outlined dark depressed @click.native="closeTemplateDetailDialog" class="ml-3">{{$t('cancelButton')}}</v-btn>
                    <v-btn outlined dark depressed @click="unselectTemplate" v-if="(selectedId == templateSelected.id)" class="ml-3" >{{$t('unselect')}}</v-btn>
                    <v-btn outlined dark depressed @click="selectTemplate" v-else class="ml-3">{{$t('select')}}</v-btn>
                    </v-toolbar>
                    <v-card-text class="pa-6">
                        <templateDetailComponent :template="templateSelected" :campaign="null" @getForm="getFormToAdd" />
                    </v-card-text>
                </v-card>
            </v-dialog>
            <FormDialogComponent v-if="form" ref="anotherForm" :form="form" :action="'SET_DATA'" />
            <dialogTemplateEmail v-if="templateEmailDialog" :templateId="templateEmailId" :templateDuplicatedId="templateDuplicatedEmailId" :openTemplateEmailDialog="templateEmailDialog" @recordTemplateDialog="closeTemplateDialog" @closeTemplateDialog="closeTemplateDialog" />
        </v-card>
    </v-dialog>
</template>
<script>
import GenericDataService from '@/services/GenericDataService'
import dialogTemplateEmail from '@/components/dialogs/dialogTemplateEmail'
import templateDetailComponent from '@/components/templates/templateDetailComponent'
export default {
    name: "WorkflowSelectTemplateComponent",
    props: ["templateSelect", "type", "template", "element"],
    components: {
        FormDialogComponent: () => import("@/components/dialogs/FormDialogComponent"), 
        dialogTemplateEmail, 
        templateDetailComponent
    },
    data() {
        return {
            publicPath: process.env.BASE_URL,
            templates: [],
            templateSelected: {},
            selectedId:0,
            templateDialog: false,
            templateEmailDialog: false,
            form:null,
            templateEmailId: 0,
            templateDuplicatedEmailId:0,
        }
    },
    watch: {
        templateSelect(val){
            if(val){
                this.templates = []
                this.refreshTemplates()
            }
        }
    },
    mounted() {
        this.templates = []
        this.refreshTemplates()
    },
    created() {
        this.templates = []
        this.refreshTemplates()
    },
    methods: {
        closeTemplateDialog(){
            this.templateSelected = this.templates.find((e) => e.id == this.selectedId)
            this.templateEmailDialog = false
        },
        refreshTemplates(){
            if(this.template && this.template.hasOwnProperty('id')){
                this.selectedId = this.template.id
                this.templateSelected = this.template
            }
            let filters = {};
            if(this.$route.params.operation_id){
                filters.operation_id = this.$route.params.operation_id
            }
            if(this.element && this.element.config && this.element.config.template_type && this.element.config.template_type != ""){
                filters.type = [this.element.config.template_type]
            }
            if(this.element && this.element.config && this.element.config.template_cat && this.element.config.template_cat != ""){
                filters.category = [this.element.config.template_cat.value]
            }
            filters.canal = [this.type]
            GenericDataService.postData('/template/list', {filters : filters}).then((response) => {
                this.templates = response.data.data.data
            })
        },
        closeTemplateDetailDialog() {
            this.templateDialog = false;
            this.templateEmailId = 0;
            this.templateDuplicatedEmailId = 0;
            this.templateSelected = {};
        },
        selectTemplate() {
            this.$emit('selectTemplate', this.templateSelected)
            this.refreshTemplates()
            this.templateDialog = false;
        },
        unselectTemplate(){
            this.selectedId = null;
            this.templateDialog = false;
        },
        displayTemplate(item){
            //if(!this.templateSelected || this.templateSelected.id !== item.id) {
                GenericDataService.getData("/template/get?id=" + item.id).then((response) => {
                    this.templateSelected = response.data.data;
                    this.templateDialog = true;
                });
            //} 
        },
        getFormToAdd(url, createForm = false) {
            this.$store.dispatch("forms/GET_FORM_DATA", { url: url, payload: {} }).then((e) => {
                this.form = this.$store.state.forms.forms[e.reference];
            });
            setTimeout(() => {
                this.crtFromAnotherForm(this.form.params.title, createForm);
            }, 200);
        },
        async crtFromAnotherForm(title, createForm) {
            await this.$refs.anotherForm.open(this.$t(title)).then((response) => {
                if(response && response.data && response.data.success) {
                    if(createForm) {
                        switch (response.data.data.canal) {
                        case 'EMAIL':
                            this.templateEmailId = response.data.data.id;
                            this.templateDuplicatedEmailId = response.data.data.id;
                            this.templateEmailDialog = true;
                            break;
                        }
                    }

                    if(response.data.data.canal === 'ENVELOPE') {
                        this.model.config.template_envelope = response.data.data.id;
                    } else {
                        this.selectedId = response.data.data.id;
                        this.templateSelected = response.data.data
                    }

                    this.selectTemplate()
                    this.templateDialog = false;
                    //this.templateSelect = false;
                    this.templateSelected = {};
                }
            })
        },
    },
}
</script>
<style lang="scss">
.templateSelect {
    .itemsContainer {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      flex-grow:0 !important;
      .pdf {
        overflow: hidden;
      }
    }
    .itemTemplate {
      position: relative;
      height: 200px;
      width: 19%;
      margin-right:1%;
      margin-bottom:15px;
      border: 1px solid rgba(0, 0, 0, 0.13);
      background-color: rgba(0, 0, 0, 0.03);
      cursor: pointer;
    //   &.EMAIL, &.MAILING, &.ENVELOPE {
    //     height:300px;
    //     .itemPreview {
    //       height: 269px;
    //     }
    //   }
      &.create {
        border: 1px solid rgba(9, 87, 125, 0.2);
        background-color: rgba(9, 87, 125, 0.1);
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        p {
          margin-bottom:0;
          text-transform: uppercase;
          margin-top:10px;
          font-weight:bold;
        }
      }
      .itemPreview {
        height: 169px ;
        padding:30px;
        display:flex;
        justify-content:center;
        align-items:center;
        position:relative;
        overflow: hidden;
        overflow-y:auto;
        &.email {
          padding:0;
          align-items: flex-start;
          overflow:hidden;
          embed {
            overflow: hidden;
          }
        }
        &.MMS {
          padding:0px;
          overflow:hidden;
          img {
            width:100%;
            height:auto;
          }
        }
        .template-span {
            top: 0;
            position: absolute;
        }
      }
      .itemName {
        height:30px;
        padding: 5px;
        border-top: 1px solid rgba(0, 0, 0, 0.13);
        border-bottom: 1px solid rgba(0, 0, 0, 0.13);
        background-color: white ;
      }
      .overlayItem {
        position:absolute;
        width:100%;
        height:100%;
        z-index:2;
        display:none;
        background-color: rgba(9, 87, 125, 0.6);
      }
      .selectedOverlay {
        position:absolute;
        display:none;
        align-items: flex-end;
        justify-content: flex-end;
        z-index:2;
        width:100%;
        height:100%;
        background-color: rgba(91, 184, 93, 0.4);
        border: 1px solid rgba(91, 184, 93, 1);
      }
      &.selected {
        .selectedOverlay {
          display:flex;
        }
        &:hover {
          .overlayItem {
            display:none;
          }
        }
      }
      &:hover {
        .overlayItem {
          display:flex;
          justify-content: center;
          align-items: center;
        }
      }
    } }
</style>
