<template>
    <v-dialog v-model="dialog" max-width="500" :style="{ zIndex: 200 }" @keydown.esc="cancel" @click:outside="cancel">
        <v-card>
            <v-toolbar dark class="bg-gradient" dense flat>
                <v-toolbar-title class="text-body-2 font-weight-bold white--text">
                    {{ $t("formFields") }}
                </v-toolbar-title>
            </v-toolbar>
            <v-card-text class="pa-4 black--text" v-if="field">
                <v-checkbox v-for="(linkField, index) in field.formFields" :key="index" :label="linkField" class="mt-0" v-model="model[index]" hide-details></v-checkbox>
            </v-card-text>
            <v-card-actions class="pt-3">
                <v-spacer></v-spacer>
                <v-btn color="grey" text class="body-2 font-weight-bold" @click.native="cancel" >{{$t('confirmationCancel')}}</v-btn>
                <v-btn color="secondary" depressed class="body-2 font-weight-bold"  @click.native="agree">{{$t('confirmationConfirm')}}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "ConfirmDialogComponent",
    data() {
        return {
            dialog: false,
            resolve: null,
            reject: null,
            model: null,
            field: null,
        };
    },
    methods: {
        open(model, field) {
            this.dialog = true;
            this.model = model;
            this.field = field;
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        agree() {
            this.resolve(true);
            this.dialog = false;
        },
        cancel() {
            this.resolve(false);
            this.dialog = false;
        }
    },
};
</script>