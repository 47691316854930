<template>
  <div class="col col-12 pa-0">
    <div class="segementInput">
      <div class="nbContact pa-9">
        <v-icon color="primary">$users_l</v-icon>
        <h2 class="darkgrey--text" style="font-size:30px;">{{ totalContact }}</h2>
        <p style="text-transform:uppercase;">{{$t('contactInOpeLabel')}}</p>
      </div>
      <v-icon large color="primary">$arrow_alt_circle_right_s</v-icon>
      <div class="segmentationSelect pa-6 pt-11 px-12">
        <v-autocomplete :label="field.title ? $t(field.title) : ''" :class="field.props && field.props.required ? 'required' : ''" v-model="model[fieldName]" v-bind="field.props" :items="this.items" @focus="loadData" :loading="loadingData" :rules=[validate] :no-data-text="$t('no-data-text')">
          <template v-slot:prepend-item v-if="formUrl">
            <v-list-item ripple @click="getFormToAdd">
              <v-list-item-content>
                <v-list-item-title> {{ $t("createNew") }} </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>
          <template v-slot:selection="data" v-if="field.props.multiple">
              <v-chip v-bind="data.attrs" :input-value="data.selected" small outlined color="primary" close close-icon="$close" @click:close="remove(data.item)">
                {{ data.item.text }}
              </v-chip>
            </template>
        </v-autocomplete>
      </div>
      <v-icon large color="primary">$arrow_alt_circle_right_s</v-icon>
      <div class="nbTarget pa-9 primary">
        <v-icon color="white">$bullseyepointer_s</v-icon>
        <h2 class="white--text" style="display:flex; align-items:flex-end;"><b style="font-size:30px; line-height:28px;" class="mr-1">{{ targetedContact }}</b> <span>/ {{ plannedContact }}</span></h2>
        <p class="white--text " style="text-transform:uppercase; text-align:center;">Contact ciblés / prévision</p>
      </div>
    </div>
    <v-container class="pa-0">
      <FormDialogComponent v-if="form" ref="anotherForm" :form="form" :action="'SET_DATA'" />
    </v-container>
  </div>
</template>

<script>
import GenericDataService from "@/services/GenericDataService";
import axiosHttp from "@/http-common";
import validate from "@/components/forms/functions/validations";

export default {
  props: ["model", "fieldName", "field"],
  mixins: [validate],
  components: {
    FormDialogComponent: () => import("@/components/dialogs/FormDialogComponent"),
  },
  data() {
    return {
      loadingData: null,
      items: [],
      formUrl: "",
      form: null,
      totalContact: 0,
      targetedContact: 0,
      plannedContact: 0,
    };
  },
   watch: {
    'model': {
      handler: function(val, oldVal) {
        this.loadStats();
      },
      deep: true
    },
  },
  mounted() {
    if(this.field.addForm && this.field.addForm.formUrl) this.formUrl = this.field.addForm.formUrl;
    if (this.field.props && this.field.props.items) this.items = this.field.props.items;
    this.loadData();
    this.loadStats();
  },
  methods: {
    remove(item){
      const index = this.model[this.fieldName].findIndex((e) => e == item.value)

      if(index >= 0) 
        this.model[this.fieldName].splice(index, 1)
    },
    loadStats() {
      let filters = "";
      if(this.model[this.fieldName] && this.model[this.fieldName].value)
        filters = "?segment_id=" + this.model[this.fieldName].value

      GenericDataService.getData('/operation/' + this.model['operation_id'] + '/getClientFiles' + filters).then((response) => {
        let clientFile = response.data.data.data.find(e => e.id == this.model['id']);
        this.totalContact = response.data.data.global.totalNumber;
        this.targetedContact = clientFile ? clientFile.targetedContact : 0;
        this.plannedContact = clientFile ? clientFile.plannedContact : 0;
      });

    },
    loadData() {
      if (this.field.selectDataUrl && (this.loadingData === null || this.field.selectDataUrl.match(/{{(.*?)}}/g))) {
        this.items = [];
        this.loadingData = true;

        axiosHttp.http
          .get(this.field.selectDataUrl.replace(/{{(.*?)}}/g, this.replaceFilter))
          .then((response) => {
            this.loadingData = false;
            if (response.data && response.data.data) {
              for (let item of response.data.data) {
                if (item.hasOwnProperty("text") && item.hasOwnProperty("value")) {
                  if (this.field["to_trad"]) item.text = this.$t(item.text);
                  this.items.push(item);
                }
              }
            }

            // Permet de sélectionner le / les items au retour de l'ajax
            var modelItem = this.items.find(function(item) {
              if(typeof this.model[this.fieldName] !== "undefined") {
                if(this.field.props.multiple){
                  if(this.model[this.fieldName].includes(item.value))
                    return true;
                } else if (this.field.props['return-object']) {
                  if(item.value === this.model[this.fieldName].value)
                    return true;
                } else {
                  if(item.value === this.model[this.fieldName])
                    return true;
                }
              }
            }, this);

            if (!modelItem) {
              delete this.model[this.fieldName]
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    // Permet de remplacer les filtres dans l'URL Ajax par les valeurs du model
    replaceFilter(match, fieldName) {
      if(this.model[fieldName])
        return this.model[fieldName]
      return '0';
    },
    //Initialisation de la popup d'add
    getFormToAdd() {
      this.$store
        .dispatch("forms/GET_FORM_DATA", {
          url: this.formUrl.replace(/{{(.*?)}}/g, this.replaceFilter),
        })
        .then((e) => {
          this.form = this.$store.state.forms.forms[e.reference];
        });
      setTimeout(() => {
        this.crtFromAnotherForm();
      }, 200);
    },
    async crtFromAnotherForm() {
      let response = await this.$refs.anotherForm.open(this.form.params.title);
      if (response && response.data.data["__selectValue"]) {
        this.items.push(response.data.data["__selectValue"]);

        if(this.field.props && this.field.props.multiple) {
          this.model[this.fieldName].push(response.data.data["__selectValue"].value);
        } else {
          this.model[this.fieldName] = response.data.data["__selectValue"].value;
        }
      }
    },
  },
};
</script>

<style lang="scss">
.segementInput {
  display:flex;
  justify-content:space-between;
  align-items: center;
  width:70%;
  margin-left:auto;
  margin-right:auto;
  .segmentationSelect {
    width:60%;
    display:flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(0,0,0,0.1)
  }
  .nbContact {
    border: 1px solid rgba(0,0,0,0.1);
    display:flex;
    width:15%;
    flex-direction:column;
    justify-content: center;
    align-items: center;
  }
  .nbTarget {
    width:15%;
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
  }
}
</style>