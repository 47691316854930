<template>
  <div class="col col-12 pa-0 input-form" :style="field.props.hidden ? 'display:none; ' : ''">
      <span v-if="field.title" class="label">
        <b>{{$t(field.title)}}</b>
        <span v-if="field.props && field.props.required" style="color:red">&nbsp;*</span>
        <infoTooltip v-if="field.tooltip" :tooltip="field.tooltip" />
      </span>
      
        <div :class="'create itemTemplateSelect '" v-if="!model[fieldName] || !model[fieldName].hasOwnProperty('id')" @click="templateSelectDialog = true">
            <v-icon color="primary" large>$plus_l</v-icon>
            <p>{{ $t("selectTemplate") }}</p>
        </div>
        <div v-else :class="'itemTemplateSelect ' + field.canal" @click="templateSelectDialog = true">
            <div>
                <div class="itemPreview email" v-if="model[fieldName].id"><span class="template-span" v-html="templateThumb"></span> </div>
                <div class="itemName"><b>#T{{ model[fieldName].num }} - {{ model[fieldName].version }} - {{ model[fieldName].name }}</b></div>
            </div>
        </div>

        <WorkflowSelectTemplateComponent 
            @close="templateSelectDialog = false" 
            :type="'TEL'" 
            :element="model" 
            :template="model[fieldName]" 
            :templateSelect="templateSelectDialog" 
            @selectTemplate="selectTemplate"/>
  </div>
</template>

<script>
import axiosHttp from "@/http-common";
import validate from '../functions/validations';
import WorkflowSelectTemplateComponent from '@/modules/workflow/components/WorkflowSelectTemplateComponent';
import GenericDataService from '@/services/GenericDataService';
import infoTooltip from '@/components/infoTooltip';

export default {
    props: ["model", "fieldName", "field"],
    mixins: [validate],
    components: { WorkflowSelectTemplateComponent, infoTooltip },
    data() {
        return {
            template: {},
            templateSelectDialog: false,
            templateThumb: "",
        };
    },
    created() {
        if(this.model[this.fieldName] && this.model[this.fieldName].hasOwnProperty('id')){
            GenericDataService.getData('/template/get?id=' + this.model[this.fieldName].id).then((response) => {
                this.template = response.data.data.template;
                this.templateThumb = response.data.data.template.text;
            });
        }
    },
    methods: {
        selectTemplate(template){

            let templateToRecord = {
                id: template.id,
                canal: template.canal,
                name: template.name,
                num: template.num,
                version: template.version
            }

            this.templateThumb = template.template.text
            
            this.model[this.fieldName] = templateToRecord
            this.templateSelectDialog = false
        },
    },
};
</script>

<style lang="scss">
    .itemTemplateSelect {
        position: relative;
        height: 200px;
        width: 100%;
        margin-right:1%;
        margin-bottom:15px;
        border: 1px solid rgba(0, 0, 0, 0.13);
        background-color: rgba(0, 0, 0, 0.03);
        cursor: pointer;
        &.create {
            border: 1px solid rgba(9, 87, 125, 0.2);
            background-color: rgba(9, 87, 125, 0.1);
            display:flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            p {
                margin-bottom:0;
                text-transform: uppercase;
                margin-top:10px;
                font-weight:bold;
            }
        }
        .itemPreview {
            height: 169px ;
            padding:30px;
            display:flex;
            justify-content:center;
            align-items:center;
            position:relative;
            overflow: hidden;
            &.email {
                padding:0;
                align-items: flex-start;
                overflow:hidden;
                embed {
                    overflow: hidden;
                }
            }
            &.MMS {
                padding:0px;
                overflow:hidden;
                img {
                    width:100%;
                    height:auto;
                }
            }
            .template-span {
                top: 0;
                position: absolute;
            }
        }
        .itemName {
            height:30px;
            padding: 5px;
            border-top: 1px solid rgba(0, 0, 0, 0.13);
            border-bottom: 1px solid rgba(0, 0, 0, 0.13);
            background-color: white ;
        }
    }
</style>