<template>
    <div>
        <v-data-table
        :headers="field.headers"
        :items="model[fieldName]"
        hide-default-footer
        disable-sort
        class='data-custom-table'>
             <template v-for="header in field.headers"  v-slot:[`header.${header.value}`]="{  }">
                <!-- Surcharge des actions en fin de ligne -->
                <div v-if="header.value !== 'actions'"  class="icons-datatable" :key="header.value">
                    {{$t(header.text)}}
                </div>
            </template>
            <template v-for="header in field.headers" v-slot:[`item.${header.value}`]="{ item }">
                <div class="test_planning" v-if="header.type == 'select'"
                        @mouseenter="hoverEditable('iconEdit_'+model[fieldName].findIndex((e) => e == item)+'_'+item.id)" 
                        @mouseleave="outEditable('iconEdit_'+model[fieldName].findIndex((e) => e == item)+'_'+item.id)" 
                        :key="header.value">
                        <v-edit-dialog
                        >
                            <div class='text-editable'>
                                <div class='mr-1'>{{ header.items.find((e) => e.value == item[header.value])['text'] }}</div>
                                <v-icon small class=" d-none" :ref="'iconEdit_'+model[fieldName].findIndex((e) => e == item)+'_'+item.id">$pencil_l</v-icon>
                            </div>
                        <template v-slot:input>
                            <v-select
                                :items="header.items"
                                v-model="item[header.value]"
                                dense
                                hide-details
                                class="my-2"
                                filled
                                :no-data-text="$t('no-data-text')"
                            >
                            </v-select>
                        </template>
                        </v-edit-dialog>
                </div>
                <div v-else-if="header.type == 'actions'"  :key="header.value">
                    <v-btn depressed text class="square">
                        
                        <v-icon dark small @click="deleteRow(item)">
                            ${{header.defaultValue.icon}}
                        </v-icon>
                    </v-btn>                
                </div>
                <div v-else 
                
                    :key="header.value"
                    @mouseenter="hoverEditable('iconEdit_'+model[fieldName].findIndex((e) => e == item)+'_'+header.value)" 
                    @mouseleave="outEditable('iconEdit_'+model[fieldName].findIndex((e) => e == item)+'_'+header.value)" 
                    >
                    <v-edit-dialog
                        light
                        class="elevation-0"
                        >
                        <div class="text-editable">
                            {{item[header.value]}}
                            <v-icon small class="ml-2 d-none" :ref="'iconEdit_'+model[fieldName].findIndex((e) => e == item)+'_'+header.value">$pencil_l</v-icon>
                        </div>
                        <template v-slot:input > 
                            <v-text-field
                            v-model="item[header.value]"
                            label="Edit"
                            single-line
                            dense filled
                            hide-details
                            class="my-2"
                            :type="header.type == 'number' ? 'number' : 'text'"
                            autofocus
                            ></v-text-field>                        
                        </template>
                    </v-edit-dialog>
                </div>
            </template>
            
        </v-data-table>
        <v-btn small depressed color="primary" class="mt-2" @click="addRow">Ajouter</v-btn>
    </div>
</template>
<script>
export default {
    name:"vCustomTableForm",
    props: ["model", "fieldName", "field"],
    data() {
        return {
            headers: [
                {
                    text: "Libellé du planning",
                    value: "libelle",
                    type:"string",
                    defaultValue: "Valeur par défaut"
                },
                {
                    text: "Type de planning",
                    value: "planning_type",
                    type: "select",
                    items: [
                            {
                                text:"Users",
                                value:"users"
                            },
                            {
                                text:"Stock",
                                value:"stock"
                            },
                            {
                                text:"Autre",
                                value:"other"
                            },
                        ],
                    defaultValue: "users"
                },
                {
                    text: "Nombre de rdv simultanés acceptés",
                    value: "rdv_number",
                    type: "number",
                    defaultValue: 0
                },
                {
                    text: "Capacité d'accueil",
                    value: "accueil_capacity",
                    type: "number",
                    defaultValue: 0
                },
                {
                    text: "actions",
                    value: "actions",
                    type: "actions",
                    defaultValue: {
                        action:"delete",
                        type:"actions",
                        icon:"trash_l",
                        title:"actionDelete",
                        url:"/contact/delete",
                    }
                }
            ],
            items: [
                
                {
                    id:1,
                    libelle : "Vendeur",
                    planning_type: "users",
                    rdv_number:1,
                    accueil_capacity: 9,
                    actions:{
                        action:"delete",
                        type:"actions",
                        icon:"trash_l",
                        title:"actionDelete",
                        url:"/contact/delete",
                    }
                },
                {
                    id:2,
                    libelle :"Voitures",
                    planning_type: "stock",
                    rdv_number: 2,
                    accueil_capacity: 4,
                    actions:{
                        action:"delete",
                        type:"actions",
                        icon:"trash_l",
                        title:"actionDelete",
                        url:"/contact/delete",
                    }
                },
                {
                    id:3,
                    libelle : "Pizza",
                    planning_type: "other",
                    rdv_number: 1,
                    accueil_capacity: 6,
                    actions:{
                        action:"delete",
                        type:"actions",
                        icon:"trash_l",
                        title:"actionDelete",
                        url:"/contact/delete",
                    }
                }
            ],
        }
    },
    methods: {
        deleteRow(item){
            let indexToDelete = this.model[this.fieldName].findIndex((e) =>  e == item)
            this.model[this.fieldName].splice(indexToDelete, 1)
        },
        hoverEditable(reference) {
            this.$refs[reference][0].$el.classList.remove('d-none')
        },
        outEditable(reference) {
            this.$refs[reference][0].$el.classList.add('d-none')
        },
        addRow(){
            const newRow = {};
            this.field.headers.forEach(element => {
                newRow[element.value] = element.defaultValue
            });
            // const idMax = this.items.reduce(function(prev, current) {
            //     return (prev.id > current.id) ? prev : current
            // })
            // newRow.id = idMax.id + 1
            this.model[this.fieldName].push(newRow)
        }
    },
}
</script>
<style lang="scss">
    .data-custom-table {
        background-color:transparent !important;
        thead {
            tr {
                th {
                    border-bottom: 2px solid var(--v-darkgrey-base) !important;
                }
            }
        }
        .text-editable {
            display:flex;
        }
    }
    .test_planning {
        display:flex;
    }
</style>