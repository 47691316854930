<template>
    <v-dialog v-model="openDialogStats" fullscreen class="" :scrollable="true">
        <v-card class="lightgrey stats-contact">
            <v-toolbar tile flat dark class="bg-gradient">
                <!-- <v-toolbar-title>{{$t('dialogStatsContactTitle')}}</v-toolbar-title> -->
                <v-btn x-large depressed color="transparent" class="ml-2" dark @click="$emit('closeStatsContactDialog')"><v-icon small class="mr-2" >$prev</v-icon> {{ $t('back') }}</v-btn>
                <v-spacer></v-spacer>

                <v-btn-toggle mandatory outlined color="white" background-color="transparent" v-model="toggleValue" class="mr-2 btnToggleStats" @click.stop="">
                    <v-btn outlined small v-for="property in toggleProperties" :key="property.value" :value="property.value" style="border-color: rgba(255, 255, 255, 1) !important;">
                        <v-icon left>{{property.icon}}</v-icon>
                        <span>{{$t(property.label)}}</span>
                    </v-btn>
                </v-btn-toggle>

                <v-menu offset-y v-if="$func.hasRight('library/add') || (this.idType && $func.hasRight('export/export')) || (this.idType && this.idType == 'contactlist_id' && $func.hasRight('lead/update'))">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn outlined small v-bind="attrs" v-on="on">
                            {{$t('action')}}
                            <v-icon right dark>$dropdown</v-icon>
                        </v-btn>
                    </template>
                    <v-list dense>
                        <v-list-item v-if="$func.hasRight('library/add')" @click="addModel" link>
                            <v-list-item-title>{{$t('defineAsModel')}}</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="this.idType && $func.hasRight('export/export')" @click="exportData" link>
                            <v-list-item-title>{{$t('exportData')}}</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="this.idType && this.idType == 'contactlist_id' && $func.hasRight('lead/update')" @click="assignContacts" link>
                            <v-list-item-title>{{$t('assignContactsTo')}}</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="this.idType && this.idType == 'contactlist_id' && ($func.hasRight('import/add') || $func.hasRight('import/addData'))" @click="reassignContacts" link>
                            <v-list-item-title>{{$t('reassignContacts')}}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-toolbar>
            <v-card-text class="pt-5 px-12">
                <div v-if="toggleValue === 'stats'">
                    <div v-if="Object.keys(filters[0]).length > 0" class="px-3 py-2 pb-4 filtersDiv">
                        <div v-for="(filterType, index) in filters[0]" v-if="filterType.field !== 'contactId'" :key="'filter_'+index">
                            <v-chip class="darkgrey--text mr-2" v-if="['empty', 'notEmpty', 'valid', 'notValid', 'true', 'false', 'dateBeforeToday', 'dateAfterToday', 'dateEqualsToday'].includes(filterType.operator.value)" @click:close="deleteFilter(index)" close-icon="$clear" close outlined small>{{$t(filterType.field)}} : {{$t(filterType.operator.value)}}</v-chip>
                            <v-chip class="darkgrey--text mr-2" v-else-if="filterType.value_label" v-for="(filter, indexFilter) in filterType.value_label" :key="indexFilter" @click:close="deleteFilter(index, indexFilter)" close-icon="$clear" close outlined small>{{$t(filterType.field)}} : {{filter.to_trad ? $t(filter.label) : filter.label}}</v-chip>
                            <v-chip class="darkgrey--text mr-2" v-else v-for="(filter, indexFilter2) in filterType.value" :key="indexFilter2" @click:close="deleteFilter(index, indexFilter2)" close-icon="$clear" close outlined small>{{$t(filterType.field)}} : {{filter}}</v-chip>
                        </div>
                    </div>
                    <v-card class="mx-3">
                        <v-card-text class="heading pa-8 ">
                            <div v-for="(item, index) in data.header" :key="index+'_header'" @click="selectItem(index, item)" class="header-div">
                                <v-icon large :color="item.active ? 'primary' : 'grey'" class="mr-5">${{item.icon}}</v-icon>
                                <span :class="item.active ? 'primary--text' : 'nearblack--text'">
                                    <h3 :class="item.active ? 'primary--text' : 'nearblack--text'">{{item.value}}</h3>
                                    <div class="label">{{$t(item.label)}}</div>
                                </span>
                            </div>
                            <v-btn :disabled="innerFilters.length == 0" small depressed :color="innerFilters.length > 0 ? 'primary' : 'darkgrey'" @click="loadData()">{{$t('filter')}}</v-btn>
                        </v-card-text>
                    </v-card>
                    <v-container fluid class="ma-0">
                        <v-row dense>
                            <v-col cols="6" :key="barKey">
                                <barContactStatsComponent v-for="(graph, index) in barGraphs" :data="graph" :filters="filters[graph.label] ? filters[graph.label] : false" class="mb-2" :key="'bargraph_' + index" @addFilter="addFilter" @removeFilter="removeFilter" />
                            </v-col>
                            <v-col cols="6" >
                                <div class="donut-col" :key="donutKey">
                                    <donutContactStatsComponent v-for="(graph, index) in donutGraphs" :data="graph" :filters="filters[graph.label] ? filters[graph.label] : false" :key="'donutgraph_' + index" style="width:49%;" class="mb-2" @addFilter="addFilter" @removeFilter="removeFilter" :ref="'graph_' + graph.label" />
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
                </div>

                <DatatableV2 :key="datatableKey" :fromPopup="true" v-if="toggleValue === 'list'" :displayTitle="false" :flat="false" :url="apiUrl" :datatableId="'stat_datatable'" :otherData="{'segment': allFilters, 'retroplanning_id': retroplanning_id ? retroplanning_id : null}" :disableForm="true" :disableSaveParams="true"/>
            </v-card-text>
        </v-card>

        <FormDialogComponent v-if="form" ref="componentForm" :form="form" :action="'SET_DATA'" />
        <LibraryDialogComponent v-if="formLib" :form="formLib" :libType="'EXPORT'" @cancel="formLib = null;" :showLibraryDialog="showLibraryDialog" @hideLibraryDialog="showLibraryDialog = false;" :libParams="libParams" />
    </v-dialog>
</template>

<script>
import barContactStatsComponent from '@/components/dialogs/dialogStatsComponents/barContactStatsComponent';
import donutContactStatsComponent from '@/components/dialogs/dialogStatsComponents/donutContactStatsComponent';
import GenericDataService from '@/services/GenericDataService';
import DatatableV2 from '@/components/DatatableV2';
import LibraryDialogComponent from "@/components/dialogs/LibraryDialogComponent";
import getForm from "@/mixins/mixins";

export default {
    name: 'dialogStatsContactComponent',
    components:{
        barContactStatsComponent, 
        donutContactStatsComponent, 
        DatatableV2, 
        LibraryDialogComponent, 
        FormDialogComponent: () => import("@/components/dialogs/FormDialogComponent"),
    },
    mixins: [getForm],
    props:['openDialogStats', 'prefilter', 'id', 'idType', 'retroplanning_id'],
    data() {
        return {
            apiUrl: '/stats/contactList',
            innerFilters: [], // Filtres temporaires du header
            filters: [[]], // Filtres pour le post
            data: {
                header: [],
                graphs: []
            }, 
            toggleValue: "stats",
            toggleProperties: [{'value': 'stats', 'label': 'stats', 'icon': '$chart_pie_alt_l'}, {'value': 'list', 'label': 'list', 'icon': '$list_l'}],
            barKey: 0,
            donutKey: 0,
            formLib: null,
            showLibraryDialog: false,
            libParams: {},
            form: null,
            datatableKey: 0,
        }
    },
    created() {
        this.loadData(true);
    },
    methods: {
        loadData(loadWithId = false) {
            var url = '/stats/getStats';

            if(loadWithId) {
                url += '?' + this.idType + '=' + this.id;

                if(this.prefilter)
                    url += '&prefilter=' + this.prefilter;
            }

            GenericDataService.postData(url, this.allFilters).then((response) => {
                this.filters = response.data.data.filters.length == 0 ? [[]] : response.data.data.filters;
                this.data.header = response.data.data.header;
                this.data.graphs = response.data.data.graphs;
                this.innerFilters = [];
                this.barKey++;
                this.donutKey++;
            });
        },
        selectItem(index, item) {
            if(typeof this.data.header[index].active !== 'undefined') {
                this.data.header[index].active = !this.data.header[index].active;
                if(this.data.header[index].active) {
                    this.innerFilters.push(index);
                } else {
                    let indexToRemove = this.innerFilters.findIndex((e) => e == index)
                    this.innerFilters.splice(indexToRemove, 1);
                }
            }
        },
        deleteFilter(index, indexFilter = null) {
            if(this.filters[0][index].value.length > 1 && indexFilter !== null)
                this.filters[0][index].value.splice(indexFilter, 1);
            else
                this.filters[0].splice(index, 1);
            this.loadData();
        },
        addFilter(label, values){
            let graph = this.data.graphs.find(element => element.label === label);
            let valuesLabels = [];

            values.forEach(index => {
                if(graph.datas) {
                    let labels = Object.keys(graph.datas);
                    valuesLabels.push(labels[index]);
                } else {
                    valuesLabels.push(graph.labels[index]);
                }
            });

            graph.rule.value = valuesLabels

            this.filters[0].push(graph.rule);

            this.loadData();
        },
        removeFilter(label, value){
            
                let index = this.filters[label].findIndex((e) => e == value)
                this.filters[label].splice(index, 1)
            

            let ref = 'graph_'+label;
            this.$refs[ref][0].updateBorderColors()
            this.$forceUpdate()
        },
        exportData: function() {
            this.libParams[this.idType] = this.id;
            this.libParams["module_source_id"] = this.id;
            this.libParams["filters"] = JSON.stringify(this.filters);

            let url = "/library/getFilters?libType=EXPORT";
            for (const param in this.libParams)
                url += "&params[" + param + "]=" + encodeURIComponent(this.libParams[param]);

            GenericDataService.getData(url).then((response) => {
                this.formLib = response.data.data;
                this.showLibraryDialog = true;
            });
        },
        addModel(){
            this.getForm('/library/getForm?formName=SEGMENT&postdata[data]=' + JSON.stringify(this.filters));
        },
        assignContacts() {
            this.getForm('/contact/getMultiAssignForm?' + this.idType + '=' + this.id + '&filters=' + JSON.stringify(this.filters), false, false, null, null, true, true);
        },
        reassignContacts() {
            this.getForm('/contact/getReassignContactsForm?contactlist_ids[]=' + this.id + '&filters=' + JSON.stringify(this.filters), false, false, null, null, true, true);
        },
        customRefresh() {
            this.loadData();
            this.datatableKey++;
        }
    },
    computed: {
        barGraphs(){
            return this.data.graphs.filter((e) => e.type == "BarComponent" || e.type == "HorizontalBarComponent")
        },
        donutGraphs(){
            return this.data.graphs.filter((e) => e.type == "DonutComponent")
        },
        allFilters() {
            let filtersRet = this.filters;

            if(this.innerFilters.length > 0) {
                this.innerFilters.forEach(index => {
                    filtersRet[0].push(this.data.header[index].rule);
                });
            }

            return filtersRet;
        }
    },
}
</script>

<style lang="scss">
    .stats-contact {
        .btnToggleStats {
            .v-btn {
                border-color: rgba(255, 255, 255, 1) !important;
            }
            .v-btn:first-child {
                border-top-right-radius: 0 !important;
                border-bottom-right-radius: 0 !important;
            }
            .v-btn:nth-child(2) {
                border-top-left-radius: 0 !important;
                border-bottom-left-radius: 0 !important;
            }
        }
        .heading{
            display:flex;
            justify-content: space-between;
            align-items:center;
            .header-div {
                cursor:pointer;
                display: flex;
                align-items: center;
                h3 {
                    font-size:32px;
                    line-height:normal;
                }
                .label {
                    text-transform: uppercase;
                    font-size: 13px;
                }
            }
        }
        .donut-col {
            display:flex;
            justify-content: space-around;
            align-items:flex-start;
            flex-wrap:wrap;
        }
        .filtersDiv {
            display:flex;
        }
    }
</style>