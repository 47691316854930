<template>
  <div :class="this.field.noRow ? 'col col-12 pa-0 input-form' : 'row input-form mt-4'">
    <span v-if="field.noTranslateTitle || field.title" class="label">
        <b>{{field.noTranslateTitle ? field.noTranslateTitle : $t(field.title)}}</b>
        <span v-if="field.props && field.props.required" style="color:red">&nbsp;*</span>
        <infoTooltip v-if="field.tooltip" :tooltip="field.tooltip" />
    </span>
    <vue-tel-input-vuetify
        v-if="this.field.noRow"
        :label="''"
        :class="'phone-number input-form '"
        v-model="phone"
        v-bind="Object.assign({}, this.field.props, {'validate-on-blur': true})"
        placeholder=""
        solo
        flat
        mode="international"
        :input-options="options"
        v-on="{ ...{}, input }"
        defaultCountry="fr"
        :error="error"
        :error-messages="errorMessage"
        :single-line="false"
        :rules=[validate]
      />
    <div v-else class="col col-12 pa-0">
      <vue-tel-input-vuetify
        solo
        flat
        label=""
        :class="'phone-number '"
        v-model="phone"
        v-bind="Object.assign({}, this.field.props, {'validate-on-blur': true})"
        placeholder=""
        mode="international"
        :input-options="options"
        v-on="{ ...{}, input }"
        defaultCountry="fr"
        :error="error"
        :error-messages="errorMessage"
        :single-line="false"
        :rules=[validate]
      />
    </div>
  </div>
</template>

<script>
import VueTelInputVuetify from "vue-tel-input-vuetify/lib/vue-tel-input-vuetify";
import infoTooltip from '@/components/infoTooltip';

export default {
  components: {
    VueTelInputVuetify,
    infoTooltip,
  },
  props: ["model", "fieldName", "field", "fromArray"],
  data() {
    return {
      phone: "",
      options: {
        showDialCode: true,
        tabIndex: 0,
      },
      error: false,
      errorMessage: "",
      number: null,
      country: null,
    };
  },
  mounted() {
    if(this.model[this.fieldName])
      this.phone = this.model[this.fieldName];
  },
  watch: {
    model: {
        handler(val, oldval) {
        if(val && oldval && (val[this.fieldName] && oldval[this.fieldName]) && (val[this.fieldName] !== oldval[this.fieldName]))
          this.phone = val[this.fieldName] ? val[this.fieldName] : '+33';

        if(this.field.hasOwnProperty("depends_of") && this.field.depends_of.hasOwnProperty('call') &&( !this.model[this.fieldName])){
          if(val['call'] && val['call'][this.field.depends_of.call.field])
            this.model[this.fieldName] = val['call'][this.field.depends_of.call.field]
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    input(value, { number, valid, country }) {
      const re = /^[()\-+0-9\s]*$/;
      var isNotValid = !re.test(number.input);

      this.number = number;
      this.country = country;
      
      this.$emit('changeBtnState', true);

      if(((isNotValid || !valid) && (this.phone !== '+44' && this.phone !== '+33' && this.phone !== '+39' && this.phone !== '+31' && this.phone !== '+352' && this.phone !== '+32'))) {
        if(value === "" && this.fromArray !== true) {
          this.error = false;
          this.errorMessage = "";
          this.model[this.fieldName] = number.e164;
          this.$emit('changeBtnState', false);
        } else {
          this.error = true;
          this.errorMessage = this.$t('errorPatternPhoneNumber');
        }
      } else {
        this.error = false;
        this.errorMessage = "";
        this.model[this.fieldName] = number.e164;
        this.$emit('changeBtnState', false);
      }
    },
    validate() {
      if(this.field.props.required && !this.model[this.fieldName]) // Si le champ est requis
        return this.$t('requiredField');

      if(this.number && this.number.input) {
        var PhoneNumber = require('awesome-phonenumber');
        var phoneTmp = new PhoneNumber(this.number.input, this.country.iso2);

        if(phoneTmp.isValid() && !phoneTmp.isMobile() && this.field.props.onlyMobile) // Si on veux seulement un mobile
          return this.$t('mobileOnly');
        if(phoneTmp.isValid() && !phoneTmp.isFixedLine() && this.field.props.onlyFixedLine) // Si on veux seuelement un fixe
          return this.$t('fixedLineOnly');
      }

      return !this.error;
    }
  },
};
</script>

<style lang="scss">
.phone-number {
  .country-code {
    .v-select {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      border-top-left-radius: 4px !important;
      border-bottom-left-radius: 4px !important;
      .v-input__slot {
        &:before {
          display:none;
        }
        &:after {
          display:none;
        }
      }
      fieldset {
        border-right: none;
      }
    }
  }
  .v-text-field {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    .v-input__slot {
        &:before {
          display:none;
        }
        &:after {
          display:none;
        }
      }
    fieldset {
      border-left: none;
    }
  }
  .v-text-field--filled:not(.v-select) {
    border-top-left-radius: 0px;
  }
  .v-text-field--filled.v-input--dense.v-text-field--single-line > .v-input__control > .v-input__slot {
    min-height: 52px;
  }
  .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--single-line .v-input__append-inner {
    margin-top: 14px;
  }
  .vti__flag {
    margin-left: auto !important;
  }
  .v-input {
    margin-top:0 !important;
  }
}
</style>