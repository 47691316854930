<template>
<v-menu
    v-model="menu"
    bottom
    offset-y
    :close-on-content-click="false"
    :close-on-click="false"
    min-width="420"
    fixed
    :allow-overflow="true"
    right
    @click.stop=""
    :attach="datatableId ? '#cogs'+datatableId : '#cogs'"
    :activator="datatableId ? '#cogs'+datatableId : '#cogs'"
    content-class="dualListComponent"
    >
    <div class="dual-list" @click.stop="">
        <div class="lists">
            <div class="list-false">
                <v-list
                    nav
                    dense  
                >
                    <v-list-item-group
                    v-model="selectedItemListFalse"
                    color="primary"
                    multiple
                    >
                    <v-list-item
                        v-for="(item, i) in value.unselected"
                        :key="i"
                        @click.stop=""
                    >
                        <v-list-item-content>
                            <v-list-item-title> {{$t(item)}}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    </v-list-item-group>
                </v-list>
            </div>
                <div class="dual-list-icons">
                    <v-btn
                        small
                        depressed
                        color="primary"
                        class="square"
                        @click.stop="setAllTrue"
                    >
                        <v-icon color="white" small> $doubleright_l </v-icon>
                    </v-btn>
                    <v-btn
                        small
                        depressed
                        color="primary"
                        class="square"
                        @click.stop="setTrue"
                    >
                        <v-icon color="white" small> $next </v-icon>
                    </v-btn>
                    <v-btn
                        small
                        depressed
                        color="primary"
                        class="square"
                        @click.stop="setFalse"
                    >
                        <v-icon color="white" small> $prev </v-icon>
                    </v-btn>
                    <v-btn
                        small
                        depressed
                        color="primary"
                        class="square"
                        @click.stop="setAllFalse"
                    >
                        <v-icon color="white" small> $doubleleft_l </v-icon>
                    </v-btn>
                    
                </div>
            <div class="list-true">
                <v-list
                    nav
                    dense
                    
                >
                    <v-list-item-group
                    v-model="selectedItemListTrue"
                    color="primary"
                    multiple
                    >
                    <v-list-item
                        v-for="(item, i) in value.selected"
                        :key="i"
                        @click.stop=""
                    >
                        <v-list-item-content>
                            <v-list-item-title> {{$t(item)}}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    </v-list-item-group>
                </v-list>
                <div class="order-icons">
                    <v-btn
                        small
                        depressed
                        color="primary"
                        class="square"
                        @click.stop="orderUp"
                    >
                        <v-icon  color="white" small> $up_l </v-icon>
                    </v-btn>
                    <v-btn
                        small
                        depressed
                        color="primary"
                        class="square"
                        @click.stop="orderDown"
                    >
                        <v-icon  color="white" small> $down_l </v-icon>
                    </v-btn>
                </div>
            </div>
        </div>
        <div class="text-right">
            <v-btn small depressed color="error" class="mr-2" @click="resetColumns">{{$t('reset')}}</v-btn>
            <v-btn small @click="submitColumns" depressed color="primary">
                {{$t('buttonConfirm')}}
            </v-btn>
        </div>
    </div>
    </v-menu>
</template>
<script>
export default {
    props: ['value', 'datatableId'],
    data() {
        return {
            menu: false,
            selectedItemListTrue: [],
            selectedItemListFalse: [],
        }
    },
    watch: {
        menu(val){
        }
    },
    methods: {
        setMenuValue: function(){
            this.menu = false;
        },
        orderUp: function(){
            let indexFrom = parseInt(this.selectedItemListTrue);
            let indexTo = indexFrom - 1;
            if (indexTo >= 0){
                this.moveInArray(this.value.selected, indexFrom, indexTo)
                this.selectedItemListTrue = indexTo;
            }
        },
        orderDown: function(){
            let indexFrom = parseInt(this.selectedItemListTrue);
            let indexTo = indexFrom + 1;
            if (indexTo >= 0){
                this.moveInArray(this.value.selected, indexFrom, indexTo)
                this.selectedItemListTrue = indexTo;
            }
        },
        moveInArray: function(arr, from, to){
            var item = arr.splice(from, 1);
            arr.splice(to, 0, item[0]);
        },
        setFalse: function(){
            if(this.selectedItemListTrue != ""){
                this.selectedItemListFalse = []
                this.value.unselected.push(this.value.selected[this.selectedItemListTrue]);
                this.value.selected.splice(this.selectedItemListTrue, 1);
                this.selectedItemListTrue = ""
            }
        },
        setTrue: function(){
            if(this.selectedItemListFalse.length > 0){
                this.selectedItemListTrue = ""
                this.selectedItemListFalse.forEach(id => {
                    this.value.selected.push(this.value.unselected[id]);
                    this.value.unselected.splice(id, 1);
                    this.selectedItemListFalse = []
                });
            }
        },
        setAllTrue: function(){
            this.value.selected = this.value.selected.concat(this.value.unselected)
            this.value.unselected = []
        },
        setAllFalse: function(){
            this.value.unselected = this.value.unselected.concat(this.value.selected)
            this.value.selected = []
        },
        submitColumns: function(){
            this.menu = false;
            this.$emit('changeColumns')
        },
        resetColumns: function(){
            this.menu = false;
            this.$emit('resetColumns')
        },
    },
    computed: {
        
    },
}
</script>
<style lang="scss">
.dual-list {
    background-color:white;
    padding:10px;
}
.icons-datatable {
    .v-btn {
        text-transform:unset !important;
    }   
    
}

.dualListComponent {
    left:-369px !important;
    .v-list-item-group .v-list-item--active {
        background-color: var(--v-mediumgrey-base) !important;
    }
}
    .lists {
        display:flex;
        justify-content:space-between;
        
        .list-true, .list-false {
            width:45%;
            min-height:216px;
            max-height:216px;
            overflow:hidden;
            .v-list {
                height:100%;
                min-height:200px;
                max-height:200px;
                border-radius: 5px;
                border: 1px solid var(--v-lightgrey-base) !important;
                overflow:hidden;
                padding:0;
                margin: 8px;
            }
            .v-item-group {
                min-height:200px;
                max-height:200px;
                overflow-y:scroll;
                padding-right:7px;
            }
        }
        .list-true {
            display:flex;
            width:55%;
            .v-list {
                width:100%;
            }
            .order-icons {
                margin-left:4px;
                display:flex;
                justify-content:center;
                align-items:center;
                flex-direction: column;
                .v-btn {
                    margin:2px 0;
                }
            }
        }
        .dual-list-icons {
            display:flex;
            justify-content:center;
            align-items:center;
            flex-direction: column;
            .v-btn {
                margin:2px 0;
            }
        }
    }
</style>