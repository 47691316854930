<template>
  <div class="col col-12 pa-0 v-file-input">
    <span v-if="field.title" class="label"><b>{{$t(field.title)}}</b></span>
    <div class="thumbnailInput my-4" @click="openDialogMediatheque()">
      <span class="activeHover">
        <div v-if="!model[fieldName] || Object.keys(model[fieldName]).length === 0" class="uploadInput">
          <v-icon large>$paperclip_l</v-icon>
        </div>
      </span>
      <div v-if="model[fieldName] && Object.keys(model[fieldName]).length !== 0" class="uploadInput">
        <span v-if="!field.props.multiple">
          <embed v-if="model[fieldName].mime && model[fieldName].mime === 'application/pdf'" :src="model[fieldName].url + '#zoom=35'" width="300" height="200" />
          <v-img v-else-if="model[fieldName].thumb" :src="model[fieldName].thumb + '?option=x300,y200,crop'" width="300" height="200" ></v-img>
        </span>
        <span v-else>
          <ul class="mt-2 listItems">
            <li v-for="item in model[fieldName]" :key="item.id" class="mb-2">
              {{item.title}}
            </li>
          </ul>
        </span>
      </div>
    </div>

    <div v-if="this.field.specificationsText" class="specifications mt-4">
        <p v-for="(specification, index) in this.field.specificationsText" :key="index" class="mb-0">{{ $t(index) }} : {{ Array.isArray(specification) ? specification.join(", ") : $t(specification) }}</p>
    </div>

    <MediathequeDialogComponent :multiple="field.props.multiple" v-model="model[fieldName]" :openDialogMediatheque="dialogMediatheque" @close="closeDialogMediatheque" :prefilters="prefilters" :availableFilters="availableFilters" :upload="true" :accept="field.props && field.props.accept ? field.props.accept : null" />
  </div>
</template>

<script>
import validate from "../functions/validations";
import MediathequeDialogComponent from "@/components/dialogs/MediathequeDialogComponent";

export default {
  components: { MediathequeDialogComponent },
  props: ["model", "fieldName", "field"],
  mixins: [validate],
  data() {
    return {
      dialogMediatheque: false,
      prefilters: {},
      availableFilters: [],
    };
  },
  created() {
    this.prefilters = this.field.prefilters ? this.field.prefilters : {};
    this.availableFilters = this.field.availableFilters
      ? this.field.availableFilters
      : [];
  },
  methods: {
    openDialogMediatheque() {
      this.dialogMediatheque = true;
    },
    closeDialogMediatheque: function () {
      this.dialogMediatheque = false;
    },
  },
};
</script>

<style lang="scss">
.thumbnailInput {
  height: 200px;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
  border-radius: 4px;
  //border: 1px solid #cccccc;
  position:relative;
  .activeHover {
    width: 100%;
    height: 100%;
    position:absolute;
    .upload {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #f0f0f0;
      position:absolute;   
    }
  }
  .uploadInput {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f0f0f0;
    position:absolute;   
  }
  &:hover {
    .activeHover {
      .upload {
        background: #e0e0e0;
      }
    }
  }
}
.listItems {
  list-style: none;
  padding-left:0 !important;
  li {
    padding-left:10px;
    padding-bottom:7px;
    border-bottom:1px solid rgba(0, 0, 0, 0.2);
  }
}
.specifications {
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
</style>