<template>
  <v-dialog v-model="dialog" max-width="500" :style="{ zIndex: 200 }" @keydown.esc="cancel" @click:outside="cancel">
    <v-card>
      <v-toolbar dark class="bg-gradient" dense flat>
        <v-toolbar-title class="text-body-2 font-weight-bold white--text">
          {{ $t("formPhoneModalTitle") }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pa-4 black--text">
        <VPhoneInput :field="field" fieldName="phone" :model="{'phone': model}" @changeBtnState="changeBtnState" :fromArray="true" />
      </v-card-text>
      <v-card-actions class="pt-3">
        <v-spacer></v-spacer>
        <v-btn color="grey" text class="body-2 font-weight-bold" @click.native="cancel" >{{$t('confirmationCancel')}}</v-btn>
        <v-btn :disabled="disabled" color="secondary" depressed class="body-2 font-weight-bold"  @click.native="agree">{{$t('confirmationConfirm')}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import VPhoneInput from "@/components/forms/inputs/v-phone-input";

export default {
  name: "ConfirmDialogComponent",
  components: {
    VPhoneInput,
  },
  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      model: null,
      fieldName: null,
      field: null,
      disabled: false,
    };
  },
  methods: {
    open(model, fieldName, field) {
      this.dialog = true;
      this.model = model;
      this.fieldName = fieldName;
      this.field = field;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    },
    changeBtnState(state) {
      this.disabled = state;
    }
  },
};
</script>