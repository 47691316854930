<template>
  <div class="col pa-0" :class="field.props.center ? 'col-5' : 'col-12'" :style="field.props.center ? 'margin-right:auto; margin-left:auto;' : ''">
    <v-switch 
      :label="field.title ? $t(field.title) : ''"
      :class="field.props && field.props.required ? 'required mt-0' : 'mt-0'"
      v-model="model[fieldName]"
      color="secondary"
      inset
      v-bind="field.props"
      :rules=[validate]
    ></v-switch>
  </div>
</template>

<script>
import validate from '../functions/validations'

export default {
  props: ["model", "fieldName", "field"],
  mixins: [validate], 
  data() {
    return {};
  },
};
</script>

<style lang="scss">
</style>