<template>
    <div class="col col-12 pa-0 input-form v-custom-reassign-contacts">
        <v-row>
            <v-col cols="3">
                <span class="label">
                    <b>{{ $t("repartitionmode") }}</b>
                    <infoTooltip :tooltip="{ title: 'repartitionModeTooltipTitle', description: 'repartitionModeTooltipText' }" />
                </span>
                <v-select :items="['normal', 'smooth', 'force_smooth']" filled flat solo hide-details v-model="vendorRepartition" @change="recalculateVendorRepartition">
                    <template v-slot:selection="{ item }">
                        {{ $t(item) }}
                    </template>
                    <template v-slot:item="{ item }">
                        {{ $t(item) }}
                    </template>
                </v-select>

                <v-card color="primary" class="mt-5 white--text">
                    <v-card-title>
                        <strong>{{ $t("summary") }}</strong>
                    </v-card-title>
                    <v-card-text class="white--text">
                        <ul>
                            <li>{{ $t("totalcontacts") }} : {{ totalContacts }}</li>
                            <li>{{ $t("affectedcontacts") }} : {{ affectedContacts }}</li>
                            <li>{{ $t("notaffected") }} : {{ notAffected }}</li>
                        </ul>
                    </v-card-text>
                    <v-card-title>
                        <strong>{{ $t("assignments") }}</strong>
                    </v-card-title>
                    <v-card-text class="white--text">
                        <ul>
                            <li v-for="(candidate, index) in vendorMappingNumbers" v-show="vendorMappingNumbers.length > 0" :key="'candidate_' + index">
                                {{ candidate.name }} : {{ candidate.nb }}
                            </li>
                        </ul>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col>
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th>{{ $t("source") }}</th>
                                <th class="text-center">{{ $t("contactlistContact_count") }}</th>
                                <th>{{ $t("assignto") }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(content) in toRemap.vendors.values" :key="content.text_value">
                                <td>
                                    <span v-if="content.text_value != '' && content.text_value != null">{{ content.text_value }}</span>
                                    <span v-else>{{ $t("NCLabel") }}</span>
                                </td>
                                <td class="text-center">{{ content.nb }}</td>
                                <td style="width: 40%;">
                                    <v-autocomplete class="py-2" @blur="recalculateVendorRepartition" filled solo flat multiple hide-details v-model="content.mapped" :items="toRemap.vendors.candidates" :no-data-text="$t('no-data-text')"></v-autocomplete>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
                <!-- <pre>{{vendorMappingNumbers}}</pre> -->
            </v-col>
        </v-row>
    </div>
</template>

<script>
import GenericDataService from '@/services/GenericDataService';
import infoTooltip from '@/components/infoTooltip';

export default {
    components: { infoTooltip },
    props: ["model", "fieldName", "field"],
    data() {
        return {
            vendorRepartition: "normal",
            vendorsValuesClone: [],
        }
    },
    created() {
        this.vendorsValuesClone = this._.cloneDeep(this.toRemap.vendors.values);
        this.recalculateVendorRepartition();
    },
    computed: {
        totalContacts() {
            return this.model["ids"].length;
        },
        affectedContacts() {
            return this.vendorMappingNumbers.reduce((acc, obj) => acc + obj.nb, 0);
        },
        notAffected() {
            return this.totalContacts - this.affectedContacts;
        },
        toRemap() {
            return this.model[this.fieldName];
        },
        vendorsValues() {
            return this.toRemap.vendors.values;
        },
        vendorMappingNumbers: {
            get: function() {
                return this.toRemap.vendors.vendorMappingNumbers;
            },
            set: function(newVal) {
                this.model[this.fieldName].vendors.vendorMappingNumbers = newVal;
            }
        }
    },
    watch: {
        vendorsValues: {
            deep: true,
            handler(val){
                if(val && val.length){
                    for (let index = 0; index < val.length; index++) {
                        let newElement = val[index];
                        let oldElement = this.vendorsValuesClone[index];
                        this.checkForAll(oldElement, newElement)
                    }
                    this.vendorsValuesClone = this._.cloneDeep(this.vendorsValues)
                }
            }
        }
    },
    methods: {
        checkForAll(oldElement, newElement){
            if((oldElement && newElement) && (oldElement.mapped && newElement.mapped) && !this._.isEqual(oldElement.mapped, newElement.mapped)){
                if(oldElement.mapped.includes(0)){
                    newElement.mapped = newElement.mapped.filter((e) => e != 0)
                } else if(oldElement.mapped.includes(null)){
                    newElement.mapped = newElement.mapped.filter((e) => e != null)
                } else {
                    if(newElement.mapped.includes(0)){
                        newElement.mapped = newElement.mapped.filter((e) => e == 0)
                    } else if(newElement.mapped.includes(null)){
                        newElement.mapped = newElement.mapped.filter((e) => e == null)
                    }
                }
            }
        },
        recalculateVendorRepartition() {
            this.vendorMappingNumbers = [];

            let mapToAll = [];

            this.vendorsValues.forEach((element) => {
                let idsVendeurs = element['mapped'];

                if(!idsVendeurs.includes(0) && !idsVendeurs.includes(null)) {
                    if(idsVendeurs.length == 1) { // on a qu'une seule entrée, on se prend pas la tête tous à lui

                        let vendor = this.toRemap.vendors.candidates.find(candidate => candidate.value === idsVendeurs[0]);
                        let vendorMapping = this.vendorMappingNumbers.find(vendorMapping => vendorMapping.id === vendor.value);
                        if(vendorMapping) {
                            vendorMapping.nb += parseInt(element.nb);
                            vendorMapping.nbBySource[element['text_value']] = parseInt(element.nb);
                        } else {
                            let nbBySource = {};
                            nbBySource[element['text_value']] = parseInt(element.nb);
                            this.vendorMappingNumbers.push({ "id": vendor.value, "name": vendor.text, "nb": parseInt(element.nb), "nbBySource": nbBySource });
                        }
                        
                    } else { // on en a plusieurs pour la même valeur. On compte combien de contact à affecter, on divise par le nombre de vendeur à associer, et on attribue

                        let nToSet = parseInt(element.nb);
                        let nbParVendor = Math.floor(nToSet / idsVendeurs.length);
                        let reste = nToSet % idsVendeurs.length; // calcule le reste de la division pour évaluer 
                        let n = 0;
                        idsVendeurs.forEach((idVendeur) => {
                            n++;
                            let limit = nbParVendor + (n <= reste ? 1 : 0); // attribut au "$reste" premiers vendeur un contact supp pour lisser le nombre
                            let vendor = this.toRemap.vendors.candidates.find(candidate => candidate.value === idVendeur);
                            let vendorMapping = this.vendorMappingNumbers.find(vendorMapping => vendorMapping.id === vendor.value);
                            if(vendorMapping) {
                                vendorMapping.nb += parseInt(limit);
                                vendorMapping.nbBySource[element['text_value']] = parseInt(limit);
                            } else {
                                let nbBySource = {};
                                nbBySource[element['text_value']] = parseInt(limit);
                                this.vendorMappingNumbers.push({ "id": vendor.value, "name": vendor.text, "nb": parseInt(limit), "nbBySource": nbBySource });
                            }
                        });

                    }
                } else if(idsVendeurs.includes(0)) {
                    mapToAll.push(element['text_value']);
                }
            });

            if (mapToAll.length) {
                let idsVendeurs = this.toRemap.vendors.candidates.filter(candidate => candidate.value);

                if(idsVendeurs.length) { // SI pas de vendeurs, ben pas de mapping
                    if(this.vendorRepartition === "smooth" || this.vendorRepartition === "force_smooth") { // S'adapte afin de répartir au mieux les contacts sans retirer les contacts déjà affectés au user

                        let nbAssignmentVendors = [];
                        idsVendeurs.forEach((idVendeur) => {
                            let vendorMapping = this.vendorMappingNumbers.find(vendorMapping => vendorMapping.id === idVendeur.value);
                            nbAssignmentVendors[idVendeur.value] = vendorMapping ? vendorMapping.nb : 0;
                        });

                        mapToAll.forEach((text) => { // attributionMethod == smooth
                            let nToSet = parseInt(this.vendorsValues.find(vendorValue => vendorValue.text_value === text).nb);
                            for (let i = 0; i < nToSet; i++) {
                                let id = Object.keys(nbAssignmentVendors).reduce((a, b) => nbAssignmentVendors[a] < nbAssignmentVendors[b] ? a : b);
                                let vendor = this.toRemap.vendors.candidates.find(candidate => candidate.value === id);
                                let vendorMapping = this.vendorMappingNumbers.find(vendorMapping => vendorMapping.id === vendor.value);
                                if(vendorMapping) {
                                    vendorMapping.nb++;
                                    if(!vendorMapping.nbBySource[mapToAll.join('  ,  ')])
                                        vendorMapping.nbBySource[mapToAll.join('  ,  ')] = parseInt(0);
                                    vendorMapping.nbBySource[mapToAll.join('  ,  ')]++;
                                } else {
                                    let nbBySource = {};
                                    nbBySource[mapToAll.join('  ,  ')] = parseInt(1);
                                    this.vendorMappingNumbers.push({ "id": vendor.value, "name": vendor.text, "nb": 1, "nbBySource": nbBySource });
                                }
                                nbAssignmentVendors[id]++;
                            }
                        });

                        if(this.vendorRepartition === "force_smooth") { // attributionMethod == force_smooth => possibilité de piocher dans les affectations existantes pour lisser au maximum
                            while (Math.max(...Object.values(nbAssignmentVendors)) > Math.min(...Object.values(nbAssignmentVendors)) + 1) {
                                let maxId = Object.keys(nbAssignmentVendors).reduce((a, b) => nbAssignmentVendors[a] > nbAssignmentVendors[b] ? a : b);
                                let minId = Object.keys(nbAssignmentVendors).reduce((a, b) => nbAssignmentVendors[a] < nbAssignmentVendors[b] ? a : b);

                                let vendorMax = this.toRemap.vendors.candidates.find(candidate => candidate.value === maxId);
                                let vendorMappingMax = this.vendorMappingNumbers.find(vendorMapping => vendorMapping.id === vendorMax.value);
                                vendorMappingMax.nb--;
                                let sourceWithMax = Object.keys(vendorMappingMax.nbBySource).reduce((a, b) => vendorMappingMax.nbBySource[a] > vendorMappingMax.nbBySource[b] ? a : b);
                                vendorMappingMax.nbBySource[sourceWithMax]--;

                                let vendorMin = this.toRemap.vendors.candidates.find(candidate => candidate.value === minId);
                                let vendorMappingMin = this.vendorMappingNumbers.find(vendorMapping => vendorMapping.id === vendorMin.value);
                                if(!vendorMappingMin) {
                                    this.vendorMappingNumbers.push({ "id": vendorMin.value, "name": vendorMin.text, "nb": 0, "nbBySource": {} });
                                    vendorMappingMin = this.vendorMappingNumbers.find(vendorMapping => vendorMapping.id === vendorMin.value);
                                }
                                vendorMappingMin.nb++;
                                if(!vendorMappingMin.nbBySource[sourceWithMax])
                                    vendorMappingMin.nbBySource[sourceWithMax] = parseInt(0);
                                vendorMappingMin.nbBySource[sourceWithMax]++;

                                nbAssignmentVendors[maxId] -= 1;
                                nbAssignmentVendors[minId] += 1;
                            }
                        }

                    } else { // attributionMethod == normal

                        let nToSet = this.vendorsValues.reduce((acc, obj) => {
                            if (mapToAll.includes(obj.text_value))
                                acc += parseInt(obj.nb);
                            return acc;
                        }, 0);
                        let nbParVendor = Math.floor(nToSet / idsVendeurs.length); // compte le nombre à affecter par vendeur
                        let reste = nToSet % idsVendeurs.length; // calcule le reste de la division pour évaluer 
                        let n = 0;
                        idsVendeurs.forEach((idVendeur) => {
                            n++;
                            let limit = nbParVendor + (n <= reste ? 1 : 0); // attribut au "$reste" premiers vendeur un contact supp pour lisser le nombre
                            let vendorMapping = this.vendorMappingNumbers.find(vendorMapping => vendorMapping.id === idVendeur.value);
                            if(vendorMapping) {
                                vendorMapping.nbBySource[mapToAll.join('  ,  ')] = parseInt(limit);
                                vendorMapping.nb += parseInt(limit);
                            } else {
                                let nbBySource = {};
                                nbBySource[mapToAll.join('  ,  ')] = parseInt(limit);
                                if(limit > 0)
                                    this.vendorMappingNumbers.push({ "id": idVendeur.value, "name": idVendeur.text, "nb": parseInt(limit), "nbBySource": nbBySource });
                            }
                        });

                    }
                }
            }
        },
    },
};
</script>

<style lang="scss">
    .v-custom-reassign-contacts {
        .v-data-table {
            th {
                color: black !important;
            }
            tbody {
                tr {
                    height: 90px;
                }
            }
        }
    }
</style>