<template>
    <v-menu v-model="show" :offset-x='isOffsetX' :left='isLeft' :top="isTop" :offset-y='isOffsetY' min-width='175' :open-on-hover='isOpenOnHover' :transition='transition'>
        <template v-slot:activator="{ on }">
            <v-btn v-if='icon' v-on="on"><v-icon>{{ icon }}</v-icon></v-btn>
            <v-list-item dense v-else-if='isSubMenu' v-on="on" >
                <div  style="width:100%" class='d-flex justify-space-between'>
                   {{ name }}<v-icon small >$next</v-icon>
                </div>
            </v-list-item>
            <v-btn v-else v-on="on" @click.stop="" text tile ref="launcher" class="test" :style="style">{{ name }}</v-btn>
        </template>
        <v-list>
            <template v-for="(item, index) in menuItems">
                <varMenu v-if='item.items' :key='index' :name='item.name' :menuItems='item.items' @menuVarClick='emitClickEvent'
                :is-open-on-hover="false" :isLeft='isLeft' :isOffsetX="true" :isOffsetY="false" :isSubMenu="true"
                />
                <v-list-item v-else dense :key='"_"+index' @click.stop='emitClickEvent(item)'>
                    <v-list-item-title  style="font-size:13px">{{ item.text }}</v-list-item-title>
                </v-list-item>
            </template>
        </v-list>
    </v-menu>
</template>
<script>
import varMenu from '@/components/varMenu'
export default {
    name:"varMenu",
    components: {varMenu}, 
    props:['menuItems', 'name', "icon", "isOpenOnHover", "isOffsetX", "isOffsetY", "transition", "isSubMenu", "isLeft", "isTop", "hasTitle", "left"],
    data() {
        return {
            show: false,
            style: ""
        }
    },
    created() {
        this.getStyleMenu()
    },
    mounted() {
        this.getStyleMenu()
    },
    watch: {
        isLeft(val){
             this.getStyleMenu()
        }
    },
    computed: {
        
    },
    methods: {
        emitClickEvent (item) {
        // this.closeAllMenus() // Theoretically, create a method that does this as a workaround
        this.$emit('menuVarClick', item)
        },
        getStyleMenu(){
            let style = "position:absolute; z-index: 1; ";
            if(this.hasTitle){
                style += "top:30px; ";
            } else {
                style += "top:5px; ";
            }
            style += "left:"+this.left+"px; "
            
            this.style = style;
        }
    }
}
</script>
<style lang="scss">
    .test {
        &:focus:before {
            opacity:0 !important;
        }
    }
</style>