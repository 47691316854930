<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    :style="{ zIndex: options.zIndex }"
    @keydown.esc="cancel"
    @click:outside="cancel"
  >
    <v-card>
      <v-toolbar dark :color="options.color" class="bg-gradient" dense flat>
        <v-toolbar-title class="text-body-2 font-weight-bold white--text">
          {{ title }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text
        v-show="!!message"
        class="pa-4 black--text"
        v-html="message"
      ></v-card-text>
      <v-card-text v-if="options.needDoubleValidation" class="doubleValidation">
          <p class="error--text"><v-icon color="error" class="mr-3">$warning_l</v-icon>{{options.confirmationImpactTitle}}</p>
          <ul class="error--text">
            <li v-for='(impact, index) in options.confirmationImpacts' :key="index">{{impact.nb}} {{$t(impact.label)}}</li>
          </ul>
          <v-checkbox
              v-model="confirmationImpactCheck"
              :label="$t('impactCheckBoxLabel')"
              color="error"
              :value="true"
               v-if="options.deletable"
              hide-details
            ></v-checkbox>
          <p class="error--text" v-if="confirmationImpactError != ''">{{$t(confirmationImpactError)}}</p>
      </v-card-text>
      <SuccessErrorAlertComponent v-if="$store.state.alerts.message" />
      <v-card-actions class="pt-3">
        <v-spacer></v-spacer>
        <v-btn
          v-if="!options.noconfirm"
          color="grey"
          text
          class="body-2 font-weight-bold"
          @click.native="cancel"
          >{{$t('confirmationCancel')}}</v-btn
        >
        <v-btn
          color="primary"
          class="body-2 font-weight-bold"
          
          :loading="loader"
          v-if="options.deletable"
          @click.native="agree"
          >{{$t('confirmationConfirm')}}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import GenericDataService from '@/services/GenericDataService'
import SuccessErrorAlertComponent from '@/components/alerts/SuccessErrorAlertComponent'

import { mapActions } from "vuex";

const defaultOptions = {
  color: "grey lighten-3",
  width: 400,
  zIndex: 200,
  noconfirm: false,
  needDoubleValidation: false,
  confirmationImpactTitle: "",
  confirmationImpacts: [],
  action: '',
  ids: []
}

export default {
  name: "ConfirmDialogComponent",
  components: { SuccessErrorAlertComponent },
  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      title: null,
      options: {},
      confirmationImpactCheck: false,
      confirmationImpactError:"",
      loader:false
    };
  },
  created() {
    this.confirmationImpactCheck = false
  },
  methods: {
    ...mapActions("alerts", ["setAlert"]),
    open(title, message, options) {
      this.dialog = true;
      this.title = title;
      this.message = message;
      this.options = Object.assign({}, defaultOptions, options);
      if(!this.options.hasOwnProperty('deletable')) {
        this.options.deletable = true
      }
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
        this.loader = false;
      });
    },
    agree() {
      this.loader = true;
      if(this.options.needDoubleValidation){
        if(this.confirmationImpactCheck){
          if(this.options.action != '' && Object.keys(this.options.ids).length > 0){
            GenericDataService.postData(this.options.action, this.options.ids).then((response) => {
                this.resolve(response);
                this.loader = false;
                this.dialog = false;
            }).catch((e) =>  {
              this.setAlert({
                message: this.$t('errorAPIconfirm'),
                type: "error",
                timeout: 3000
              });
              this.loader = false;
            });
          } else {
            this.resolve(true);
            this.loader = false;
            this.dialog = false;
          }
        } else {
          this.confirmationImpactError = "errorCheckboxValidationLabel"
        }
      } else {
        if(this.options.action != ''){
            GenericDataService.postData(this.options.action, Object.keys(this.options.ids).length > 0 ? this.options.ids : []).then((response) => {
                this.resolve(response);
                this.loader = false
                this.dialog = false;
            }).catch((e) =>  {
              this.setAlert({
                message: this.$t('errorAPIconfirm'),
                type: "error",
                timeout: 3000
              });
              this.loader = false;
            });
        } else {
          this.resolve(true);
          this.loader = false;
          this.dialog = false;
        }
      }
      this.confirmationImpactCheck = false
    },
    cancel() {
      this.confirmationImpactCheck = false
      this.loader = false;
      this.resolve(false);
      this.dialog = false;
    },
  },
};
</script>
<style lang="scss">
  .doubleValidation {
    ul {
      padding:0;
      li {
        list-style: none;
      }
    }
  }
</style>