<template>
    <Component :is="component" :libType="libType" :model="model" :specData="specData" :isFromPopup="isFromPopup" :formModel="formModel" :colSize="colSize" :from="from"  v-on="$listeners" />
</template>

<script>
import CCar from "@/components/libraryComponent/cards/c-car";
import CLibMin from "@/components/libraryComponent/cards/c-lib-min";
import CCoach from "@/components/libraryComponent/cards/c-coach.vue";
export default {
    name: "LibraryComponentList",
    components: {
        CCar, CLibMin, CCoach
    },
    props: ["libType", "component", "model", "colSize", "from", "formModel", "specData", "isFromPopup"]
};
</script>

<style lang="scss">
</style>