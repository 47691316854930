<template>
  <div class="v-array col col-12 pa-0 mb-6">
    <div class="pb-5">
      <v-btn small depressed color="primary"  @click="addElemToArray()">
        <v-icon left dark small> $plus_l </v-icon> {{ $t(field.buttonTitle ? field.buttonTitle : "add") }}
      </v-btn>
    </div>

    <div class="v-row" v-if="model[fieldName] && model[fieldName].length > 0" :key="key">
      <div v-for="(item, index) in model[fieldName]" :key="index">
          <div class="row">
            <div class="col py-2 ma-auto">
              <div class="row">
                <div :class="'col-' + (12 / Object.keys(field.fields).length)" v-for="(otherField, index) in field.fields" :key="index">
                  <FormComponentList :field="otherField" :fieldName="index" :model="item" />
                </div>
              </div>
            </div>
            <div class="pt-4">
              <v-btn depressed icon class="mt-4" @click.stop="openConfirmModal(index)">
                <v-icon color="error" small> $trash_l </v-icon>
              </v-btn>
            </div>
          </div>
      </div>
    </div>

    <div v-else>
      <v-alert class="mt-2 mb-0" border="left" color="info" text dense>
        {{ $t("emptyArrayInfoMessage") }}
      </v-alert>
    </div>

    <ConfirmDialogComponent ref="confirmDelete" />
  </div>
</template>

<script>
import ConfirmDialogComponent from "@/components/dialogs/ConfirmDialogComponent";

export default {
  name: "VArray",
  components: {
    ConfirmDialogComponent,
    FormComponentList: () => import('@/components/forms/FormComponentList')
  },
  props: ["model", "fieldName", "field"],
  data() {
    return {
      key: 0
    };
  },
  created() {
    //if (!this.model[this.fieldName] || typeof(this.model[this.fieldName]) === "object") {
    if (!this.model[this.fieldName]) {
        this.$set(this.model, this.fieldName, []);
    }
  },
  methods: {
    //ouverture de la modale de confirmation de delete
    async openConfirmModal(index) {
      if (await this.$refs.confirmDelete.open(this.$t("confirmDelete"), this.$t("confirmDeleteMessage"))) {
        this.model[this.fieldName].splice(index, 1);
        this.key++;
      }
    },
    addElemToArray() {
       //if (!this.model[this.fieldName] || typeof(this.model[this.fieldName]) === "object") {
    if (!this.model[this.fieldName]) {
        this.model[this.fieldName] = [];
      }
      this.model[this.fieldName].push({});
      this.$forceUpdate();
    },
  },
};
</script>
