<template>
    <div class="col col-12 pa-0">
        <div class="col col-12 pa-0" v-for="(otherField, otherFieldName) in field.fields" :key="otherFieldName + '_' + key">
            <div class="row ma-0 objectClass">
                <FormComponentList :field="otherField" :fieldName="otherFieldName" :model="model" />
            </div>
        </div>
    </div>
</template>

<script>
import GenericDataService from '@/services/GenericDataService';

export default {
    name: "VCustomItemCar",
    components: {
        FormComponentList: () => import('@/components/forms/FormComponentList')
    },
    props: ["model", "fieldName", "field"],
    data() {
        return {
            key: 0,
        };
    },
    computed: {
        carId() {
            return this.model["car.id"];
        },
    },
    watch: {
        carId() {
            this.loadCarData();
        },
    },
    created() {
        if(this.model['item_id']) {
            this.$set(this.model, "car.id", this.model['item_id']);
        }

        this.loadCarData();
    },
    methods: {
        loadCarData() {
            if(this.carId === null || typeof(this.carId) === "undefined")
                this.$set(this.model, "car.id", 'NEW');

            if(this.carId === 'NEW') {
                for (const [key, value] of Object.entries(this.model)) {
                    if(key.includes('car.') && key !== 'car.id') {
                        if(Array.isArray(value))
                            this.$set(this.model, key, []);
                        else
                            delete this.model[key];
                    }
                }
                this.key++;
            } else {
                GenericDataService.getData("contact/" + this.model['contact_id'] + "/getItemForm?type=car&id=" + this.carId).then((response) => {
                    for (const [key, value] of Object.entries(response.data.data.model)) {
                        this.$set(this.model, 'car.' + key, value);
                    }
                    this.key++;
                });
            }
        },
    },
};
</script>
