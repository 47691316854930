<template>
  <div class="col col-12 pa-0">
    <v-radio-group>
      <VRadio></VRadio>
      <VRadio></VRadio>
    </v-radio-group>
  </div>
</template>

<script>
import VRadio from "@/components/forms/inputs/v-radio";

export default {
  components: {
    VRadio,
  },
  props: [],
  data() {
    return {};
  },
};
</script>

<style lang="scss">
.v-input--radio-group--column {
  .v-input--radio:not(:last-child):not(:only-child) {
    margin-bottom: 8px;
  }
}
</style>