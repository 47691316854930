<template>
    <v-dialog fullscreen v-model="displayAddList" scrollable>
        <v-card height="100%" color="lightgrey">
            <v-toolbar tile flat dark  class="bg-gradient" >
                <div style="display:flex; width:100%; height:80px; align-items:center; justify-content:space-between;">
                    <div class="d-flex align-center">
                        <v-btn depressed color="transparent" class="square mr-2" @click="$emit('close')"><v-icon dark small>$prev</v-icon></v-btn>
                        <v-toolbar-title class="d-flex align-center">{{$t("addlist")}}</v-toolbar-title>
                    </div>
                    <div>
                        <v-btn outlined dark depressed @click="cancelAdd">{{$t('cancel')}}</v-btn>
                        <v-btn outlined :loading="loader" dark depressed @click="addList" class="ml-3">{{$t('buttonConfirm')}}</v-btn>
                    </div>
                </div> 
            </v-toolbar>
            <v-card-text class="contactListTab lightgrey pt-10">
                <v-container>
                    <div class="d-flex justify-end">
                        <v-btn class="mb-1 mr-4 bg-gradient" depressed @click="openLibraryDialog">{{$t('createList')}}</v-btn>
                    </div>
                    <v-tabs hide-slider  background-color="lightgrey" vertical v-model="tab">
                            <v-tab href="#all" :key="'#all'">
                                <template v-slot:default>
                                    <div style="text-transform: none; width:200px;">
                                        {{$t('all')}}
                                    </div>
                                </template>
                            </v-tab>
                            <v-tab v-for="(entity) in entities" :key="'#tab_'+entity.value" :href="'#tab_'+entity.value">
                                <template v-slot:default>
                                    <div style="text-transform: none;">
                                        {{entity.text}}
                                    </div>
                                </template>
                            </v-tab>
                            <v-tab-item value="all" class="lightgrey">
                                <div class="listContent pa-4" v-if="lists.length > 0">
                                    <v-card v-for="(list, index) in lists" :key="'allList_'+index" class="px-3 mb-3">
                                        <v-card-title class="justify-space-between">
                                            <div class="d-flex align-center">
                                                <v-checkbox v-model="list.selected" class="mr-4" @change="clickCheck($event, list.id)"></v-checkbox>
                                                <div>
                                                    <b style="font-weight: 900;">{{list.name}} <v-chip :color="list.type == 'STATIC' ? 'primary' : 'pink'" class="ml-3" dark small>{{$t(list.type)}}</v-chip></b>
                                                    <div>{{(entities.find((e) => e.value == list.entity_id).text)}}</div> 
                                                </div>
                                            </div>
                                            <div>
                                                <b style="font-weight: 900;">{{list.contact_count}}</b> Contacts 
                                                <v-btn depressed text class="square ml-4" @click="openStats(list)">
                                                    <v-icon dark small> $search_l </v-icon>
                                                </v-btn>
                                            </div>
                                        </v-card-title>
                                    </v-card>
                                </div>
                                <div v-else class="listContent pa-4">
                                    <v-alert border="left" class="mt-0 mb-0" text dense color="info">
                                        <template v-slot:prepend>
                                            <v-icon color="info" class="mr-3" small>
                                                $warning_s
                                            </v-icon>
                                        </template>
                                        {{$t('noListsFound')}} 
                                    </v-alert>
                                </div>
                            </v-tab-item>
                            <v-tab-item v-for="(entity) in entities"  :key='"tab_"+entity.value' :value="'tab_'+entity.value" class="lightgrey">
                                <div class="listContent pa-4" v-if="subLists[entity.value] && subLists[entity.value].length > 0">
                                    <v-card v-for="(list, index) in subLists[entity.value]" :key="'allList_'+index" class="px-3 mb-3"> 
                                        <v-card-title class="justify-space-between">
                                            <div class="d-flex align-center">
                                                <v-checkbox v-model="list.selected" class="mr-4" @change="clickCheck($event, list.id)"></v-checkbox>
                                                <div>
                                                    <b style="font-weight: 900;">{{list.name}} <v-chip :color="list.type == 'STATIC' ? 'primary' : 'pink'" class="ml-3" dark small>{{$t(list.type)}}</v-chip></b>
                                                    <div>{{(entities.find((e) => e.value == list.entity_id).text)}}</div> 
                                                </div>
                                            </div>
                                            <div>
                                                <b style="font-weight: 900;">{{list.contact_count}}</b> Contacts 
                                                <v-btn depressed text class="square ml-4" @click="openStats(list)">
                                                    <v-icon dark small> $search_l </v-icon>
                                                </v-btn>
                                            </div>
                                        </v-card-title>
                                    </v-card>
                                </div>
                                <div v-else class="listContent pa-4">
                                    <v-alert border="left" class="mt-0 mb-0" text dense color="info">
                                        <template v-slot:prepend>
                                            <v-icon color="info" class="mr-3" small>
                                                $warning_s
                                            </v-icon>
                                        </template>
                                        {{$t('noListsFound')}} 
                                    </v-alert>
                                </div>
                            </v-tab-item>
                    </v-tabs>
                </v-container>
            </v-card-text>
        </v-card>
        
        <dialogStatsContactComponent v-if="openDialogStats" @closeStatsContactDialog="openDialogStats = false" :openDialogStats="openDialogStats" :id="contactlistId" :retroplanning_id="retroplanning.id" :idType="'contactlist_id'" />
        <LibraryDialogComponent v-if="formLib" :form="formLib" :libType="libType" @cancel="formLib = null;" :showLibraryDialog="showLibraryDialog" @hideLibraryDialog="showLibraryDialog = false;" @customRefresh="customRefresh" :libParams="{ 'operationId': operationId }" />
    </v-dialog>
</template>

<script>
import GenericDataService from '@/services/GenericDataService'
import dialogStatsContactComponent from '@/components/dialogs/dialogStatsContactComponent';
import LibraryDialogComponent from "@/components/dialogs/LibraryDialogComponent";

export default {
    name: "addListDialogComponent",
    props: ["displayAddList", "entities", "retroplanning", "excludedIds", "operationId", "loader"],
    components:{
        FormDialogComponent: () => import("@/components/dialogs/FormDialogComponent"),
        dialogStatsContactComponent,
        LibraryDialogComponent,
    },
    data() {
        return {
            lists : [],
            subLists: {}, 
            tab: 0 ,
            selectedIds: [],
            openDialogStats: false,
            contactlistId: null,
            formLib: null,
            libType: "SEGMENT",
            showLibraryDialog: false,
        }
    },
    mounted() {
        if(this.lists.length == 0 && this.entities && this.entities.length > 0){
            GenericDataService.getData('contactlist/getListByEntitiesId?entityIds=['+this.entities.map((e) => {return e.value})+']&excludedIds=['+this.excludedIds+']'+(this.operationId ? "&operation_id="+this.operationId : "")).then((response) => {
                this.lists = response.data.data
                this.entities.forEach(entity => {
                    this.subLists[entity.value] = this.lists.filter((e) => e.entity_id == entity.value)
                });
            })
        }
    },
    watch: {
        displayAddList(val){ 
            if(val){
                GenericDataService.getData('contactlist/getListByEntitiesId?entityIds=['+this.entities.map((e) => {return e.value})+']&excludedIds=['+this.excludedIds+']'+(this.operationId ? "&operation_id="+this.operationId : "")).then((response) => {
                    this.lists = response.data.data
                    this.entities.forEach(entity => {
                        this.subLists[entity.value] = this.lists.filter((e) => e.entity_id == entity.value)
                    });
                })
            }
        }
    },
    methods: {
        refreshLists(){
            GenericDataService.getData('contactlist/getListByEntitiesId?entityIds=['+this.entities.map((e) => {return e.value})+']&excludedIds=['+this.excludedIds+']'+(this.operationId ? "&operation_id="+this.operationId : "")).then((response) => {
                this.lists = response.data.data
                this.entities.forEach(entity => {
                    this.subLists[entity.value] = this.lists.filter((e) => e.entity_id == entity.value)
                });
            })
        },
        cancelAdd(){
            this.selectedIds = []
            this.tab = 0;
            this.$emit('close');
        },
        addList(){
            this.$emit('addToList', this.selectedIds, this.retroplanning)
            setTimeout(() => {
                this.selectedIds = []
            }, 500);
            
        },
        clickCheck($event, id){
            if($event){
                this.selectedIds.push(id)
            } else {
                this.selectedIds.splice(this.selectedIds.findIndex((e) => e == id), 1)
            }
        },
        openStats(item) {
            this.contactlistId = item.id;
            this.openDialogStats = true;
        },
        openLibraryDialog() {
            GenericDataService.getData("/library/getFilters?libType=" + this.libType).then((response) => {
                this.formLib = response.data.data;
                this.showLibraryDialog = true;
            });
        },
        customRefresh() {
            this.refreshLists();
        }
    },
}
</script>
<style lang="scss">
    .contactListTab {
        .v-tabs-items {
            background-color:var(--v-lightgrey-base) !important;
        }
        .listContent {
            border-left: 1px solid rgba(0,0,0,0.2) !important;
        }
    }
</style>