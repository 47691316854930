<script>
import { HorizontalBar, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  extends: HorizontalBar,
  props: ['dataSet', 'maintainAspectRatio'],
  data() {
      return {
          options: {
            responsive:true,
            maintainAspectRatio: this.maintainAspectRatio ? true : false, 
            onClick:this.handle,
            layout: {
                padding: {
                    left: 0,
                    right: 0, 
                    top: 5,
                    bottom: 5
                }
            },
            legend: {
                display: false,
                position: 'bottom',

            },
            tooltip: {
                enabled: false
            },
            animation: {
                duration:0,
            },
            title:{
                display: false,
                text: "test"
            },
            indexAxis: 'y',
            scaleBeginAtZero : true,
            beginAtZero: true,
            tooltips: {
                callbacks: {
                    label: function(tooltipItem, data) {
                        return " " + tooltipItem['value'] + (data['datasets'][0]['percents'] ? '('+data['datasets'][0]['percents'][tooltipItem['index']]+'%)' : '');
                    }
                },
            },  
            elements:{
                bar:{
                    borderWidth: 5,
                    borderColor: "rgba(0,0,0,0.30)"
                }
            },
            scales: {
                yAxes: [{
                    id: 'y-axis-0',
                    gridLines: {
                        display: true,
                        lineWidth: 1,
                        color: "rgba(0,0,0,0.30)"
                    },
                    borderWidth: 40,
                    ticks: {
                        beginAtZero:true,
                        mirror:false,
                        suggestedMin: 0,
                        suggestedMax: 500
                    },
                    afterBuildTicks: function(chart) {

                    }
                    }],
                    xAxes: [{
                    id: 'x-axis-0',
                    gridLines: {
                        display: false
                    },
                    ticks: {
                        beginAtZero: true,
                        stepSize:1
                    }
                    }]
            }
          }
      }
  },
  watch: {
      chartData : {
          deep:true,
          handler(val){
            //this.$data._chart.destroy()
            //this._data._chart.update()
            //this.renderChart(this.chartData, this.options) 
          }
      }
  },
  computed: {
      chartData: function() {
          const data = this.dataSet;
          data.datasets.forEach(dataset => {
              let newColors = []
              if(typeof(dataset.backgroundColor) == 'array'){
                dataset.backgroundColor.forEach(color => {
                    if(this.$vuetify.theme.themes.light[color]){
                        const newColor = this.$vuetify.theme.themes.light[color]
                        newColors.push(newColor)
                    } else {
                        newColors.push(color)
                    }
                    
                })
                 dataset.backgroundColor = newColors 
              } 
              
          });
          return data
      }
  },
  methods: {
    handle (point, event) {
    	const item = event[0]
        if(item && item._chart){
            this.$emit('selectItem', item._index);
        }
    }
  },
  mounted () {
      this.options.title.text = this.chartData.label
      this.renderChart(this.chartData, this.options)              
  },
  
}
</script>

<style lang="scss">
    
</style>