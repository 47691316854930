<template>
    <div class="containerRepeater pt-2 pl-2 pr-2 pb-2 lightgrey">
        <div class="containerOr" v-for="(orCondition, index) in conditions" :key="'orDiv_'+index">
            <div class="containerAnd" :class="(indexAnd > 0 || index > 0) ? 'mt-2' : ''" v-for="(andCondition, indexAnd) in orCondition" :key="'andDiv_'+indexAnd">
                <v-btn v-if="index != 0 || indexAnd > 0" fab x-small depressed color="primary" class="mr-2" @click="deleteAnd(index, indexAnd)"><v-icon small>$minus_circle_l</v-icon></v-btn>
                <v-text-field v-if="type !='date'" :type="(type =='string' || type == 'email') ? 'text' : 'number'" filled dense hide-details v-model="conditions[index][indexAnd]"></v-text-field>
                <v-btn class="square ml-2" depressed small color="primary" @click="addAnd(index)"><v-icon small>$plus_r</v-icon></v-btn>
            </div>
            <v-btn depressed small class="mt-2" color="primary" @click="addOr">{{$t('or')}}</v-btn>
        </div>
    </div>
</template>
<script>
export default {
    name: "repeaterFieldComponent",
    props: ['value', 'type'],
    data() {
        return {
            
        }
    },
    methods: {
        addOr() {
            let newOr = [""]
            this.conditions.push(newOr)
        },
        addAnd(index){
            this.conditions[index].push("")
        },
        deleteAnd(index, indexAnd) {
            if(this.conditions[index].length == 1){
                this.conditions.splice(index, 1)
            } else {
                this.conditions[index].splice(indexAnd, 1)
            }
        }
    },
    computed: {
        conditions: {
            // getter
            get: function () {
                return this.value
            },
            // setter
            set: function (newValue) {
                this.$emit('input',  newValue)
            }
        }
    },
}
</script>
<style lang="scss">
    .containerRepeater {
        border-radius:5px;
        .containerOr {
            .containerAnd {
                display: flex;
                align-items: center;
            }
        }
    }
</style>