<template>
  <div class="col col-12 pa-0 input-form">
    <span v-if="field.title" class="label">
      <b>{{field.title_not_to_trad ? field.title : $t(field.title)}}</b>
      <span v-if="field.props && field.props.required" style="color:red">&nbsp;*</span>
      <infoTooltip v-if="field.tooltip" :tooltip="field.tooltip" />
    </span>
    
    <v-text-field
      :label="field.title ? $t(field.title) : ''"
      v-model="model[fieldName]"
      v-bind="props"
      :rules=[validate]
      >
        <template v-slot:prepend-inner v-if="field.prepend_inner">
          {{ field.prepend_inner.to_trad ? $t(field.prepend_inner.text) : field.prepend_inner.text }}
        </template>
      </v-text-field>
    <div v-if="field.jsonExample">
        <span><b>{{ $t('example') }} :</b></span>
       <pre>{{ field.jsonExample }}</pre>
    </div>
  </div>
</template>

<script>
import validate from '../functions/validations'
import infoTooltip from '@/components/infoTooltip';

export default {
  props: ["model", "fieldName", "field"],
  components: { infoTooltip },
  mixins: [validate],
  data() {
    return {};
  },
  computed: {
    props() {
      if(this.field.props && this.field.props.placeholder && !this.field.props.placeholderAlreadyTranslated) {
        this.field.props.placeholderAlreadyTranslated = true;
        this.field.props.placeholder = this.$t(this.field.props.placeholder);
      }
      return this.field.props;
    }
  }
};
</script>

<style lang="scss">
.input-form {
  .v-input__slot {
    background-color: var(--v-lightgrey-base) !important;
  }
}
</style>