<template>
    <div class="col col-12 pa-0 input-form" :style="field.props.hidden ? 'display:none; ' : ''">
        <p v-if="field.title" style="width:100%;" class="label mb-1">
            <b>{{$t(field.title)}}</b>
            <span v-if="field.props && field.props.required" class="error--text"> *</span>
            <infoTooltip v-if="field.tooltip" :tooltip="field.tooltip" />
        </p>

        <v-btn-toggle hide-details dense group borderless :mandatory="(field.props && field.props.required && !field.props.hasOwnProperty('mandatory')) ? true : (field.props.hasOwnProperty('mandatory') ? field.props.mandatory : false)" v-model="model[fieldName]" active-class="btnActive" :class="field.props && field.props['hide-details'] ? '' : 'mb-4'">
            <v-btn dense dark depressed class="white--text btnSpec" :disabled="(property.hasOwnProperty('disabled') && property.disabled)" 
                :style="model[fieldName] == property.value ? 'background-color:var(--v-primary-base) !important;' : ((property.hasOwnProperty('disabled') && property.disabled) ? 'background-color:var(--v-lightgrey-base) !important;' : 'background-color:var(--v-darkgrey-base) !important;')" 
                v-show="!property.hide" v-for="property in items" 
                :key="property.value" 
                :value="property.value"> 
                {{$t(property.label)}}
            </v-btn>
        </v-btn-toggle>
    </div>
</template>
<script>
import infoTooltip from '@/components/infoTooltip';

export default {
  props: ["model", "fieldName", "field"],
  components: { infoTooltip },
  data() {
    return {};
  },
  mounted() {},
  computed: {
      items() {
          return this.field.props.items ? this.field.props.items : this._.uniqBy(this.field.properties,(obj) => obj.value);
      }
  },
  methods: {},
};
</script>

<style lang="scss">
    .v-btn-toggle > .v-btn.v-btn {
        border-radius:5px !important;
    }
    .v-btn-toggle .v-btn--disabled {
        background-color: #CCC !important;
    }
    .btnSpec {
        border-radius:5px;
    }
</style>