<template>
<div style="height:100%;" class="workflowComponent">
    <div style="overflow:hidden; height:100%;">
        <div class="icons">
            <v-btn class="square btnPlus mr-2" id="btnPlus" small depressed color="primary" @click="zoomIn"><v-icon>$plus_l</v-icon></v-btn>
            <v-btn class="square btnMoins mr-2" id="btnMoins" small depressed color="primary" @click="zoomOut"><v-icon>$minus_r</v-icon></v-btn>
            <v-btn small depressed class="mr-2" color="primary" @click="zoomReset" v-if="scaleAmount != 1">100%</v-btn>
            <v-btn small depressed color="primary" v-if="!preview" @click="displayExitTriggersDialog">{{$t('addExitTriggers')}}</v-btn>
        </div>
        <div class="WorkflowContent d-flex">
            <div class="containerDiv" :style="(workflow.exit_triggers && workflow.exit_triggers.length > 0) ? 'width: calc(100% - 400px);' : 'width:100%;'" ref="containerDivRef" @wheel.shift="onWheel" v-dragscroll>
                <div ref="containerWorkflow" 
                    style="left: 0; top:30px; position: absolute; transform-origin:0 0; min-height:calc(100% - 30px);">
                    <WorkflowItemComponent :uniqueId="uniqueId" :preview="preview" :item="config" :stats="stats" :workflow="workflow" @addChild="openDrawerToAdd" @moveItem="moveItem" @editItem="editItem" @deleteNodeWithChild="deleteNodeWithChild" @deleteNodeWithoutChild="deleteNodeWithoutChild" :key="componentKey"/>
                    <div class="wheelWorkflow"></div>
                    <svg version="1.1" :id="StrokesId" class="StrokesClass" ref="strokeContainer" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        style="enable-background:new 0 0 400 150;" xml:space="preserve">
                    </svg>
                </div>
                <v-alert
                    border="top"
                    color="red lighten-2"
                    style="position:absolute; top: 20px; right:20px;"
                    outlined
                    dense
                    dark 
                    transition="scale-transition"
                    v-show="workflow.active == '0' && workflow.tobeconfig == '0' && (this.$route.params.operation_id || (!this.$route.params.operation_id && !this.$route.params.operationtype_id))"
                    >
                    <p class="mb-0 mt-1"><v-icon color="error" class="mr-2">$circleinfo_d</v-icon>{{$t('workflowNotActivated')}}<v-btn @click="activateWF" class="ml-2" outlined color="error" x-small><span style="font-size:12px;">{{$t('activate')}}</span></v-btn></p>
                </v-alert>
            </div>
            <div v-if="workflow.exit_triggers && workflow.exit_triggers.length > 0" class="exitTriggers">
                <div class="exitTriggerDiv">
                    <div class="exitTriggerContainer pa-2">
                        <h2>{{$t('exitTriggers')}}</h2>
                        <v-expansion-panels v-model="triggersOpen" multiple accordion flat>
                            <v-expansion-panel v-for="(trigger, index) in workflow.exit_triggers" :key="'exitCondition_'+index" class="mb-2 triggerPanel">
                                <v-expansion-panel-header hide-actions class="pa-3">
                                    <template  v-slot:default="{open}">
                                        <div class="d-flex justify-space-between align-center">
                                            {{$t(trigger.text)}}
                                            <span>
                                            <v-icon color="black" small :class="open ? 'icon rotate' : 'icon'" v-if="trigger.config">
                                                $dropdown 
                                            </v-icon>
                                            <v-icon color="black" @click.stop="removeExitTrigger(index)" small class="icon ml-2">
                                                $trash_d 
                                            </v-icon>
                                            </span>
                                        </div>
                                    </template>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content v-if="trigger.config">
                                    <WorkflowConfigTrigger :config="trigger" :workflow="workflow" :hideBtn="true" @recordTriggerConf="$emit('saveWorkflows')"/>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </div>
                </div>
            </div>
            
        </div>
        
    </div>
    <v-navigation-drawer
      v-model="drawer"
      right
      overlay-opacity="0.2"
      temporary
      stateless
      @input='changeDrawer'
      width="400"
      app
    >   
        <v-toolbar tile flat dark class="bg-gradient" >
            <v-toolbar-title class="d-flex" style="width:100%; align-items:center; justify-content:space-between;">
                <span v-if="!edit">
                    <v-btn text class="square" v-if="!firstStep" @click="goToChooseElement">
                        <v-icon>$prev</v-icon>
                    </v-btn>
                    {{$t('addWorkflowStep')}}
                </span>
                <span v-else>
                    {{$t('editWorkflowStep')}}
                </span>
                <v-btn text class="square" @click="closeDrawer">
                    <v-icon >$close</v-icon>
                </v-btn>    
            </v-toolbar-title>
        </v-toolbar>
        <WorkflowAddElement @addElement="addElement" v-if="firstStep"/>
        <WorkflowConfigElement :element="elementToAdd" :additionnalContexts="additionnalContexts" :workflow="workflow" :edit="edit" @addChild="addChild" @closeDrawer="closeDrawer" @updateItem="updateItem" :operationId="operationId" @goToChooseElement="goToChooseElement" v-if="!firstStep" :isFromLibraryPage="isFromLibraryPage"/>
    </v-navigation-drawer>
    <v-navigation-drawer
      v-model="configTriggerDrawer"
      right
      overlay-opacity="0.2"
      temporary
      stateless
      width="400"
      app
    >   
        <v-toolbar tile flat dark class="bg-gradient">
            <v-toolbar-title class="d-flex justify-space-between align-center" style="width:100%;">
                {{$t('configureTriggerLabel')}}
                <v-btn text class="square" @click="configTriggerDrawer = false">
                    <v-icon >$close</v-icon>
                </v-btn>
            </v-toolbar-title>
            
        </v-toolbar>
        <WorkflowConfigTrigger  class="pa-4" :workflow="workflow" :config="config" @recordTriggerConf="recordTriggerConf"/>
    </v-navigation-drawer>
    <v-dialog
      v-model="dialogBlocs"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 primary white--text">
          {{$t('moveBlocTo')}}
        </v-card-title>

        <v-card-text color="lightgrey input-form">
            <v-select flat solo filled dense v-model="blocToMoveTo" :items="availableBlocs" item-text="text" :label="$t('selectBloc')" item-value="value" class="mt-4"></v-select>
            <div class="d-flex" style="justify-content:flex-end;">
                <v-btn
                    color="primary"
                    text
                    @click="dialogBlocs = false"
                >
                {{$t('cancel')}}
                </v-btn>
                <v-btn
                    color="primary"
                    depressed
                    @click="confirmMove"
                >
                {{$t('moveBloc')}}
            </v-btn>
          </div>  
        </v-card-text>  
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogExitTriggers" width="500">
        <v-card>
            <v-card-title class="text-h5 primary white--text">
                {{$t('addExitTrigger')}}
            </v-card-title>
            <v-card-text color="lightgrey input-form">
                <v-autocomplete flat solo filled dense v-model="triggerSelectedList" return-object :items="triggerList" item-text="text" :label="$t('selectExitTriggers')" item-value="value" class="mt-4 input-form">
                    <template v-slot:selection="data">
                        {{$t(data.item.text)}}
                    </template>
                    <template v-slot:item="{item, attrs, on}">
                        <v-list-item v-on="on" v-bind="attrs">
                            <v-list-item-content>
                                <v-list-item-title>
                                    <v-row no-gutters align="center">
                                        {{ $t(item.text) }}
                                    </v-row>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-autocomplete>
                <div class="d-flex" style="justify-content:flex-end;">
                    <v-btn
                        color="primary"
                        text
                        @click="cancelTriggerListDialog"
                    >
                    {{$t('cancel')}}
                    </v-btn>
                    <v-btn
                        color="primary"
                        depressed
                        @click="confirmAddExitTriggers"
                    >
                    {{$t('addExitTriggers')}}
                    </v-btn>
                </div> 
            </v-card-text>  
        </v-card>
    </v-dialog>
</div>
</template>
<script>
import WorkflowAddElement from '@/modules/workflow/components/WorkflowAddElement'
import WorkflowConfigElement from '@/modules/workflow/components/WorkflowConfigElement'
import WorkflowItemComponent from '@/modules/workflow/components/WorkflowItemComponent'
import WorkflowConfigTrigger from '@/modules/workflow/components/WorkflowConfigTrigger'
import GenericDataService from '@/services/GenericDataService'
export default {
    name: "WorkFlowComponent",
    props: ['config', 'workflow', 'operationId', 'stats', 'lib', 'preview', 'isFromLibraryPage'],
    components: {WorkflowItemComponent, WorkflowAddElement, WorkflowConfigElement, WorkflowConfigTrigger},
    data() {
        return {
            publicPath: process.env.BASE_URL,
            firstStep: true,
            elementToAdd : {},
            items: ['Condition', 'Action'],
            drawer: false,
            configTriggerDrawer: false,
            idWhereAdd:0,
            inheritChildren: false,
            scaleAmount:1,
            scaleStep: 0.1,
            maxId:1,
            componentKey: 0,
            flatConf: {},
            edit:false,
            availableBranches:[],
            availableBlocs:[],
            dialogBlocs:false,
            blocToMoveTo: null,
            elementToMove: null,
            triggerList: [],
            triggersOpen: [],
            triggerSelectedList: {},
            dialogExitTriggers : false,
            additionnalContexts: [],
            StrokesId : 'Strokes'+Date.now(),
            uniqueId: Date.now()
        }
    },
    created() {
        setTimeout(() => {
            this.$refs.strokeContainer.style.width = this.$refs.containerWorkflow.clientWidth + "px"
            document.getElementById(this.StrokesId).innerHTML = '';
            this.calculateStrokes(this.config)
        }, 400);
        this.getMaxId(this.config)
        if(this.maxId == 1 || (this.config.config && this.config.config.hasOwnProperty('is_config') && this.config.config.is_config == false)){
            if(this.config.config && this.config.config.hasOwnProperty('is_config') && this.config.config.is_config == false){
                this.configTriggerDrawer = true
            }
        }
    },
    mounted() {
        setTimeout(() => {
            this.$refs.strokeContainer.style.width = this.$refs.containerWorkflow.clientWidth + "px"
            document.getElementById(this.StrokesId).innerHTML = '';
            this.calculateStrokes(this.config)
        }, 700);
    },
    watch: {
        dialogBlocs(val){
            if(!val){
                this.blocToMoveTo = null
                this.elementToMove = null
            }
        },
        workflow(val){
            if(val){
                setTimeout(() => {
                    this.$refs.strokeContainer.style.width = this.$refs.containerWorkflow.clientWidth + "px"
                    document.getElementById(this.StrokesId).innerHTML = '';
                    this.calculateStrokes(this.config)
                }, 400);
            }
               
        }
    },
    methods: {
        activateWF(){
            this.workflow.active = 1;
            this.$emit('saveWorkflows')
        },
        removeExitTrigger(index){
            this.workflow.exit_triggers.splice(index, 1)
            this.$emit('saveWorkflows')
        },
        confirmAddExitTriggers(){
            let newExitTrigger = {}
            newExitTrigger.text = this.triggerSelectedList.text
            newExitTrigger.event = this.triggerSelectedList.text
            newExitTrigger.config =  this.triggerSelectedList.params
            this.workflow.exit_triggers.push(newExitTrigger)
            if(newExitTrigger.config){
                this.triggersOpen.push(this.workflow.exit_triggers.length - 1)
            }
            this.triggerSelectedList = {}
            this.$emit('saveWorkflows')
            this.dialogExitTriggers = false
        },
        cancelTriggerListDialog(){
            this.triggerSelectedList = {}
            this.dialogExitTriggers = false
        },
        displayExitTriggersDialog(){
            if(this.triggerList.length == 0){
                GenericDataService.getData('/workflow/getTriggerList').then((response) => {
                    this.triggerList = response.data.data
                    this.triggerList.forEach((event) => {
                        if(event.header) event.header = this.$t(event.header);
                    });
                    this.dialogExitTriggers = true
                })
            } else {
                this.dialogExitTriggers = true
            }
            
        },
        recordTriggerConf(){
            this.configTriggerDrawer = false
            if(this.workflow.config.event == "contact_enter_alert" && this.workflow.config.config.alert_type && this.workflow.config.config.alert_type.value){
                this.additionnalContexts = this.workflow.config.config.alert_type.value.value
            }
            this.$emit('saveWorkflows')
        },
        getMaxId(element){
            if(element.id >= this.maxId){
                this.maxId = element.id
            }
            if(element.children && element.children.length > 0){
                element.children.forEach(element => {
                    this.getMaxId(element)
                });
            }
        },
        goToChooseElement(){
            this.firstStep = true
        },
        getElements(conf, parents = []){
            parents = this._.cloneDeep(parents)
            this.flatConf[conf.id] = parents
            parents.push(conf.id);
            if(conf.children && conf.children.length > 0){
                conf.children.forEach(elementChild => {
                    this.getElements(elementChild, this._.cloneDeep(parents))
                });
            }
        },
        moveItem(element) {
            this.getBlocsSelectable(this.config, [element.id])
            this.elementToMove = this._.cloneDeep(element)
            this.dialogBlocs = true
        },
        confirmMove(){
            if(this.blocToMoveTo){
                this.idWhereAdd = this.blocToMoveTo
                this.deleteIdWithChildren(this.config, this.elementToMove.id, true)
                setTimeout(() => {
                    this.addToObject(this.config, this.elementToMove)
                    setTimeout(() => {
                        this.dialogBlocs = false
                        this.blocToMoveTo = null
                        this.elementToMove = null
                    }, 100);
                }, 200);
                
                
            }
        },
        getBlocsSelectable(conf, excluded_ids){
            if(!excluded_ids.includes(conf.id) && conf.type != "condition_ifelse"){
                let object = {}
                object.value = conf.id
                if(conf.type == "condition_ifelse_child"){
                    object.text = conf.id +" - "+ (conf.branchName)
                } else if(conf.name && conf.name != conf.type){
                    object.text = conf.id +" - "+ (conf.name)
                } else {
                    object.text = conf.id +" - "+ this.$t(conf.type)
                }
                
                this.availableBlocs.push(object)
            }
            if(!excluded_ids.includes(conf.id) && conf.children && conf.children.length > 0){
                conf.children.forEach(elementChild => {
                    this.getBlocsSelectable(elementChild, excluded_ids)
                });
            }
        },
        getBranchesSelectable(conf, ids){
            if(!ids.includes(conf.id) && conf.type != "condition_ifelse_child" && conf.type != "condition_if_child"){
                let object = {}
                object.value = conf.id
                if(conf.name && conf.name != conf.type){
                    object.text = conf.id +" - "+ (conf.name)
                } else {
                    object.text = conf.id +" - "+ this.$t(conf.type)
                }
                
                this.availableBranches.push(object)
            }
            if(conf.children && conf.children.length > 0){
                conf.children.forEach(elementChild => {
                    this.getBranchesSelectable(elementChild, ids)
                });
            }
        },
        addElement(action){
            this.firstStep = false
            let configElement = this._.cloneDeep(action.configElement)
            //récupération des branches possibles pour l'action "Aller a une branche"
            if(action.label == "go_to_step"){
                this.getElements(this.config)
                let idsToExclude = this.flatConf[this.idWhereAdd]
                this.getBranchesSelectable(this.config, idsToExclude)
                configElement.branchAvailable = this.availableBranches
            }
            this.elementToAdd = configElement
        },
        changeDrawer($event){
            if(!$event){
                this.closeDrawer()
            }
        },
        editItem($event){
            this.edit = true
            this.elementToAdd = $event
            if($event.type == 'trigger'){
                this.configTriggerDrawer = true
            } else {
                this.firstStep = false,
                this.drawer = true
            }
        },
        updateItem($event){
            if($event.type.includes('external_communication_')  && (this.$route.params.operationtype_id || this.$route.params.operation_id)){
                let payload = {}
                if(this.$route.params.operationtype_id){
                    payload.operationtype_id = this.$route.params.operationtype_id
                }
                if(this.$route.params.operation_id){
                    payload.operation_id = this.$route.params.operation_id
                }
                
                payload.config = $event
                console.log($event);
                GenericDataService.postData('/workflow/'+this.workflow.id+'/setRetroplanning', payload).then((response) => {
                    if(response.data.data.retroplanning_id){
                        $event.retroplanning_id = response.data.data.retroplanning_id
                        this.updateObject(this.config, $event)   
                    }
                })
            } else {
                this.updateObject(this.config, $event)   
            }   
            
        },
        updateObject(object, node){
            if(object.id == node.id){
                object.config = node.config
                object.name = node.name
                if(node.retroplanning_id)
                    object.retroplanning_id = node.retroplanning_id
                if(node.type == "condition_ifelse"){
                    object.children.forEach((child, index) => {
                        if(!node.children[index]){
                            object.children.splice(index, 1)
                        } else {
                            child.branchName = node.children[index].branchName
                            child.rule = node.children[index].rule
                            child.type = node.children[index].type
                            child.values = node.children[index].values
                        }
                        
                    })
                }
                this.$emit('saveWorkflows')
                setTimeout(() => {
                    this.$refs.strokeContainer.style.width = this.$refs.containerWorkflow.clientWidth + "px"
                    document.getElementById(this.StrokesId).innerHTML = '';
                    this.calculateStrokes(this.config)
                }, 50);
                this.closeDrawer()
            } else {
                if(object.children && object.children.length > 0){
                    object.children.forEach(child => {
                        this.updateObject(child, node)
                    });
                }
            }
        },
        closeDrawer(){
            this.firstStep = true;
            this.elementToAdd = {};
            this.inheritChildren = false,
            this.drawer = false;
        },
        deleteNodeWithChild($event){
            this.deleteIdWithChildren(this.config, $event)
        },
        deleteNodeWithoutChild($event){
            this.deleteIdWithoutChildren(this.config, $event)
        },
        deleteIdWithoutChildren(object, id){
            if(object.children && object.children.length > 0){
                let index = object.children.findIndex((e) => e.id == id)
                if(index != -1){
                    if(object.children[index].children && object.children[index].children.length > 0){
                        let childToAdd = this._.cloneDeep(object.children[index].children)
                        object.children.splice(index, 1)
                        childToAdd.forEach(child => {
                            object.children.push(child)
                        });
                    } else {
                        object.children.splice(index, 1)
                    }
                    this.$emit('saveWorkflows')
                    setTimeout(() => {
                        this.$refs.strokeContainer.style.width = this.$refs.containerWorkflow.clientWidth + "px"
                        document.getElementById(this.StrokesId).innerHTML = '';
                        this.calculateStrokes(this.config)
                    }, 50);
                } else {
                    object.children.forEach(child => {
                        this.deleteIdWithoutChildren(child, id)
                    });
                }
            }
        },
        deleteIdWithChildren(object, id, isMoving = false){
            if(object.children && object.children.length > 0){
                let index = object.children.findIndex((e) => e.id == id)
                if(index != -1){
                    let cloneObject = this._.cloneDeep(object.children[index])

                    if(!isMoving)
                        this.checkIfExternalCom(cloneObject);

                    object.children.splice(index, 1)
                    this.$emit('saveWorkflows')
                    setTimeout(() => {
                        this.$refs.strokeContainer.style.width = this.$refs.containerWorkflow.clientWidth + "px"
                        document.getElementById(this.StrokesId).innerHTML = '';
                        this.calculateStrokes(this.config)
                    }, 50);
                } else {
                    object.children.forEach(child => {
                        this.deleteIdWithChildren(child, id, isMoving)
                    });
                }
            }
            // }
        },
        checkIfExternalCom(object){
            if(this.$route.params.operationtype_id || this.$route.params.operation_id){
                if(object.type.includes("external_communication")){
                    let payload = {}
                    payload.node_id = object.retroplanning_nodeid
                    if(this.$route.params.operationtype_id){
                        payload.operationtype_id = this.$route.params.operationtype_id
                        
                    }
                    if(this.$route.params.operation_id){
                        payload.operation_id = this.$route.params.operation_id
                        payload.retroplanning_id = object.retroplanning_id
                    }
                    GenericDataService.postData('/workflow/'+this.workflow.id+'/deleteRetroplanning', payload)
                }
                if(object.children && object.children.length > 0){
                    object.children.forEach(child => {
                        this.checkIfExternalCom(child)
                    });
                    
                }
            }
            
        },
        addChild($event){
            this.maxId +=  1
            let childToAdd = this._.cloneDeep($event)
            childToAdd.id = this.maxId
            if(childToAdd.type == "condition_if" || childToAdd.type == "condition_ifelse"){
                childToAdd.children.forEach((child) => {
                    this.maxId +=  1
                    child.id = this.maxId
                })
            }
            //TODO : Ajouter rp dans les items
            if(childToAdd.type.includes('external_communication_') && (this.$route.params.operation_id)){
                let payload = {}
                if(this.$route.params.operationtype_id){
                    payload.operationtype_id = this.$route.params.operationtype_id
                }
                if(this.$route.params.operation_id){
                    payload.operation_id = this.$route.params.operation_id
                }
                payload.config = childToAdd
                GenericDataService.postData('/workflow/'+this.workflow.id+'/setRetroplanning', payload).then((response) => {
                    if(response.data.data.retroplanning_id){
                        childToAdd.retroplanning_nodeid = response.data.data.retroplanning_nodeid
                        childToAdd.retroplanning_id = response.data.data.retroplanning_id
                    } else {
                        //type opé
                        childToAdd.retroplanning_nodeid = response.data.data
                    }
                    
                    this.addToObject(this.config, childToAdd)
                    this.elementToAdd = {};
                    this.inheritChildren = false,
                    this.drawer = false;
                })
            } else {
                this.addToObject(this.config, childToAdd)
                this.elementToAdd = {};
                this.inheritChildren = false,
                this.drawer = false;
            }
            
        },
        addToObject(object, childToAdd){
            if(object.id == this.idWhereAdd){
                if(!object.children){
                    object.children = []
                }
                if(this.inheritChildren){
                    let childrenToInherit = this._.cloneDeep(object.children)
                    object.children = []
                    childToAdd.children = childrenToInherit
                } 
                object.children.push(childToAdd) 
                
                this.componentKey += 1
                this.$emit('saveWorkflows')
                //this.$refs.containerDivRef.$forceUpdate();
                setTimeout(() => {
                    this.$refs.strokeContainer.style.width = this.$refs.containerWorkflow.clientWidth + "px"
                    document.getElementById(this.StrokesId).innerHTML = '';
                    this.calculateStrokes(this.config)
                }, 100);
                
                return true
            } else {
                if(object.children && object.children.length > 0){
                    object.children.forEach(child => {
                        this.addToObject(child, childToAdd)
                    });
                }
            }
        },
        openDrawerToAdd($event){
            this.edit = false
            this.drawer = true
            this.idWhereAdd = $event
        },
        onWheel($event){
            if($event.deltaY > 0){
                document.getElementById('btnMoins').click()
            } else {
                document.getElementById('btnPlus').click()
            }
        },
        zoomReset(){
            this.scaleAmount = 1;
            this.$refs.containerWorkflow.style.transform = 'scale('+this.scaleAmount+')';
            this.$refs.containerDivRef.style.overflow = "unset"
            setTimeout(() => {
                this.$refs.containerDivRef.style.overflow = "scroll"
            }, 10);
            //this.$refs.containerDivRef.style.overflow = "scroll"
        },
        zoomIn(){
            this.scaleAmount += this.scaleStep
            this.$refs.containerWorkflow.style.transform = 'scale('+this.scaleAmount+')';
            this.$refs.containerDivRef.style.overflow = "unset"
             setTimeout(() => {
                this.$refs.containerDivRef.style.overflow = "scroll"
            }, 10);
            this.$refs.containerDivRef.style['height'] = "100%";
            //this.$refs.containerDivRef.style.overflow = "scroll"
        },
        zoomOut(){
            this.scaleAmount -= this.scaleStep
            this.$refs.containerWorkflow.style.transform = 'scale('+this.scaleAmount+')';
            this.$refs.containerDivRef.style.overflow = "unset"
             setTimeout(() => {
                this.$refs.containerDivRef.style.overflow = "scroll"
            }, 10);
            //this.$refs.containerDivRef.style.overflow = "scroll"
        },
        calculateStrokes(parent){
            let parentElm = document.getElementById('item_'+parent.id+'_'+this.uniqueId)
            let parentLeftOrigin = parentElm.offsetLeft + (parentElm.clientWidth / 2) 
            let parentTopOrigin = parentElm.clientHeight + parentElm.offsetTop + 2
            let heightBetween = 50;
            if(parent.children && parent.children.length > 0) {
                parent.children.forEach((child) => {
                    let childItem = document.getElementById('item_'+child.id+'_'+this.uniqueId)
                        let childLeftDest = childItem.offsetLeft + (childItem.clientWidth / 2)
                        let childStroke = document.createElementNS('http://www.w3.org/2000/svg', 'path')
                        if(parentLeftOrigin == childLeftDest){
                            childStroke.setAttribute('d', 'M '+parentLeftOrigin+", "+(parentTopOrigin)+" "+childLeftDest+", "+(heightBetween + parentTopOrigin))
                        } else {
                            childStroke.setAttribute('d', 'M '+parentLeftOrigin+", "+(parentTopOrigin)+" "+parentLeftOrigin+", "+(heightBetween/2 + parentTopOrigin)+" "+childLeftDest+", "+(heightBetween/2 + parentTopOrigin)+" "+childLeftDest+", "+(heightBetween + parentTopOrigin))
                        }
                        childStroke.setAttribute('vector-effect', 'non-scaling-stroke')
                        childStroke.setAttribute('class', 'st0')
                        document.getElementById(this.StrokesId).appendChild(childStroke)
                        if(child.children && child.children.length > 0){
                            this.calculateStrokes(child) 
                        } else {
                            let childTopOrigin = childItem.clientHeight + childItem.offsetTop + 2
                            let FinalItem = document.getElementById('item_'+child.id+'_final_'+this.uniqueId)
                            let FInalLeftDest = FinalItem.offsetLeft + (FinalItem.clientWidth / 2)
                            let FinalStroke = document.createElementNS('http://www.w3.org/2000/svg', 'path')
                            if(childLeftDest == FInalLeftDest){
                                FinalStroke.setAttribute('d', 'M '+childLeftDest+", "+(childTopOrigin)+" "+FInalLeftDest+", "+(heightBetween + childTopOrigin))
                            } else {
                                FinalStroke.setAttribute('d', 'M '+childLeftDest+", "+(childTopOrigin)+" "+childLeftDest+", "+(heightBetween/2 + childTopOrigin)+" "+FInalLeftDest+", "+(heightBetween/2 + childTopOrigin)+" "+FInalLeftDest+", "+(heightBetween + parentTopOrigin))
                            }
                            FinalStroke.setAttribute('vector-effect', 'non-scaling-stroke')
                            FinalStroke.setAttribute('class', 'st0')
                            document.getElementById(this.StrokesId).appendChild(FinalStroke)
                        }
                    
                })
            } else {
                let FinalItem = document.getElementById('item_'+parent.id+'_final_'+this.uniqueId)
                let FInalLeftDest = FinalItem.offsetLeft + (FinalItem.clientWidth / 2)
                let FinalStroke = document.createElementNS('http://www.w3.org/2000/svg', 'path')
                if(parentLeftOrigin == FInalLeftDest){
                    FinalStroke.setAttribute('d', 'M '+parentLeftOrigin+", "+(parentTopOrigin)+" "+FInalLeftDest+", "+(heightBetween + parentTopOrigin))
                } else {
                    FinalStroke.setAttribute('d', 'M '+parentLeftOrigin+", "+(parentTopOrigin)+" "+parentLeftOrigin+", "+(heightBetween/2 + parentTopOrigin)+" "+FInalLeftDest+", "+(heightBetween/2 + parentTopOrigin)+" "+FInalLeftDest+", "+(heightBetween + parentTopOrigin))
                }
                FinalStroke.setAttribute('vector-effect', 'non-scaling-stroke')
                FinalStroke.setAttribute('class', 'st0')
                document.getElementById(this.StrokesId).appendChild(FinalStroke)
            }
            
        }
    },
}
</script>
<style lang="scss">
.workflowComponent{
    .v-input__slot {
        background: var(--v-lightgrey-base) !important;
        border-radius:5px;
    }
    .v-input--checkbox, .v-input__slider, .v-input--radio-group {
        .v-input__slot {
            background: #FFF !important;
            border-radius:5px;
        }
        .v-input__prepend-outer, .v-input__append-outer {
            .v-input__slot {
                background: var(--v-lightgrey-base) !important;
                border-radius:5px;
            }
        }
    }
    .input-form {
        .v-select__slot {
            background: var(--v-lightgrey-base) !important;
        }
        .label {
            color: #000;
            font-size: 15px;
            display:inline-block;
            margin-bottom:5px;
        }
    } 
    .st0{
        fill:transparent;
        stroke:#DDD;
        stroke-width:2;
        stroke-miterlimit:10;
    }
    .StrokesClass {
        //background-color: grey;
        position: absolute;
        top:0; 
        left:0;
        width:auto;
        height: 100%;
        z-index:0;
    }
    .wheelWorkflow {
        width:100%;
        height: 100%;
        position:absolute;
        top:0;
        left:0;
        z-index:2;
    }
    .icons {
        position:absolute;
        top:10px;
        left:10px;
        z-index:4;
    }
    // .btnMoins {
    //     position:absolute;
    //     top:45px;
    //     left:10px;
    //     z-index:4;
    // }
    // .btnPlus {
    //     position:absolute;
    //     top:10px;
    //     left:10px;
    //     z-index:4;
    // }
    .WorkflowContent {
        width:100%;
        height:100%;
        .exitTriggers {
            min-height:100%;
            width:400px;
            border-left:1px solid #AAA;
            .triggerPanel {
                border-radius:5px;
                .icon {
                    transition: transform 0.2s ease-in;
                    &.rotate{
                        transform:rotate(180deg);
                    }
                }
            }
            .exitTriggerDiv{
                position:relative;
                overflow-y:scroll;
                height:100%;
                .exitTriggerContainer {
                    left:0;
                    position:absolute;
                    width:100%;
                    transform-origin: 0 0;

                }
            }
            .v-expansion-panel--active > .v-expansion-panel-header {
                min-height: 48px !important;
            }
        }
    }

    .containerDiv {
        position:relative;
        display:inline-flex;
        flex-direction: column;
        cursor:grab;
        //justify-content: center;
        align-items: center;
        height: calc(100% + 0px);
        overflow:scroll;
        &:active {
            cursor: grabbing;
            cursor: -moz-grabbing;
            cursor: -webkit-grabbing;
        }
    }
    .declencheur{
        display:flex;
        flex-direction:column;
        align-items: center;
        //position:relative;
       // height:40px;
        padding-left:10px;
        padding-right:10px;
        width:100%;
        z-index:3;
        // .item{
        //      width:200px;
        //      background-color:#AAA;
        // }
    }
    // .children{
    //     //background-color:#DDD;
    //     display:flex;
    //     flex-shrink:0;
    //     margin-top:100px;
    //     z-index:2;
    //     div {
    //         background-color:#AAA;
    //         //margin: 0 25px;
    //         width:200px;
    //         height:40px;
    //     }
    // }
    }
</style>