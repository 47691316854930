<template>
  <div class="formStepper" v-if="formSteps">
    <v-stepper alt-labels non-linear :value="currentStep" @change="updateCurrentStep">
      <v-stepper-header>
        <template v-for="(step, key) in formSteps.schema.steps">
          <v-stepper-step :key="key" edit-icon="$check_l" :editable="formSteps.schema.steps[0].is_validate ? true : false" :complete="step.is_validate" :step="key + 1">
            {{ $t(step.name) }}
          </v-stepper-step>
          <v-divider v-if="key + 1 !== Object.keys(formSteps.schema.steps).length" :key="'divider_' + key"></v-divider>
        </template>
      </v-stepper-header>

      <v-divider></v-divider>

      <v-stepper-items>
        <template v-for="(step, key) in formSteps.schema.steps">
          <v-stepper-content v-if="step.header" :key="'item_header_' + key" :step="key + 1">
            <v-col cols="12">
              <div class="text-center py-3">
                <span v-if="step.header.text" v-html="$t(step.header.text)"></span><br>
                <span v-if="step.header.button">
                  <v-btn dense depressed color="primary" class="mt-4">
                    <v-icon left dark small v-if="step.header.button.icon"> {{step.header.button.icon}} </v-icon>
                    {{$t(step.header.button.title)}}
                  </v-btn>
                </span>
              </div>
            </v-col>
          </v-stepper-content>
          <v-stepper-content :key="'item_' + key" :step="key + 1" :style="step.background_color ? ('background-color: ' + step.background_color) : ''">
            <FormComponent v-if="form" :form="form" :action="action" :step="formSteps.schema.steps.length === key + 1 ? false : key + 1"  v-on="$listeners" />
          </v-stepper-content>
        </template>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import FormComponent from "@/components/forms/FormComponent";
import getForm from "@/mixins/mixins";

export default {
  name: "StepperComponent",
  props: ["formSteps", "action"],
  mixins: [getForm],
  components: {
    FormComponent,
  },
  data() {
    return {
      form: null,
    };
  },
  mounted() {
    this.loadForm()
  },
  computed: {
    currentStep() {
      return this.formSteps.schema.current_step
    },
  },
  watch: {
    currentStep(newValue) {
      this.loadForm();
    }
  },
  methods: {
    updateCurrentStep(index) {
      this.formSteps.schema.current_step = index;
    },
    loadForm() {
      this.form = null;
      var step = this.formSteps.schema.steps[this.formSteps.schema.current_step - 1];
      var url = step.url.replace('{{id}}', this.formSteps.model.id ? this.formSteps.model.id : '');
      
      this.getForm(url, false, false, null, null, false);
    }
  },
};
</script>

<style lang="scss">
  .formStepper {
    margin-left: -24px;
    margin-right: -24px;
    .v-stepper {
      position: initial;
      box-shadow: none;
      .v-stepper__header {
        width: 70%;
        padding: 12px 0px;
        margin-left: auto;
        margin-right: auto;
        box-shadow: none;
        .v-stepper__step {
          flex-basis: 215px;
        }
      }
      .v-stepper__items {
        position: initial;
        .v-stepper__content {
          padding: 12px 24px;
        }
      }
    } 
  }
</style>
