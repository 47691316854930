<script>
import { Bar, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  extends: Bar,
  props: ['dataSet'],
  data() {
      return {
          options: {
            responsive:true,
            maintainAspectRatio: false, 
            onClick:this.handle,
            layout: {
                padding: {
                    left: 0,
                    right: 0, 
                    top: 0,
                    bottom: 0
                }
            },
            legend: {
                display: true,
                position: 'top',
            },
            tooltip: {
                enabled: false
            },
            animation: {
                duration:0,
            },
            title:{
                display: false,
                text: "test"
            },
            scales:{
                yAxes: [{
                    beginAtZero: true,
                    stacked: true
                }],
                xAxes: [{
                    beginAtZero: true,
                    stacked: true
                }],
            },
          }
      }
  },
  watch: {
    //   chartData : {
    //       deep:true,
    //       handler(val){
    //         //this.$data._chart.destroy()
    //         //this._data._chart.update()
    //         this.renderChart(this.chartData, this.options) 
    //       }
    //   }
  },
  computed: {
      chartData: function() {
          const data = this.dataSet;
          if(this.dataSet.options){
            this.options = this.dataSet.options
          }
          data.datasets.forEach(dataset => {
            if(data.totrad && !data.alreadyTrad){
                dataset.label = this.$t(dataset.label);
            }
                
              let newColors = []
              if(typeof(dataset.backgroundColor) == 'array'){
                dataset.backgroundColor.forEach(color => {
                    if(this.$vuetify.theme.themes.light[color]){
                        const newColor = this.$vuetify.theme.themes.light[color]
                        newColors.push(newColor)
                    } else {
                        newColors.push(color)
                    }
                    
                })
                 dataset.backgroundColor = newColors 
              } 
              
          });

          data.alreadyTrad = true;
          return data
      }
  },
  methods: {
    handle (point, event) {
    	const item = event[0]
        if(item && item._chart){
            this.$emit('selectItem', item._index);
        }
    }
  },
  mounted () {
      this.options.title.text = this.chartData.label
      this.renderChart(this.chartData, this.options)              
  },
  
}
</script>

<style lang="scss">
    
</style>