<template>
    <div class="pb-4 todosAdd">
        <div class="d-flex align-center">
            <h5 class="mb-0 pb-0 mr-4">{{ $t('todos') }}</h5>
            <v-btn @click="getForm(createUrl)" depressed class="bg-gradient" v-if="$func.hasRight('operationtype/update')">
                <v-icon dark small>$plus_l</v-icon>
                {{ $t('add') }}
            </v-btn>
        </div>

        <v-row class="mt-2">
            <v-col cols="4" v-for="(todo, index) in this.model[this.fieldName]" :key="index">
                <v-card class="cardRP withRP">
                    <v-card-title>
                        <div>
                            <h5>{{todo.config.title}}</h5>
                        </div>
                        <v-spacer></v-spacer>
                        <v-btn text class="square" small v-if="$func.hasRight('operationtype/update')" @click="getForm(editUrl + todo.id)">
                            <v-icon small>$pencil_d</v-icon>
                        </v-btn>
                        <v-btn v-if="$func.hasRight('operationtype/update')" text class="square" small @click="openConfirm(todo.id)">
                            <v-icon small>$trash_d</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <div class="configretro black--text">
                            <p class="mb-1">
                                {{$t('start')}} :
                                <span v-if="todo.config.compute_date_start.placement != 'equals'">
                                    <span>{{ todo.config.compute_date_start.days }} {{ $t('day_s') }} </span>
                                    {{ $t(todo.config.compute_date_start.placement).toLowerCase() }} {{ $t((model['type'] === 'MARKETING' ? 'ref_step_' : 'operation_') + todo.config.compute_date_start.reference).toLowerCase() }} 
                                    <span>{{ $t('at_time').toLowerCase() }} {{ todo.config.compute_date_start.time }}</span>
                                </span>
                                <span v-else>
                                    {{ $t(todo.config.compute_date_start.placement) }} {{ $t((model['type'] === 'MARKETING' ? 'ref_step_' : 'operation_') + todo.config.compute_date_start.reference).toLowerCase() }} 
                                </span>
                            </p>
                            <p class="mb-1">
                                {{$t('end')}} :
                                <span v-if="todo.config.compute_date_end.placement != 'equals'">
                                    <span>{{ todo.config.compute_date_end.days }} {{ $t('day_s') }} </span>
                                    {{ $t(todo.config.compute_date_end.placement).toLowerCase() }} {{ $t((model['type'] === 'MARKETING' ? 'ref_step_' : 'operation_') + todo.config.compute_date_end.reference).toLowerCase() }} 
                                    <span>{{ $t('at_time').toLowerCase() }} {{ todo.config.compute_date_end.time }}</span>
                                </span>
                                <span v-else>
                                    {{ $t(todo.config.compute_date_end.placement) }} {{ $t((model['type'] === 'MARKETING' ? 'ref_step_' : 'operation_') + todo.config.compute_date_end.reference).toLowerCase() }} 
                                </span>
                            </p>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <FormDialogComponent v-if="form" ref="componentForm" :form="form" :action="'SET_DATA'"  :isFromLibraryPage="isFromLibraryPage"/>

        <v-dialog width="600" v-model="dialogConfirm">
            <v-card>
                <v-toolbar flat dark color="primary" class="toolbar bg-gradient" :style="'height:63px;'">
                    <v-toolbar-title>{{$t('confirmDeleteLabel')}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn depressed outlined color="white" class="mr-3" @click="closeDialog">
                        {{$t('cancelLabel')}}
                    </v-btn>
                    <v-btn depressed outlined color="white" @click="deleteItem">
                        {{$t('confirmLabel')}}
                    </v-btn>
                </v-toolbar>
                <v-card-text class="pt-3">
                    {{$t('confirmDeleteRetroplanningStepText')}}
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import getForm from "@/mixins/mixins";
import GenericDataService from '@/services/GenericDataService'

export default {
    mixins: [getForm],
    components: {
        FormDialogComponent: () => import("@/components/dialogs/FormDialogComponent"), 
    },
    props:['model', 'fieldName', 'field', 'isFromLibraryPage'],
    data() {
        return {
            form: null,
            formKey: 0,
            createUrl: '/operationtype/' + this.model.id + '/getFormTodos',
            editUrl: '/operationtype/' + this.model.id+ '/getFormTodos?id=',
            dialogConfirm: false,
            idToDelete: 0,
        }
    },
    created() {
    },
    methods: {
        closeDialog(){
            this.idToDelete = 0;
            this.dialogConfirm = false;
        },
        deleteItem(){
            GenericDataService.getData('/operationtype/' + this.model.id + '/deleteTodo?id=' + this.idToDelete).then((response) => {
                this.idToDelete = 0;
                this.dialogConfirm = false;
                this.model[this.fieldName] = response.data.data;
            })
        },
        customRefresh(response){
            this.model[this.fieldName] = response.data.data;
        },
        openConfirm(itemId){
            this.idToDelete = itemId;
            this.dialogConfirm = true;
        }
    }
}
</script>

<style lang="scss">
    .todosAdd {
        width:100%;
    }
</style>