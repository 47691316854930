<template>
    <div class="col col-12 pa-0 input-form pb-4">
        <span v-if="field.title" class="label">
            <b>{{ $t(field.title) }}</b>
            <span v-if="field.props && field.props.required" style="color:red">&nbsp;*</span>
            <infoTooltip v-if="field.tooltip" :tooltip="field.tooltip" />
        </span>

        <span class="ml-4">
            <v-icon
                dense
                v-for="(number, index) in field.props.count ? field.props.count : 5"
                :key="index"
                @click="setValue(index)"
                :color="index < value || hoverValue > index ? (field.props.selectedColor ? field.props.selectedColor : 'success') : ''"
                :style = ' hoverValue - value > 0 && value < index +1 && hoverValue > index ? "opacity:0.5" : ""'
                @mouseover="hoverValue = index + 1"
                @mouseout="hoverValue = 0"
                class="mr-1 iconUser">
                {{ (value > index || hoverValue > index) ? (field.props.selectedIcon ? field.props.selectedIcon : '$user_s') : (field.props.notSelectedIcon ? field.props.notSelectedIcon : '$user_l') }}
            </v-icon>
        </span>
    </div>
</template>

<script>
import infoTooltip from '@/components/infoTooltip';

export default {
    props: ["model", "fieldName", "field"],
    components: { infoTooltip },
    data() {
        return {
            value: 0,
            hoverValue:0
        };
    },
    mounted() {
        this.value = this.model[this.fieldName];
    },
    watch: {
        value (newVal) {
            this.model[this.fieldName] = newVal;
        },
    },
    methods: {
        setValue(index) {
            if(this.value == index + 1) {
                this.value = 0;
            } else {
                this.value = index + 1;
            }
        }
    }
};
</script>

<style lang="scss">
</style>