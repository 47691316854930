<template>
    <v-row style="height:100%; overflow: hidden;">
        <v-col v-if="$func.hasRight('library/filter') || libType == 'COACH'" cols="3" style="height:100%; overflow-y: auto;">
            <v-card>
                <v-card-text class="pa-0">
                    <FilterFormComponent :form="formLib" @confirmAction="confirmAction" ref="formComponent" :action="'SET_DATA'" :saveAtInput="true" />
                </v-card-text>
            </v-card>
        </v-col>

        <v-col :cols="$func.hasRight('library/filter') || libType == 'COACH' ? '9' : '12'" v-scroll.self="scrollTest" style="height:100%; overflow-y: auto;"> 
            <input style="display:none;" type="file" ref="zipModelEmail" @change="handleUploadZipModelEmail" accept=".zip" :key="'upload_'+inputKey">   
            <v-row id="innerMedia">
                <v-col cols="12" v-if="libType == 'COACH' && models.data.length == 0">
                    <v-alert class="mb-0  mb-2" border="left" color="warning" text>
                        {{ $t("emptyLibraryResult") }}
                    </v-alert>
                </v-col>
                <v-col cols="12" v-if="!(!isFromPopup && $func.hasRight('library/add')) && !(isFromPopup && !isFromLibraryPage) && models.data && !models.data.length">
                    <v-alert class="mb-0  mb-2" border="left" color="warning" text>
                        {{ $t("emptyLibraryResult") }}
                    </v-alert>
                </v-col>

                <v-col cols="4" v-if="!isFromPopup && $func.hasRight('library/add') && libType != 'COACH'">
                    <v-card class="pa-0">
                        <v-card-text @mouseover.stop="hoverAdd = true" @mouseleave.stop="hoverAdd = false" class="pa-0">
                            <div v-if="hoverAdd" class="hoverThumb d-flex flex-column align-center justify-center">
                                <v-btn small color="pink" class="white--text" @click="$emit('addModel')">
                                    <v-icon small class="mr-2">$plus_r</v-icon>
                                    {{ $t('createYourOwnModel') }}
                                </v-btn>
                                <v-btn small color="pink" class="white--text mt-2" @click="$refs.zipModelEmail.click()" v-if="libType == 'EMAIL'">
                                    <v-icon small class="mr-2">$cloudup_d</v-icon>
                                    {{ $t('importModelByZip') }}
                                </v-btn>
                            </div>
                            <div class="thumbAdd">
                                <div>
                                    <v-img contain width="100" :src="`${publicPath}`+'img/plus.png'"></v-img>
                                </div>
                            </div>
                            <div class="thumbDesc pt-2 pl-1">
                                <div class="d-flex align-center">
                                    <div class="ml-2">
                                        <p class="mb-0"><b>{{ $t('emptyModel') }}</b></p>
                                        <p class="mb-0">{{ $t('createYourOwnModel') }}</p>
                                    </div>
                                </div>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="4" v-if="isFromPopup && !isFromLibraryPage && libType != 'COACH'">
                    <v-card class="pa-0">
                        <v-card-text @mouseover.stop="hoverCreateWithoutModel = true" @mouseleave.stop="hoverCreateWithoutModel = false" class="pa-0">
                            <div v-if="hoverCreateWithoutModel" class="hoverThumb d-flex flex-column align-center justify-center">
                                <v-btn small color="pink" class="white--text" @click="selectModel(null)">
                                    <v-icon small class="mr-2">$plus_r</v-icon>
                                    {{ $t('createWithoutModel') }}
                                </v-btn>
                                <v-btn v-if="libType == 'EMAIL'" small color="pink" class="white--text mt-2" @click="$refs.zipModelEmail.click()">
                                    <v-icon small class="mr-2">$cloudup_d</v-icon>
                                    {{ $t('importModelByZip') }}
                                </v-btn>
                                
                            </div>
                            <div class="thumbAdd">
                                <div>
                                    <v-img contain width="100" :src="`${publicPath}`+'img/plus.png'"></v-img>
                                </div>
                            </div>
                            <div class="thumbDesc pt-2 pl-1">
                                <div class="d-flex align-center">
                                    <div class="ml-2">
                                        <p class="mb-0"><b>{{ $t('virginModel') }}</b></p>
                                        <p class="mb-0">{{ $t('createWithoutModel') }}</p>
                                    </div>
                                </div>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col :cols="libType != 'COACH' ? 4 : 3" v-for="(model, key) in models.data" :key="'lib_'+key">
                    <LibraryComponentList :libType="libType" :model="model" :formModel="formLib.model" :heightCard="280" :isFromPopup="isFromPopup" :component="models.component" :key="key" 
                        @openPreview="openPreview" 
                        @selectModel="selectModel" 
                        @editModel="editModel" 
                        @resetChildsModel="resetChildsModel"
                        @duplicateModel="duplicateModel"
                        @deleteModel="deleteModel"
                        @sendTestModel="sendTestModel"
                        />
                </v-col>
                


                <v-col cols="12" id="loaderCol" class="d-flex justify-center" style="height:50px;">
                    <v-progress-circular
                    v-if="loading" 
                    :size="50"
                    :width="7"
                    color="primary"
                    indeterminate
                    ></v-progress-circular>
                </v-col>
            </v-row>
        </v-col>

        <LibraryPreviewComponent v-if="previewModel" :libType="libType" :model="previewModel" :module="models.module" :openPreviewDialog="openPreviewDialog" @close="openPreviewDialog = false" :showUseModelButton="(isFromPopup || (!isFromPopup && libType !== 'ENVELOPE') || libType == 'COACH') && (!previewModel.draft || previewModel.draft == '0')" @selectModel="selectModel"/>

        <FormDialogComponent v-if="form" ref="componentForm" :form="form" :action="'SET_DATA'" @exported="exported" v-on="$listeners" />

        <ActionMenusDialogComponent v-if="showActionMenusDialog" :items="itemsActionMenusDialog" ref="actionMenus" @actionForm="actionForm" @cancel="showActionMenusDialog = false;" />

        <OperationStepperDialog @closeModal="closeOperationStepper" @afterConfigureBy="closeOperationStepper" @StepperFinished="StepperFinished" :displayOperationStepper="displayOperationStepper" :operationId="operationId"/>

        <v-dialog width="500" v-model="openExportStep1FormDialog">
            <v-card>
                <v-toolbar tile flat dark class="bg-gradient">
                    <v-toolbar-title>{{$t('create_export')}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn outlined depressed small class="mr-2" @click="openExportStep1FormDialog = false">{{$t('cancelLabel')}}</v-btn>
                    <v-btn outlined depressed small @click="getForm(getExportFormUrl({ 'module_source': exportSourceValue, 'module_target': exportTargetValue })); openExportStep1FormDialog = false;">{{$t('confirmLabel')}}</v-btn>
                </v-toolbar>
                <v-card-text class="pt-6">
                    <v-select :items="exportSources()" :label="$t('export_source') + ' *'" filled dense v-model="exportSourceValue" :disabled="exportSourceDisabled">
                        <template v-slot:selection="{ item }">
                            {{$t('export_source_' + item)}}
                        </template>
                        <template v-slot:item="{ item }">
                            {{$t('export_source_' + item)}}
                        </template>
                    </v-select>
                    <v-select :items="exportTargets()" :label="$t('export_target') + ' *'" filled dense v-model="exportTargetValue">
                        <template v-slot:selection="{ item }">
                            {{$t('export_target_' + item)}}
                        </template>
                        <template v-slot:item="{ item }">
                            {{$t('export_target_' + item)}}
                        </template>
                    </v-select>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog width="500" v-model="openWorkflowStep1FormDialog">
            <v-card>
                <v-toolbar tile flat dark class="bg-gradient">
                    <v-toolbar-title>{{$t('create_workflow_model')}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn outlined depressed small class="mr-2" @click="openWorkflowStep1FormDialog = false">{{$t('cancelLabel')}}</v-btn>
                    <v-btn outlined depressed small @click="selectWorkflowTriggerConfirm">{{$t('confirmLabel')}}</v-btn>
                </v-toolbar>
                <v-card-text class="pt-6 input-form">
                    <v-text-field filled hide-details solo flat :label="$t('workflowName')" class="mb-3" dense v-model="workflowName"></v-text-field>
                    <v-select :items="workflowTriggers" :label="$t('workflow_model_trigger') + ' *'" filled dense v-model="workflowTriggerValue"></v-select>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog width="600" v-model="openLPStep1FormDialog">
            <v-card>
                <v-toolbar tile flat dark class="bg-gradient">
                    <v-toolbar-title>{{$t('create_landingpage_model')}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn outlined depressed small class="mr-2" @click="openLPStep1FormDialog = false">{{$t('cancelLabel')}}</v-btn>
                    <v-btn outlined depressed small @click="selectLayoutConfirm ">{{$t('confirmLabel')}}</v-btn>
                </v-toolbar>
                <v-card-text class="pt-6 ">
                    <div class="layoutSelector">
                        <v-card :ripple="false" v-for="(layout, index) in landingpageLayouts" @click="selectLayout(layout.id)" :key="'layout_' + index" class="mr-4 cardSelect" :class="(landingpageLayoutValue && landingpageLayoutValue == layout.id) ? 'active': ''">
                            <v-card-text>
                                <v-img fluid max-width="200" min-height="100" :src="layout.preview"></v-img>
                                <h4 style="border:0;" class="mt-2 pb-0">{{ layout.name }}</h4>
                                <ul class="mt-2 pl-0">
                                    <li v-for="(feature, index) in layout.features" :key="feature.id" style="list-style:none">
                                        <v-icon v-if="feature" small color="success" class="ml-2">$check_l</v-icon> 
                                        <v-icon v-else small color="error" class="ml-2">$close</v-icon>
                                        <span class="ml-2">{{index}}</span>
                                    </li>
                                </ul>
                                <v-icon small class="iconCheck" v-if="!landingpageLayoutValue || (landingpageLayoutValue && landingpageLayoutValue != layout.id)">$checkboxOff</v-icon>
                                <v-icon small class="iconCheck" color="success" v-if="(landingpageLayoutValue && landingpageLayoutValue == layout.id)">$checkboxOn</v-icon>
                            </v-card-text>
                        </v-card>
                    </div>
                    <div v-if="layoutError" class="error--text mt-2">{{ $t('layoutisrequired') }}</div>
                </v-card-text>
            </v-card>
        </v-dialog>
        <Loader v-if="$store.state.loader" :flat="false" />
    </v-row>
</template>

<script>
import { mapActions } from "vuex";
import GenericDataService from '@/services/GenericDataService';
import getForm from "@/mixins/mixins";
import Loader from '@/components/Loader'

export default {
    name: "LibraryComponent",
    components: {
        FilterFormComponent: () => import("@/components/forms/FilterFormComponent"),
        LibraryComponentList: () => import("@/components/libraryComponent/LibraryComponentList"),
        LibraryPreviewComponent: () => import("@/components/LibraryPreviewComponent"),
        FormDialogComponent: () => import("@/components/dialogs/FormDialogComponent"),
        ActionMenusDialogComponent: () => import("@/components/dialogs/ActionMenusDialogComponent"),
        OperationStepperDialog: () => import("@/modules/operations/operationsComponents/OperationStepperDialog"),
        Loader
    },
    mixins:[getForm],
    props: ["formLib", "isFromPopup", "libType", "libParams", "pickerForm", "isFromLibraryPage", "rps", "operationCreatedId"],
    data() {
        return {
            models: [],
            page:1,
            loading:false,
            endScroll: false,
            hoverAdd:false,
            hoverCreateWithoutModel:false,
            publicPath: process.env.BASE_URL,
            openPreviewDialog:false,
            previewModel: false,
            form: null,
            showActionMenusDialog: false,
            itemsActionMenusDialog: [],
            displayOperationStepper: false,
            operationId: null,
            openExportStep1FormDialog: false,
            openLPStep1FormDialog: false,
            landingpageLayouts: [],
            landingpageLayoutValue: null,
            exportSourcesAndTargets: {
                "operation": ["contact", "demand", "rdv", "blacklist"],
                "retroplanning": ["contact", "demand"],
                "contact_list": ["contact", "demand"],
                "segment": ["contact", "demand"],
                "file": ["contact"]
            },
            exportSourceValue: "operation",
            exportTargetValue: "contact",
            exportSourceDisabled: false,
            openWorkflowStep1FormDialog: false,
            workflowTriggers: [],
            workflowTriggerValue: null,
            workflowName: "Nouveau Modèle Personnalisé",
            layoutError: false,
            modelUpload:[],
            inputKey:0
        }
    },
    created() {
        if(this.libType)
            this.confirmAction()

        if(this.libType && this.libType == 'LANDINGPAGE')
            GenericDataService.getData('/landingpage/getGenericLayouts').then((response) => {
                this.landingpageLayouts = response.data.data;
                this.landingpageLayoutValue = this.landingpageLayouts[0].id;
            });
        if(this.libType && this.libType == 'WORKFLOW')
            GenericDataService.getData("workflow/getTriggerList?is_system=true").then((response) => {
                let items = response.data.data;

                for (let item of items) {
                    if(!this.workflowTriggerValue && item.hasOwnProperty("value"))
                        this.workflowTriggerValue = item.value;

                    if (item.hasOwnProperty("text"))
                        item.text = this.$t(item.text);
                    else if(item.hasOwnProperty("header"))
                        item.header = this.$t(item.header);
                    this.workflowTriggers.push(item);
                }
            });
    },
    computed: {
        packId(){
            if(this.$store.state.auth.currentUser.accountParameters.packId)
                return this.$store.state.auth.currentUser.accountParameters.packId
            else 
                return false
        }
    },
    methods: {
        ...mapActions("alerts", ["setAlert"]),
        handleUploadZipModelEmail(event) {
            this.$store.dispatch("SET_LOADER", true);
            this.$refs.zipModelEmail.files.forEach(element =>
                this.modelUpload.push(element)
            );
            this.uploadZip();
        },
        uploadZip(){
            this.modelUpload.forEach(element => {
                if (element) {
                    const reader = new FileReader
                    const vm = this
                    reader.onload = e => {
                        let jsonData = {
                            'title': element.name,
                            'base64': e.target.result,
                            'type': 'model'
                        };
                        GenericDataService.postData("template/uploadZipEmail", jsonData).then((response) => {
                            this.$store.dispatch("SET_LOADER", false);
                            this.modelUpload = [];
                            this.inputKey++;
                            if(!this.isFromPopup){
                                this.$emit('refreshData');
                            } else {
                                this.selectModel(response.data.data);
                            }
                            
                        }).catch((error) => {
                            this.$store.dispatch("SET_LOADER", false);
                            this.$store.dispatch('ADD_SNACK', { message : this.$t('errorAction') }).then((e) => { this.$store.dispatch("REMOVE_SNACK") });
                        });
                    }
                    reader.readAsDataURL(element)
                } 
            });
        },
        openPreview(model){
            this.previewModel = model
            this.openPreviewDialog = true;
        },
        editModel(id){
            this.$emit('editModel', id)
        },
        resetChildsModel(id){
            this.$emit('resetChildsModel', id)
        },
        duplicateModel(id){
            this.$emit('duplicateModel', id)
        },
        deleteModel(id){
            this.$emit('deleteModel', id)
        },
        sendTestModel(id){
            this.$emit('sendTestModel', id)
        },
        scrollTest($evt){
            if(this.isInViewport('loaderCol') && this.loading == false && !this.endScroll){
                this.loading = true;
                this.page++
                this.confirmAction(false, false, true);
            }
        },
        isInViewport(id) {
            const rect = document.getElementById(id).getBoundingClientRect();
            const innerMedia = document.getElementById('outerMedia');
            return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
        },
        addResult(response){
            this.models.data = this.models.data.concat(response.data.data.data);
            if(this.models.data.length%20 != 0){
                this.endScroll = true
            }

        },
        submit(response) {
            this.models = response.data.data;
            //this.loadingFull = false
            if(this.models.data.length%20 != 0){
                this.endScroll = true
            }
        },
        confirmAction(saveAndClose = false, url = false, infiniteScroll = false) {
            if(!infiniteScroll) {
                this.endScroll = false
                this.loadingFull = true
                this.page = 1 
            }

            let payload = this._.cloneDeep(this.formLib.model)

            if(this.isFromPopup)
                payload.draft = "0";

            this.$store
                .dispatch('SET_DATA', {
                    url: url ? url+"&pageNb="+this.page : this.formLib.schema.options.posturl+"&pageNb="+this.page,
                    data: payload,
                })
                .then((response) => {
                    
                    if(infiniteScroll) {
                        this.addResult(response);
                    } else {
                        this.submit(response);
                    }
                    if(response.data.data.data.length == 0)
                        this.endScroll = true
                    setTimeout(() => {
                        this.disableConfirm = false
                        this.loading = false
                    }, 400);
                }).catch((e) =>  {
                    if(e.data && e.data.errors) {
                        for (let errorKey in e.data.errors) {
                            for (let sectionKey in this.formLib.schema.sections) {
                                for (let fieldKey in this.formLib.schema.sections[sectionKey].fields) {
                                    if(e.data.errors[errorKey].field === fieldKey) {
                                        this.formLib.schema.sections[sectionKey].fields[fieldKey].props['error'] = true;
                                        this.formLib.schema.sections[sectionKey].fields[fieldKey].props['error-messages'] = this.$t(e.data.errors[errorKey].message);
                                    }
                                }
                            }
                        }
                        this.key += 1;
                    } else {
                        let errorMessage = e.data && e.data.errorMessage ? e.data.errorMessage : this.$t('errorAPIform');
                        this.setAlert({
                            message: errorMessage,
                            type: "error",
                            timeout: 3000
                        });
                    }
                    setTimeout(() => {
                        this.disableConfirm = false
                    }, 200);
                });
        },
        redirectAfterAddRp(campaign) {
            if(campaign.operation_id) {
                this.$router.push({ name: 'OperationCampaignDetailDashboard', params: { id: campaign.id, operation_id: campaign.operation_id }});
            } else {
                this.$router.push({ name: 'RetroplanningDetail', params: { id: campaign.id }});
            }
        },
        selectModel(model) {
            this.$emit("hideLibraryDialog");
            if((this.pickerForm && model) || this.libType == 'COACH'){
                this.$emit("selectModel", model);
            } else {
                if(this.libType == 'OPERATIONTYPE') {
                    if(model){
                        let redirect ={
                            "name": "OperationDetail",
                            "params": {
                                "operation_id": 0
                            }
                        }
                        if(this.packId){ // si on est dans un pack on ne redirige pas vers le stepper
                            this.getForm("/operation/getForm?modele_id=" + model.id, false, {redirect : redirect}) 
                        } else {
                            this.getForm("/operation/getForm?modele_id=" + model.id, false, {event: "openOperationStepper"})
                        }
                        
                    } else {
                        GenericDataService.getData("/operation/getTypesItemsAction").then((response) => {
                            this.itemsActionMenusDialog = response.data.data;
                            if(this.itemsActionMenusDialog.length == 1){
                                this.getForm(this.itemsActionMenusDialog[0].url, false, {redirect: this.itemsActionMenusDialog[0].redirect})
                            } else {
                                this.showActionMenusDialog = true;
                            }
                            
                        });
                    }
                } else if(this.libType == 'EXPORT') {
                    if(model)
                        this.getForm(this.getExportFormUrl({ "export_model_id": model.id }));
                    else {
                        this.openExportStep1FormDialog = true;
                        if(this.libParams) {
                            if(this.libParams.operation_id) {
                                this.exportSourceValue = 'operation';
                                this.exportSourceDisabled = true;
                            }
                            if(this.libParams.retroplanning_id) {
                                this.exportSourceValue = 'retroplanning';
                                this.exportSourceDisabled = true;
                            }
                            if(this.libParams.contactlist_id) {
                                this.exportSourceValue = 'contact_list';
                                this.exportSourceDisabled = true;
                            }
                        }
                    }
                } else if(this.libType == 'SEGMENT') {
                    if(this.pickerForm) {
                        let url = '/library/getForm?formName=' + this.libType + '&step=["conception"]';
                        if(this.libParams && this.libParams.operation_type_id) 
                            url += '&postdata[operation_type_id]=' + this.libParams.operation_type_id;
                        if(this.libParams && this.libParams.operation_id) 
                            url += '&postdata[operation_id]=' + this.libParams.operation_id;
                        this.getForm(url, false, { event: 'selectSegmentModel', returnFullObject: true });
                    } else {
                        let url = "/contactlist/getForm?formName=full";
                        if(this.libParams && this.libParams.operationId) 
                            url += "&operationId=" + this.libParams.operationId;
                        if(model) 
                            url += "&modele_id=" + model.id;
                        this.getForm(url, false, {event: "openContactlistSegmentation", returnFullObject: true});
                    }
                } else if(this.libType == 'LANDINGPAGE') {
                    if(!model)
                        this.openLPStep1FormDialog = true
                    else if(model && this.libParams && this.libParams.operation_type_id) {
                        this.$emit("selectModel", model);
                    } else if(model && this.libParams && this.libParams.entrypoint_id) {
                        this.$emit("selectModel", model);
                    } else if(model){
                        let action = {}
                        if((this.$route.params.operation_id && this.libParams && this.libParams.operation_id == this.$route.params.operation_id) || 
                            (this.operationCreatedId && this.libParams && this.libParams.operation_id == this.operationCreatedId) || (this.libParams && this.libParams.inStepper)) {
                            action = { event: 'customRefresh', returnFullObject: true } // cas depuis operation Detail lors de l'ajout d'un ep landing : event de refresh des entrypoints dans le detail d'opé au retour du form
                        } else
                            action = { redirect: { name: 'OperationDetail', params: { operation_id: null }}} //cas lors de la creation depuis le nouveau menu : event de redirection sur l'opé ou on a inséré le nouveau ep
                        
                        
                        this.getForm('/operation/'+(this.libParams && this.libParams.operation_id ? this.libParams.operation_id+'/' : '')+'getFormEntryPoints?type=entryLanding&model_id='+model.id, false, action);
                    }
                } else if(this.libType == 'LANDINGPAGEV2') {
                    if(!model) {
                        let url = '/library/getForm?formName=' + this.libType + '&addClass=conception&step=["conception"]';
                        this.getForm(url, false, { event: 'selectModel', returnFullObject: true });
                    } else if(model && this.libParams && this.libParams.id && this.libParams.mode == 'change'){
                        this.$emit('selectModel',{model: model, id: this.libParams.id})
                    } else {
                        let url = '/landingpagev2/getForm?modele_id=' + model.id;
                        this.getForm(url, false, { event: 'customRefresh', returnFullObject: true });
                    }      
                } else if(this.libType == 'FORM') {
                    if(!model) {
                        let url = '/library/getForm?formName=' + this.libType + '&step=["conception"]';
                        this.getForm(url, false, { event: 'selectModel', returnFullObject: true });
                    } else {
                        let url = '/form/getForm?modele_id=' + model.id;
                        this.getForm(url, false, { event: 'openFormConceptionForm', returnFullObject: true });
                    }
                } else if(this.libType == 'WORKFLOW'){
                    if(!model)
                        this.openWorkflowStep1FormDialog = true
                    else if(model && this.libParams && this.libParams.operation_id) {
                        this.getForm('/library/getForm?id='+model.id+'&formName=' + this.libType + '&step=["conception"]'+(this.libParams.operation_id ? '&postdata[opeid]='+this.libParams.operation_id : ''), false, { event: 'customRefresh', returnFullObject: true });  
                    } else if(model && this.libParams && this.libParams.operation_type_id) {
                        this.$emit('selectModel', model)
                    } else if(model) {
                       this.getForm('/library/getFormSelectOpe?model_id='+model.id+'&libType='+this.libType, false, { redirect: { name: 'WorkflowOperation', params: { operation_id: null }}});
                    }
                } else if(['EMAIL', 'SMS', 'MMS', 'VMS', 'MAILING', 'ENVELOPE', 'TEL'].includes(this.libType)) {
                    if(this.pickerForm) {
                        if(!model) {
                            let url = '/library/getForm?formName=' + this.libType + '&step=["conception"]';
                            if(this.libParams && this.libParams.operation_type_id)
                                url += '&postdata[operation_type_id]=' + this.libParams.operation_type_id;
                            if(this.libParams && this.libParams.operation_id)
                                url += '&postdata[operation_id]=' + this.libParams.operation_id;
                            if(this.libParams && this.libParams.sendToNonOpeners)
                                url += '&sendToNonOpeners=' + this.libParams.sendToNonOpeners;
                            if(this.libParams && this.libParams.campaign_id)
                                url += '&campaign_id=' + this.libParams.campaign_id;

                            this.getForm(url, false, {event: 'selectLPModel', returnFullObject: true})
                        }
                    } else {
                        if(!this.libParams || !this.libParams.operation_type_id) {
                            if(model) {
                                let campTypeUrl = this.libType === "TEL" ? 'campCALL' : 'camp' + this.libType + 'out';
                                let url = '/retroplanning/getForm?formName=@link:' + campTypeUrl + '.params/formOpe' + '&modele_id=' + model.id;
                                if(this.libParams && this.libParams.operation_id)
                                    url += '&operation_id=' + this.libParams.operation_id;
                                if(this.libParams && this.libParams.sendToNonOpeners)
                                    url += '&sendToNonOpeners=' + this.libParams.sendToNonOpeners;
                                if(this.libParams && this.libParams.campaign_id)
                                    url += '&campaign_id=' + this.libParams.campaign_id;
                                this.getForm(url, false, { event: 'redirectAfterAddRp', returnFullObject: true });
                            } else {
                                let url = '/library/getForm?formName=' + this.libType + '&step=["conception"]';
                                if(this.libParams && this.libParams.operation_id)
                                    url += '&postdata[operation_id]=' + this.libParams.operation_id;
                                this.getForm(url, false, { event: 'selectModel', returnFullObject: true }); 
                            }
                        }
                    }
                }
            }
        },
        selectLPModel(model){
            this.$emit("selectModel", model);
        },
        selectSegmentModel(model){
            this.$emit("selectModel", model);
        },
        actionForm(item) {
            if(this.showActionMenusDialog) {
                this.itemsActionMenusDialog = [];
                this.showActionMenusDialog = false;
            }
            
            this.getForm(item.url, false, item)
        },
        openOperationStepper(id){
            this.operationId = id
            this.displayOperationStepper = true
        },
        closeOperationStepper() {
            this.operationId = null;
            this.displayOperationStepper = false;
        },
        StepperFinished(){
            this.$router.push({name:"OperationDetail", params: {operation_id: this.operationId}})
            this.closeOperationStepper();
        },
        exportSources() {
            return Object.keys(this.exportSourcesAndTargets);
        },
        exportTargets() {
            return this.exportSourcesAndTargets[this.exportSourceValue];
        },
        exported() {
            this.form = null;
        },
        getExportFormUrl(params = {}) {
            let url = "/export/getForm?formName=config";

            for (const param in params)
                url += "&params[" + param + "]=" + encodeURIComponent(params[param]);

            if(this.libParams)
                for (const param in this.libParams)
                    url += "&params[" + param + "]=" + encodeURIComponent(this.libParams[param]);

            return url;
        },
        openContactlistSegmentation(contactlist){
            if(!contactlist.emptyList)
                this.getForm("contactlist/" + contactlist.id + "/getForm?formName=segment");
            this.$emit("customRefresh");
        },
        customRefresh() {
            this.$emit("customRefresh");
        },
        selectLayout(id){
            if(this.landingpageLayoutValue && this.landingpageLayoutValue == id){
                this.landingpageLayoutValue = null;
            } else {
                this.landingpageLayoutValue = id
                this.layoutError = false
            }
            
            this.$forceUpdate()
        },
        selectLayoutConfirm(){
            if(!this.landingpageLayoutValue)
                this.layoutError = true
            else {
                if(this.libParams && this.libParams.operation_type_id)
                    this.getForm('/library/getForm?formName=LANDINGPAGE&postdata[layout_id]=' + this.landingpageLayoutValue + '&step=["conception"]&postdata[opetypeid]='+this.libParams.operation_type_id, false, {event: 'selectLPModel', returnFullObject: true}); 
                else
                    this.getForm('/library/getForm?formName=LANDINGPAGE&postdata[layout_id]=' + this.landingpageLayoutValue+ '&step=["conception"]', false, {event: 'selectModel', returnFullObject: true});   

                this.openLPStep1FormDialog = false;
            }
            
        },
        selectWorkflowTriggerConfirm(){
            if(this.libParams && this.libParams.operation_type_id)
                this.getForm('/library/getForm?formName=WORKFLOW&postdata[trigger_id]=' + this.workflowTriggerValue+ '&step=["conception"]&postdata[opetypeid]='+this.libParams.operation_type_id+((this.workflowName != '') ? '&postdata[name]='+this.workflowName : ''), false, {event: 'selectLPModel', returnFullObject: true}); 
            else if (this.libParams && this.libParams.operation_id)
                this.getForm('/library/getForm?formName=WORKFLOW&postdata[trigger_id]=' + this.workflowTriggerValue+ '&step=["conception"]&postdata[opeid]='+this.libParams.operation_id+((this.workflowName != '') ? '&postdata[name]='+this.workflowName : ''), false, {event: 'customRefresh', returnFullObject: true});   
            else
                this.getForm('/library/getForm?formName=WORKFLOW&postdata[createForOpe]=true&postdata[trigger_id]=' + this.workflowTriggerValue+ '&step=["conception"]'+((this.workflowName != '') ? '&postdata[name]='+this.workflowName : ''), false, {event: 'selectModel', returnFullObject: true});   
            this.openWorkflowStep1FormDialog = false;
            this.workflowName = "Nouveau Modèle Personnalisé";
        },
        openFormConceptionForm(form) {
            if(form.model_source_id)
                this.getForm("/library/getForm?formName=FORM&id=" + form.id + "&step=[\"conception\"]");
            this.$emit("customRefresh");
        },
    },
}
</script>

<style lang="scss">
    .thumbAdd {
        height:190px;
        background-color: var(--v-pale_purple-base);
        display:flex;
        align-items:center;
        justify-content:center;
    }
    .hoverThumb {
        .v-btn__content {
            font-size:14px !important;
        }
    }
</style>