<template>
  <div class="form" style="overflow: auto; height: inherit">
    <v-skeleton-loader
      v-if="!form.schema"
      type="article,article, actions"
    ></v-skeleton-loader>
    <v-container fluid class="containerForm" :class="noPadding ? 'pa-0' : ''" v-else>
      <v-form ref="form" v-model="valid" @submit.prevent="validateForm" :key="key">
        <div class="row ma-0 objectClass">
          <div v-for="(section, sectionName) in form.schema.sections" :key="sectionName" style="width:100%;" :class="Object.keys(form.schema.sections).length > 1 ? ('ma-0 pl-2 pt-2 col-' + (form.schema.options.rowSections ? 12 : (12 / Object.keys(form.schema.sections).length)) + ' d-block section') : 'row ma-0 pt-2'">
            <span class="py-2 h4 title mb-6" v-if="Object.keys(form.schema.sections).length > 1 && !section.hideTitle">{{section.title ? $t(section.title) : ''}}</span>
            <div :class="field.class ? field.class : 'col col-12 pa-0'" v-for="(field, fieldName) in section.fields" :key="fieldName" v-if="field.component && !field.hide">
              <v-divider v-if="field.dividerBefore" class="py-2"></v-divider>
              <div class="row ma-0 objectClass">
                <FormComponentList :field="field" :fieldName="fieldName" :model="form.model" :key="form.reference + '_' + keys[fieldName]" :isFromLibraryPage="isFromLibraryPage" v-on="$listeners" />
              </div>
              <v-divider v-if="field.dividerAfter" class="py-2"></v-divider>
            </div>
          </div>
        </div>
      </v-form>

      <div v-if="form.schema.options.progressbar && showProgressBar">
        <v-progress-linear :value="progressBarValue" color="success" height="20" rounded dark>
            <template v-slot:default="{ value }">
                <strong>{{ value }}%</strong>
            </template>
        </v-progress-linear>
      </div>

      <SuccessErrorAlertComponent v-if="$store.state.alerts.message" />
      <v-layout row class="mt-2">
        <v-spacer></v-spacer>
        <div class="registerForm" v-if="!hideButtons && !form.params.buttons">
          <v-btn v-if="form.params.resetBtn && form.params.resetBtn.action" outlined color="white" class="mt-1 mr-3" @click="$emit('launchFunction', form.params.resetBtn.functionName)">{{ $t(form.params.resetBtn.buttonTitle ? form.params.resetBtn.buttonTitle : "reset") }}</v-btn>
           <v-btn v-if="step" color="white" class="mr-3" outlined :loading="loader"  @click="validateForm(true)">{{ $t("formSaveAndClose") }}</v-btn>
           <v-btn color="white" class="mt-1 mr-3" depressed outlined :loading="loader" @click="validateForm(step ? false : true)">{{ $t(form.params && form.params.buttonConfirmTitle ? form.params.buttonConfirmTitle : "formSave") }}</v-btn>
        </div>
        <div class="registerForm" v-else-if="!hideButtons">
          <v-btn v-for="(button, index) in form.params.buttons" :key="index" x-large color="white" class="mt-1 mr-3" outlined @click="launchFunction(button)">{{ $t(button.title) }}</v-btn>
        </div>
      </v-layout>
      <v-dialog v-model="checkBeforeDialog" width='800'>
          <v-card>
            <v-toolbar tile flat dark class="bg-gradient">
                 <v-toolbar-title>{{checkBeforeDialogTitle}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn outlined dark depressed @click.native="cancelDialog" class="ml-3">{{$t('cancel')}}</v-btn>
                <v-btn outlined dark depressed @click="validateAction(step ? false : true)" class="ml-3">{{$t('confirm')}}</v-btn>
            </v-toolbar>
            <v-card-text class="pt-4">
                <v-row>
                  <v-col cols="12">
                    {{checkBeforeDialogText}}
                  </v-col>
                  <v-col cols="12" v-if="checkBeforeDialogField">
                    <vCustomSelect :model='form.model' :fieldName="checkBeforeDialogField.fieldName" :field="checkBeforeDialogField" />
                  </v-col>
                  <v-col cols="12" v-if="checkBeforeDialogImpact">
                      <v-checkbox class="mt-0 pt-0" hide-details v-model="form.model['acceptImpact']" :label="$t('impactCheckBoxLabel')"></v-checkbox>
                  </v-col>
                  <v-col cols="12" v-if="checkBeforeDialogError">
                      <b class="red--text">{{ checkBeforeDialogError }}</b>
                  </v-col>
                </v-row>
            </v-card-text>
          </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import SuccessErrorAlertComponent from "@/components/alerts/SuccessErrorAlertComponent";
import GenericDataService from '@/services/GenericDataService'
import { i18n } from "@/i18n";
import { mapActions } from "vuex";
import FormComponentList from "@/components/forms/FormComponentList";
import vCustomSelect from '@/components/forms/inputs/v-custom-select'

export default {
  name: "FormComponent",
  components: {
    SuccessErrorAlertComponent,
    FormComponentList,
    vCustomSelect
  },
  props: ["form", "action", "step", "saveAtInput", "isFromLibraryPage", "hideButtons", "noPadding"],
  data() {
    return {
      checkBeforeDialog:false,
      checkBeforeDialogImpact: false,
      checkBeforeDialogField: false,
      checkBeforeDialogTitle: "",
      checkBeforeDialogText: "",
      checkBeforeDialogError: false,
      publicPath:process.env.BASE_URL,
      valid: null,
      loader: false,
      key: 0,
      keys: [],
      showProgressBar: false,
      progressBarInterval: null,
      progressBarValue: 0,
    };
  },
  mounted() {
    this.showHideConditionalFields();
    setTimeout(() => {
      this.refreshItems();
    }, 500);
    
  },
  computed: {
    model: function () {
      return Object.assign({}, this.form.model);
    }
  },
  watch: {
    // Permet de supprimer la valeur du model lorsqu'une valeur dépend d'une autre (ex: Assignement => Vendeur attribué dépend du PDV)
    model: {
      handler(newModel, oldModel) {
        this.$emit("modelIsUpdated", this.model);
        for (var fieldName in newModel) {
          // Ça ne fonctionne pas avec les champs array (Ex : Groupe et point de vente dans le formulaire d'édition d'un user)
          // if(!oldModel.hasOwnProperty(fieldName) || oldModel[fieldName] !== newModel[fieldName]) {
            for (var [sectionName, section] of Object.entries(this.form.schema.sections)) {
              if(section.fields.hasOwnProperty(fieldName)){ // Permet de récupérer le champ qui vient d'être update
                if(section.fields[fieldName].hasOwnProperty('depends_fields') || section.fields[fieldName].hasOwnProperty('conditional_fields') || section.fields[fieldName].hasOwnProperty('properties_for')) {
                  if(section.fields[fieldName].hasOwnProperty('depends_fields')) {
                    for (var dependFieldName of section.fields[fieldName].depends_fields) {
                      this.keys[dependFieldName] = this.keys[dependFieldName] ? this.keys[dependFieldName] + 1 : 1;
                    }
                  }
                  if(section.fields[fieldName].hasOwnProperty('properties_for')){
                    let fieldToFill = section.fields[fieldName].properties_for[0]
                    if(this.model[fieldName]) {
                      if(section.fields[fieldName].hasOwnProperty('lexxyFields'))
                        section.fields[fieldToFill].props.items = Object.values(this.model[fieldName]).reduce((filtered, value) => { if(value) { filtered.push({"value": value, "text": this.$t(value)}); } return filtered; }, []);
                      else
                        section.fields[fieldToFill].props.items = section.fields[fieldName].props.items.filter((item) => this.model[fieldName].includes(item.value))
                      this.keys[fieldToFill] = this.keys[fieldToFill] ? this.keys[fieldToFill] + 1 : 1;
                    }
                    if(this.model[fieldToFill] && (!this.model[fieldName] || !this.model[fieldName].includes(this.model[fieldToFill])))
                      this.form.model[fieldToFill] = null;
                  }
                  if(section.fields[fieldName].hasOwnProperty('conditional_fields')) {
                    this.showHideConditionalFields();
                  }
                  this.$forceUpdate();
                }
              }
            }
          // }
        }
        if(this.saveAtInput && !this.loader) {
            this.validateForm();
        }
      },
      deep: true
    },
  },
  methods: {
    ...mapActions("alerts", ["setAlert"]),
    refreshItems(){
      for (var fieldName in this.form.model){
        for (var [sectionName, section] of Object.entries(this.form.schema.sections)){
          if(section.fields.hasOwnProperty(fieldName)){
            if( section.fields[fieldName].hasOwnProperty('properties_for')){
              let fieldToFill = section.fields[fieldName].properties_for[0]
              if(this.form.model[fieldName]) {
                if(section.fields[fieldName].hasOwnProperty('lexxyFields'))
                  section.fields[fieldToFill].props.items = Object.values(this.model[fieldName]).reduce((filtered, value) => { if(value) { filtered.push({"value": value, "text": this.$t(value)}); } return filtered; }, []);
                else
                  section.fields[fieldToFill].props.items = section.fields[fieldName].props.items.filter((item) => this.model[fieldName].includes(item.value))
                setTimeout(() => {
                  this.keys[fieldToFill] = this.keys[fieldToFill] ? this.keys[fieldToFill] + 2 : 1;
                  this.$forceUpdate()
                }, 200);
                
              }
            }
          }
        }
      }
    },
    startProgressBar() {
        this.progressBarValue = 0;
        this.progressBarInterval = setInterval(() => {
            GenericDataService.getData(this.form.schema.options.progressbar.replace(/{{(.*?)}}/g, this.replaceFilter)).then((response) => {
                if(response.data.data)
                    this.progressBarValue = Math.round((100 * response.data.data.currentLoop) / response.data.data.nbLoop);
            });
        }, 500);
    },
    stopProgressBar() {
        this.progressBarValue = 100;
        clearInterval(this.progressBarInterval);
    },
    // Permet de remplacer les filtres dans l'URL Ajax par les valeurs du model
    replaceFilter(match, fieldName) {
        if(this.model[fieldName]) {
            return this.model[fieldName];
        }
        return '0';  // Attention problème si id = 0
    },
    confirmAction(saveAndClose = false, url = false){
      // this.disableConfirm = false
      if(!url && !this.form.schema.options.posturl)
        this.$emit("saveForm", "closeDialog");
      else {
        if(this.form.schema.options.progressbar) {
            this.showProgressBar = true;
            this.startProgressBar();
        }

        this.$store
              .dispatch(this.action, {
                url: url ? url : this.form.schema.options.posturl,
                data: this.form.model,
              })
              .then((response) => {
                this.stopProgressBar();

                if(saveAndClose) {
                  response.saveAndClose = true;
                } else if(this.step) {
                  response.nextStep = this.step + 1;
                }
                setTimeout(() => {
                  this.loader = false
                  this.$emit("removeLoader");
                }, 200);
                this.$emit("saveForm", response);
              }).catch((e) =>  {
                this.stopProgressBar();
                if(e.data && e.data.errors) {
                  for (let errorKey in e.data.errors) {
                    for (let sectionKey in this.form.schema.sections) {
                      for (let fieldKey in this.form.schema.sections[sectionKey].fields) {
                        if(e.data.errors[errorKey].field === fieldKey) {
                          this.form.schema.sections[sectionKey].fields[fieldKey].props['error'] = true;
                          this.form.schema.sections[sectionKey].fields[fieldKey].props['error-messages'] = this.$t(e.data.errors[errorKey].message);
                        }
                      }
                    }
                  }
                  this.key += 1;
                } else {
                  let errorMessage = e.data && e.data.errorMessage ? e.data.errorMessage : this.$t('errorAPIform');
                  this.setAlert({
                    message: errorMessage,
                    type: "error",
                    timeout: 3000
                  });
                }
                setTimeout(() => {
                  this.loader = false
                  this.$emit("removeLoader");
                }, 200);
              });
      }
       

    },
    validateAction(saveAndClose = false){
        this.checkBeforeDialogError = false
        if(this.checkBeforeDialogField){
          if(!this.form.model[this.checkBeforeDialogField.fieldName])
            this.checkBeforeDialogError = "fieldsAreRequired";
        }
        if(this.checkBeforeDialogImpact){
          if(!this.form.model['acceptImpact'])
            this.checkBeforeDialogError = "fieldsAreRequired";
        }
        if(!this.checkBeforeDialogError)
          this.confirmAction(saveAndClose);
    },
    validateForm(saveAndClose = false, url = false) {
        this.loader = true

      // Supprime les objects vides
      for (var fieldName in this.form.model) {
        let field = this.getFieldByFieldName(fieldName); // Récupère le field
        if(field && Array.isArray(this.form.model[fieldName]) && field['component'] === 'VArray') {
          this.form.model[fieldName] = this.form.model[fieldName].filter(function(value) {

            // Check pour le VArray qu'on est bien tout les champs de remplis
            if(field && field['component'] === 'VArray' && (Object.keys(value).length === 0 || Object.keys(value).some((k) => value[k] === "" || typeof(value[k]) === 'undefined')))
              return false;

            return true;

            // if((Object.keys(value).length === 0 || Object.keys(value).some((k) => value[k] === "" || !value[k])) && (typeof(value) == 'array' || typeof(value) == 'object') && fieldName != "files" && fieldName != "stock_selection")
            //   return false;
          }, this);
        }
        // if(field && field.component == 'VWysiwyg'){
        //   if(!this.form.model[fieldName] || this.form.model[fieldName] == '')
        //     return false
          
        //   return true
        // }
      }

      if (this.$refs.form.validate()) {
        if(this.form.schema.options.checkBefore){
            GenericDataService.postData(this.form.schema.options.checkBefore.url, this.form.model).then((response) => {
              if(response.data.data && response.data.data.title){
                if(response.data.data.var){
                  this.checkBeforeDialogTitle = this.$t(response.data.data.title, {var: response.data.data.var});
                }
                else 
                  this.checkBeforeDialogTitle = this.$t(response.data.data.title);

                this.checkBeforeDialogText = this.$t(response.data.data.text);
                if(response.data.data.impact)
                  this.checkBeforeDialogImpact = true
                if(response.data.data.field)
                  this.checkBeforeDialogField = response.data.data.field

                this.checkBeforeDialog = true;
              } else {
                this.confirmAction(saveAndClose, url)
              }
            }).catch((e) => {
              this.loader = false
              this.$emit("removeLoader");
            })
        } else {
            this.confirmAction(saveAndClose, url)
        }
      } else {
        this.loader = false
        this.$emit("removeLoader");
      }
    },
    validateFormPart(){
      for (var fieldName in this.form.model) {
        let field = this.getFieldByFieldName(fieldName); // Récupère le field
        if(field && Array.isArray(this.form.model[fieldName])) {
          this.form.model[fieldName] = this.form.model[fieldName].filter(function(value) {

            // Check pour le VArray qu'on est bien tout les champs de remplis
            if(field && field['component'] === 'VArray' && (Object.keys(value).length === 0 || Object.keys(value).some((k) => value[k] === "" || typeof(value[k]) === 'undefined')))
              return false;

            return true;

          }, this);
        }
      }
      if (this.$refs.form.validate()) {
        return true;
      } else {
        this.loader = false
        this.$emit("removeLoader");
        return false;
      }

    },
    getFieldByFieldName(fieldName) {
      for (var [sectionName, section] of Object.entries(this.form.schema.sections)) {
        if(section.fields.hasOwnProperty(fieldName)){ // Permet de récupérer le champ qui vient d'être update
          return section.fields[fieldName];
        }
      }
      return null;
    },
    showHideConditionalFields() {
      let forcedHide = [];
      for (var [sectionName, section] of Object.entries(this.form.schema.sections)) {
        if(section.hasOwnProperty('fields')) {
          
          for (var [fieldName, field] of Object.entries(section.fields)) {
            
            if(field.hasOwnProperty('conditional_fields')) {
              for (var [conditionalFieldsSectionName, conditionalFieldsSection] of Object.entries(field.conditional_fields)) {
                
                for (var conditionalField of conditionalFieldsSection) {
                  
                  if(this.form.schema.sections[conditionalFieldsSectionName] && this.form.schema.sections[conditionalFieldsSectionName].hasOwnProperty('fields') && this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField]) {
                    
                      
                    if(this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField].hasOwnProperty('conditional_of')) {
                      var fieldCanBeVisible = true;
                      for (var conditionalOf in this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField].conditional_of) {
                        if(conditionalOf === fieldName) {
                          // Check sur la value
                          let fieldNameCondition = 'value'; // valeur par défaut
                          if(this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField].conditional_of[conditionalOf].hasOwnProperty('field')){ //si field est défini on remplace value par un autre champ
                              fieldNameCondition = this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField].conditional_of[conditionalOf].field
                          }
                          if(this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField].conditional_of[conditionalOf].hasOwnProperty(fieldNameCondition)) {
                            if(fieldNameCondition === 'min_length') {
                              let valueModel = this.model[fieldName];
                              if(!valueModel || valueModel.length < this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField].conditional_of[conditionalOf][fieldNameCondition]) {
                                fieldCanBeVisible = false;
                              }
                            } else if(typeof this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField].conditional_of[conditionalOf][fieldNameCondition] === 'string') {
                              let valueModel = typeof this.model[fieldName] === 'object' && this.model[fieldName] !== null ? (this.model[fieldName][fieldNameCondition] ? this.model[fieldName][fieldNameCondition] : null) : this.model[fieldName];
                              if(valueModel !== this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField].conditional_of[conditionalOf][fieldNameCondition]) {
                                fieldCanBeVisible = false;
                              }

                              // Début -- Traitement custom pour afficher ou non le switch "Inclure les pdv du groupe" dans la création d'une opé
                              let entityItems = this.form.schema.sections[conditionalFieldsSectionName].fields[fieldName].props.items;
                              if(conditionalField === "includeChilds" && entityItems && this.model[fieldName]) {
                                fieldCanBeVisible = false;
                                this.model[fieldName].forEach(function(entityId) {
                                    let entity = entityItems.find(({ value }) => value === entityId);
                                    if(entity && entity.hasChild)
                                        fieldCanBeVisible = true;
                                }, entityItems, fieldCanBeVisible);
                                if(!fieldCanBeVisible)
                                    this.form.model['includeChilds'] = false;
                              }
                              // Fin -- Traitement custom pour afficher ou non le switch "Inclure les pdv du groupe" dans la création d'une opé

                            } else if (typeof this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField].conditional_of[conditionalOf][fieldNameCondition] === 'object') {
                              let valueModel = typeof this.model[fieldName] === 'object' && this.model[fieldName] !== null ? (this.model[fieldName][fieldNameCondition] ? this.model[fieldName][fieldNameCondition] : null) : this.model[fieldName];
                              if(!this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField].conditional_of[conditionalOf][fieldNameCondition].includes(valueModel)) {
                                fieldCanBeVisible = false;
                              }
                            } else if (typeof this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField].conditional_of[conditionalOf][fieldNameCondition] === 'boolean') {
                              let valueModel = typeof this.model[fieldName] === 'object' && this.model[fieldName] !== null ? (this.model[fieldName][fieldNameCondition] ? this.model[fieldName][fieldNameCondition] : null) : this.model[fieldName];

                              // Début -- Traitement custom pour afficher le nom du RP dans le titre Date de début et Fin sur le form opé maketing
                              if(this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField].conditional_of[conditionalOf]['updateTitle'] && typeof this.model[fieldName] === 'object' && this.model[fieldName] !== null) {
                                this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField].tmpTitle = this.model[fieldName][this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField].conditional_of[conditionalOf]['updateTitle']];
                                this.keys[conditionalField] = this.keys[conditionalField] ? this.keys[conditionalField] + 1 : 1;
                              }
                              // Fin -- Traitement custom pour afficher le nom du RP dans le titre Date de début et Fin sur le form opé maketing

                              if(valueModel !== this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField].conditional_of[conditionalOf][fieldNameCondition]) {
                                if(typeof valueModel !== "undefined" || (typeof valueModel === "undefined" && this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField].conditional_of[conditionalOf][fieldNameCondition] === true)) {
                                  fieldCanBeVisible = false;
                                }
                              }
                            }
                          } else if(Array.isArray(this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField].conditional_of[conditionalOf])) {
                            let neededValues = this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField].conditional_of[conditionalOf].reduce(function (filtered, field) {
                              if(field.value)
                                filtered.push(field.value);
                              return filtered;
                            }, []);

                            if(!neededValues.includes(this.model[fieldName]))
                              fieldCanBeVisible = false;
                          }
                        }
                      }
                      if(!fieldCanBeVisible || forcedHide[conditionalField] === true) {
                        this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField].hide = true;
                        forcedHide[conditionalField] = true;
                      } else {
                        this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField].hide = false;
                      }
                    } else if(this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField].hasOwnProperty('conditional_property')){
                      this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField].properties.forEach(property => {
                          if(property.hasOwnProperty('conditional_of')){
                            var propertyCanBeVisible = true;
                            for (var conditionalOf in property.conditional_of){

                              if(conditionalOf === fieldName) {
                                let fieldNameCondition = 'value';
                                if(property.conditional_of[conditionalOf].hasOwnProperty('value')){
                                  if(typeof property.conditional_of[conditionalOf][fieldNameCondition] === 'string') {
                                    if(this.model[fieldName] !== property.conditional_of[conditionalOf][fieldNameCondition]) {
                                      propertyCanBeVisible = false;
                                    }
                                  } else if (typeof property.conditional_of[conditionalOf][fieldNameCondition] === 'object') {
                                    if(!property.conditional_of[conditionalOf][fieldNameCondition].includes(this.model[fieldName])) {
                                      propertyCanBeVisible = false;
                                    }
                                  } else if (typeof property.conditional_of[conditionalOf][fieldNameCondition] === 'boolean') {
                                    if(this.model[fieldName] !== property.conditional_of[conditionalOf][fieldNameCondition]) {
                                      if(typeof this.model[fieldName] !== "undefined" || (typeof this.model[fieldName] === "undefined" && property.conditional_of[conditionalOf][fieldNameCondition] === true)) {
                                        propertyCanBeVisible = false;
                                      }
                                    }
                                  }
                                }
                              }
                            }
                            if(!fieldCanBeVisible) {
                              property.hide = true;
                            } else {
                              property.hide = false;
                            }
                          }
                      });
                        // for(property in this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField].properties){
                        //   if(property.hasOwnProperty('conditional_of')){
                        //     var propertyCanBeVisible = true;
                        //     for (var conditionalOf in property.conditional_of){
                        //       if(conditionalOf === fieldName) {
                        //         if(this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField].conditional_of[conditionalOf].hasOwnProperty('value')){
                        //           if(typeof this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField].conditional_of[conditionalOf][fieldNameCondition] === 'string') {
                        //             if(this.model[fieldName] !== this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField].conditional_of[conditionalOf][fieldNameCondition]) {
                        //               propertyCanBeVisible = false;
                        //             }
                        //           } else if (typeof this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField].conditional_of[conditionalOf][fieldNameCondition] === 'object') {
                        //             if(!this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField].conditional_of[conditionalOf][fieldNameCondition].includes(this.model[fieldName])) {
                        //               propertyCanBeVisible = false;
                        //             }
                        //           } else if (typeof this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField].conditional_of[conditionalOf][fieldNameCondition] === 'boolean') {
                        //             if(this.model[fieldName] !== this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField].conditional_of[conditionalOf][fieldNameCondition]) {
                        //               if(typeof this.model[fieldName] !== "undefined" || (typeof this.model[fieldName] === "undefined" && this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField].conditional_of[conditionalOf][fieldNameCondition] === true)) {
                        //                 propertyCanBeVisible = false;
                        //               }
                        //             }
                        //           }
                        //         }
                        //       }
                        //     }
                        //     if(!fieldCanBeVisible) {
                        //       property.hide = true;
                        //     } else {
                        //       property.hide = false;
                        //     }

                        //   }
                        // }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    cancelDialog(){
      this.checkBeforeDialogTitle = "";
      this.checkBeforeDialogText = "";
      this.checkBeforeDialog = false;
    },
    launchFunction(button) {
        if(button.action) {
            if(button.action === 'function' && button.functionName === 'validateForm') {
                this.validateForm(true, button.url ? button.url : false);
            }

            // Ici on peux check si l'action est un emit et faire le dev nécessaire
        }
    }
  },
};
</script>

<style lang="scss">
.form {
  .containerForm {
    overflow: hidden !important;
  }
  .section {
    .title {
      width: 100%;
      display: inline-block;
    }
  }
  .required {
    label.v-label:after {
      content: '*';
      color: var(--v-error-base);
      margin-left: 5px;
    }
  }
  .registerForm {
    position:absolute;
    right:13px;
    top:10px;
  }
}
</style>
