<template>
  <div class="col col-12 pa-0">
    <v-range-slider 
      :label="field.title ? $t(field.title) : ''"
      :class="field.props && field.props.required ? 'required' : ''"
      v-model="model[fieldName]"
      v-bind="field.props"
      :rules=[validate]
    ></v-range-slider>
  </div>
</template>

<script>
import validate from '../functions/validations'

export default {
  props: ["model", "fieldName", "field"],
  mixins: [validate],
  data() {
    return {};
  },
};
</script>

<style lang="scss">
</style>