<template>
    <div :class="nocols ? 'input-form variableInput' : (varMode ? 'col  pa-0 input-form variableInput' : 'col col-12 pa-0 input-form variableInput')" style="position:relative;">
        <span v-if="field.title" class="label">
            <b>{{$t(field.title)}}</b>
            <span v-if="field.props && field.props.required" style="color:red">&nbsp;*</span>
            <infoTooltip v-if="field.tooltip" :tooltip="field.tooltip" />
        </span>
        <v-text-field
        v-model="model[fieldName]"
        :label="field.title ? $t(field.title) : ''"
        v-bind="field.props"
        :rules=[validate]
        :hint="hint && varPresent ? hint : ''"
        single-line
        @click.stop="moveCursor"
        @keypress="inputVariable"
        @input="testInput"
        class="pt-0 mt-0"
        :id="index ? 'inputVar_'+this.fieldName+this.index : 'inputVar_'+this.fieldName"
        :ref="'inputVariable'+this.fieldName"
        style="position:relative; z-index:3;"
        >
            <template v-slot:append>
                <v-btn depressed text @click.stop="openTestMenu" class="square">
                    <v-icon dark small color="darkgrey">
                        $bracketscurly_d
                    </v-icon>
                </v-btn>
            </template>
        </v-text-field>
        <varMenu :menuItems="variables" 
        :isLeft="isLeft" 
        :left="left" :isTop="isTop" :isOffsetX="false" :hasTitle="field.title ? true : false" @menuVarClick="addVal" :isOffsetY="false" ref="varMenu"/>
        <span v-if="hint && errorText" style="position:absolute; bottom:0" class="error--text">{{ $t(errorText) }}</span>
    </div>
</template>

<script>
import validate from '../functions/validations'
import messageSender from '../functions/validations'
import varMenu from '@/components/varMenu'
import GenericDataService from '@/services/GenericDataService'
import infoTooltip from '@/components/infoTooltip'

export default {
    props: ["model", "fieldName", "field", "nocols", "index", "blockMoveCursor", "varMode", "contexts", "hint", "operationId", "checkMessageSender"],
    components: {varMenu, infoTooltip},
    mixins: [validate, messageSender],
    //link_d
    data() {
        return {
            show: false,
            variables : [],
            orientation: 'left',
            isLeft: false,
            isTop: false,
            cursorPosition:0,
            srcInput: {},
            left:10,
            varPresent: false,
            errorText: "",
        };
    },
    created() {
        if(this.contexts && this.contexts.length > 0){
            let payload = {
                contexts : this.contexts
            }

            if(this.operationId)
                payload.operationId = this.operationId

            GenericDataService.postData('/template/getAvailableVariables', payload).then((response) => {
                this.variables = this.tradIfNeeded(response.data.data);
            })
        } else {
            GenericDataService.getData('/template/getAvailableVariables').then((response) => {
                this.variables = this.tradIfNeeded(response.data.data);
            })
        }
        if(this.model[this.fieldName] && this.model[this.fieldName].includes('{'))
            this.varPresent = true
       
    },
    methods: {
         tradIfNeeded(data){
            data.forEach(element => {
                if(element.to_trad){
                    if(element.hasOwnProperty('name'))
                        element.name = this.$t(element.name)
                    else
                        element.text = this.$t(element.text)
                }
                if(element.hasOwnProperty('items'))
                   element.items = this.tradIfNeeded(element.items)
            });
            return data
        },
        openTestMenu(){
            let elementId = 'inputVar_'+this.fieldName
            if(this.index){
                elementId = 'inputVar_'+this.fieldName+this.index
            }
            let fieldX = document.getElementById(elementId).getBoundingClientRect().x;
            let fullWidth = document.body.clientWidth;
            let fieldY = document.getElementById(elementId).getBoundingClientRect().y;
            let fullHeight = document.body.clientHeight;

            let percentTop = (fieldY*100)/fullHeight;
            if(percentTop > 55){
                 this.isTop = true
            } else {
                this.isTop = false
            }
            let percentLeft = (fieldX*100)/fullWidth;
            if(percentLeft > 75){
                this.isLeft = true
                this.left = 100
            } else {
                this.isLeft = false
                this.left = 10
            }
            setTimeout(() => {
                this.$refs.varMenu.show = true    
            }, 10);
        },
        inputVariable(e){
            if(e.key == '{'){
                this.varPresent = true
                this.errorText = ""
                this.cursorPosition = e.srcElement.selectionStart
                this.srcInput = e.srcElement
                setTimeout(() => {
                   this.openTestMenu() 
                }, 50);
            } 
            
        },
        testInput(e){
            if(this.varMode && this.hint && this.model[this.fieldName] && !this.model[this.fieldName].includes('{')){
                this.varPresent = false
                if(!this.model[this.fieldName].match(/[0-9a-zA-Z]{3,11}/)){
                    this.errorText = "formatNotValid"                
                } else if(this.model[this.fieldName].length <= 11){
                    this.errorText = ""                
                }
            }   

            if(this.checkMessageSender) {
                let resp = this.messageSender(e);
                if(resp !== true) {
                    this.$emit('disableSaveButton')
                } else {
                    this.$emit('enableSaveButton')
                }
            }
        },
        moveCursor(e){
            if(!this.blockMoveCursor)
                this.cursorPosition = e.srcElement.selectionStart
        },
        addVal($event) {
            let count = 0;
            let value = $event.value
            if(this.model[this.fieldName] && this.model[this.fieldName].length > 0){
                let firstPart = this.model[this.fieldName].substring(0, (this.cursorPosition - 1))
                let secondPart = this.model[this.fieldName].substring(this.cursorPosition)
                if(firstPart.match(/{+$/)){
                    firstPart = firstPart.replace(/{+$/, value)
                } else {
                    firstPart = firstPart+value
                }
                
                count = firstPart.length;
                this.model[this.fieldName] = firstPart+secondPart;
            } else {
                count = value.length

                this.model[this.fieldName] = value;
            }
            this.cursorPosition = count + 1
            setTimeout(() => {
                const ctrl =  document.getElementById('inputVar_'+this.fieldName)
                if (ctrl.setSelectionRange) {
                    ctrl.focus();
                    ctrl.setSelectionRange(count, count);
                    //ctrl.select();
                // IE8 and below
                } else if (ctrl.createTextRange) {
                    var range = ctrl.createTextRange();
                    range.collapse(true);
                    range.moveEnd('character', count);
                    range.moveStart('character', count);
                    range.select();
                }
                this.$refs.varMenu.show = false
                this.$forceUpdate();
            }, 50);
        },
    },
};
</script>

<style lang="scss">
.input-form {
    .v-input__slot {
        background-color: var(--v-lightgrey-base) !important;
    }
}
.variableInput {
    .v-input__slot {
        padding-right:3px !important;
    }
}
</style>