<template>
    <div style="width:100%; height: calc(100vh - 128px);">
        <div id="surveyCreator" />
        <Loader v-if="showLoader"></Loader>
        <MediathequeV2DialogComponent v-model="fileUrl" :openDialogMediatheque="dialogMediatheque" @close="closeDialogMediatheque" :returnUrl="true" />
    </div>
</template>

<script>
import MediathequeV2DialogComponent from '@/components/dialogs/MediathequeV2DialogComponent';
import GenericDataService from '@/services/GenericDataService';
import { SurveyCreator } from "survey-creator-knockout";
import { Serializer, slk, surveyLocalization } from "survey-core";
import { editorLocalization } from "survey-creator-core";
import "survey-creator-core/i18n/french";
import "survey-core/i18n/french";
import "survey-core/defaultV2.min.css";
import "survey-creator-core/survey-creator-core.min.css";
import Loader from '@/components/Loader.vue'

// Ajout de la clé de licence
slk(process.env.VUE_APP_SURVEY);
surveyLocalization.supportedLocales = ["fr"];
surveyLocalization.defaultLocale = "fr";
const frLocale = editorLocalization.getLocale("fr");
frLocale.tabs.designer = "Éditeur de formulaire";
frLocale.tabs.preview = "Tester le formulaire";
frLocale.p.widthMode = "Largeur du formulaire";
frLocale.pe.tabs.showOnCompleted = "Formulaire terminé";
frLocale.pe.tabs.logo = "Logo dans le titre du formulaire";
frLocale.pe.cookieName = "Nom du cookie (pour empêcher de compléter 2 fois le formulaire localement)";
frLocale.ed.surveyTypeName = "Formulaire";

export default {
    name: "formModelComponent",
    props: ["model", "fieldName", "field"], 
    components: { MediathequeV2DialogComponent, Loader },
    data() {
        return {
            fileUrl: "",
            dialogMediatheque: false,
            showLoader: false,
            creator: null,
            autosave: true,
        }
    },
    computed: {
        mediaUrl() {
            return this.$store.state.auth.currentUser.accountParameters.mediaUrl
        },
        apiKey() {
            return this.$store.state.auth.currentUser.accountParameters.mediaApiKey
        }
    },
    mounted() {
        this.showLoader = true;
        // Sans le timeout, le composant d'affiche qu'une fois le survey chargé
        setTimeout(() => {
            const options = {
                isAutoSave: true,
                showJSONEditorTab: false,
                questionTypes: ["text", "comment", "checkbox", "radiogroup", "dropdown", "rating", "tagbox"]
            };
            this.creator = new SurveyCreator(options);
            this.creator.locale = "fr";
            this.creator.text = JSON.stringify(this.model[this.fieldName]);
            this.creator.saveSurveyFunc = (saveNo, callback) => {
                this.model[this.fieldName] = this.creator.text;
                if(!this.autosave)
                    this.$emit('validateForm', true);
            };

            const vm = this;
            // this.creator.onUploadFile.add(function (_, options) {
            //     const reader = new FileReader();
            //     reader.onload = (e) => {
            //         let jsonData = {
            //             'title': options.files[0].name,
            //             'meta': {
            //                 "utilisation": "image_form"
            //             },
            //             'base64': e.target.result
            //         };

            //         GenericDataService.postData(vm.mediaUrl + "upload?key=" + vm.apiKey, jsonData).then((response) => {
            //             let url = response.data.media.url;
            //             if(url)
            //                 options.callback("success", url);
            //         });
            //     };
            //     reader.readAsDataURL(options.files[0]);
            // });

            this.creator.onOpenFileChooser.add(function (_, options) {
                vm.dialogMediatheque = true;
            });

            // Ajout d'un champ personnalisé
            GenericDataService.getData("/entity/getSelectList2").then((response) => {
                this.creator.toolbox.addItem({
                    name: "dropdown",
                    iconName: "icon-dropdown",
                    title: this.$t("contactEntity_idTitle"),
                    json: {
                        "name": "entity",
                        "title": this.$t("contactEntity_idTitle"),
                        "type": "dropdown",
                        "placeholder": this.$t("contactEntity_idTitle"),
                        "choices": response.data.data
                    }
                });
            })

            this.creator.render("surveyCreator");
            this.showLoader = false;
        }, 100);
    },
    watch: {
        fileUrl (newFileUrl) {
            this.creator.survey.logo = newFileUrl;
        }
    },
    methods: {
        closeDialogMediatheque: function(){
            this.dialogMediatheque = false;
        },
        saveSurvey: function() {
            this.autosave = false;
            this.creator.saveSurvey();
        },
    }
}

// Ajout des propriétés personnalisées
Serializer.addProperty("question", { name: "class", displayName: "Classes", type: "string", category: "general" });
Serializer.addProperty("question", { name: "data-attributes", displayName: "Attributs personnalisés", type: "itemvalues", category: "general" });
</script>

<style lang="scss">
    #surveyCreator {
        height: 100%;
        width: 100%;
    }
</style>