<template>
    <div class="col col-12 pa-0">
        <CustomVarComponent :model="model" :fieldName="fieldName" :field="field" />
        <v-expansion-panels depressed class="no-shadow" v-for="variableCat in variablesGlobal" :key="variableCat.name">
            <v-expansion-panel class="no-shadow">
                <v-expansion-panel-header><h4>{{variableCat.name}}</h4></v-expansion-panel-header>
                <v-expansion-panel-content>
                    <TemplateVariableRecursiveComponent @varsHaveChanged="refreshModel" :variables="variableCat.items"/>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>
import GenericDataService from '@/services/GenericDataService';
import TemplateVariableRecursiveComponent from '@/modules/templates/views/TemplateVariableRecursiveComponent';
import CustomVarComponent from './v-custom-variable-model.vue';
export default {
    components: { TemplateVariableRecursiveComponent, CustomVarComponent },
    props: ["model", "fieldName", "field"],
    data() {
        return {
            variables: [],
            variablesGlobal: [],
        };
    },
    created() {
        GenericDataService.getData("/template/getAvailableVariables").then((response) => {
            this.variables = response.data.data;

            let variablesGlobal = response.data.data;
            let model = this.model;
            let fieldName = this.fieldName;

            if(model && model[fieldName] && model[fieldName].values) {
                const callback_func = function(variables) {
                    variables.map(function(variable) {
                        if(variable.hasOwnProperty("items")) {
                            callback_func(variable.items);
                        } else {
                            variable.selected = Object.keys(model[fieldName].values).includes(variable.value.replace('{{', '').replace('}}', ''));
                        }
                    });
                }

                callback_func(variablesGlobal);
            }

            this.variablesGlobal = variablesGlobal;
            this.model[this.fieldName] = this.getVariablesSelected();
        });
    },
    methods: {
        refreshModel() {
            this.model[this.fieldName] = this.getVariablesSelected();
        },
        getVariablesSelected() {
            let array = [];
            let variablesGlobal = this.variables;

            const callback_func = function(variables) {
                variables.map(function(variable) {
                    if(variable.hasOwnProperty("items")) {
                        callback_func(variable.items);
                    } else {
                        if(variable.selected)
                            array.push(variable.value.replace('{{', '').replace('}}', ''));
                    }
                });
            }

            callback_func(variablesGlobal);

            return array;
        },
    },
};
</script>

<style lang="scss">
</style>