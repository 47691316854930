<template>
    <div class="form" style="overflow: auto; height: inherit">
        <v-skeleton-loader v-if="!form.schema" type="article,article, actions" ></v-skeleton-loader>
        
        <v-container fluid class="containerForm" v-else>
            <v-form ref="formQualif" v-model="valid" @submit.prevent="validateForm" :key="key">
                <div class="row ma-0 objectClass">
                    <div v-for="(section, sectionName, index) in form.schema.sections" :key="sectionName" :class="'row ma-0 pt-2' + (index != Object.keys(form.schema.sections).length - 1 ? ' custom_divider' : ' ')">
                        <span class="py-2 h5 mb-6" v-if="sectionName !== 'rdv'">{{section.title ? $t(section.title) : ''}} : <span class="pink--text">{{section.name}}</span></span>
                        <div :class="field.class ? field.class : 'col col-12 pa-0'" v-for="(field, fieldName) in section.fields" :key="fieldName" v-if="field.component && !field.hide">
                            <div class="row ma-0 objectClass" style="position:relative;">
                                <FormComponentList :field="field" :fieldName="fieldName" :model="form.model" :key="keys[fieldName]" :isFromLibraryPage="isFromLibraryPage" />
                                <p v-if="errorToggle && errorToggle[fieldName]" class="error--text test">{{ $t('requiredField') }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </v-form>

            <SuccessErrorAlertComponent v-if="$store.state.alerts.message" />

            <v-layout row class="mt-2">
                <v-spacer></v-spacer>
                <div class="registerForm">
                    <v-btn color="white" class="mt-1 mr-3" depressed outlined :loading="loader" @click="validateForm(true)">{{ $t("formSave") }}</v-btn>
                </div>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
import SuccessErrorAlertComponent from "@/components/alerts/SuccessErrorAlertComponent";
import { i18n } from "@/i18n";
import { mapActions } from "vuex";
import FormComponentList from "@/components/forms/FormComponentList";

export default {
    name: "FormComponent",
    components: {
        SuccessErrorAlertComponent,
        FormComponentList,
    },
    props: ["form", "action", "step", "saveAtInput", "isFromLibraryPage"],
    data() {
        return {
            publicPath:process.env.BASE_URL,
            valid: null,
            loader: false,
            key: 0,
            keys: [],
            errorToggle: []
        };
    },
    mounted() {
        this.showHideConditionalFields();
    },
    computed: {
        model: function () {
            return Object.assign({}, this.form.model);
        }
    },
    watch: {
        // Permet de supprimer la valeur du model lorsqu'une valeur dépend d'une autre (ex: Assignement => Vendeur attribué dépend du PDV)
        model: {
            handler(newModel, oldModel) {
                for (var fieldName in newModel) {
                    if(!oldModel.hasOwnProperty(fieldName) || oldModel[fieldName] !== newModel[fieldName]) {
                        for (var [sectionName, section] of Object.entries(this.form.schema.sections)) {
                            if(section.fields.hasOwnProperty(fieldName)){ // Permet de récupérer le champ qui vient d'être update
                                if(section.fields[fieldName].hasOwnProperty('depends_fields') || section.fields[fieldName].hasOwnProperty('conditional_fields')) {
                                    if(section.fields[fieldName].hasOwnProperty('depends_fields')) {
                                        for (var dependFieldName of section.fields[fieldName].depends_fields) {
                                            this.keys[dependFieldName] = this.keys[dependFieldName] ? this.keys[dependFieldName] + 1 : 1;
                                        }
                                    } else if(section.fields[fieldName].hasOwnProperty('conditional_fields')) {
                                        this.showHideConditionalFields();
                                    }
                                    this.$forceUpdate();
                                }
                            }
                        }
                    }
                }
                if(this.saveAtInput && !this.loader) {
                    this.validateForm(false, false, true);
                }
            },
            deep: true
        },
    },
    methods: {
        ...mapActions("alerts", ["setAlert"]),
        confirmAction(saveAndClose = false, url = false) {
            if(!url && !this.form.schema.options.posturl)
                this.$emit("saveForm", "closeDialog");
            else {
                this.$store
                    .dispatch(this.action, {
                        url: url ? url : this.form.schema.options.posturl,
                        data: this.form.model,
                    })
                    .then((response) => {
                        if(saveAndClose) {
                            response.saveAndClose = true;
                        } else if(this.step) {
                            response.nextStep = this.step + 1;
                        }
                        setTimeout(() => {
                            this.loader = false
                        }, 200);
                        this.$emit("saveForm", response);
                    }).catch((e) =>  {
                        if(e.data && e.data.errors) {
                            for (let errorKey in e.data.errors) {
                                for (let sectionKey in this.form.schema.sections) {
                                    for (let fieldKey in this.form.schema.sections[sectionKey].fields) {
                                        if(e.data.errors[errorKey].field === fieldKey) {
                                            this.form.schema.sections[sectionKey].fields[fieldKey].props['error'] = true;
                                            this.form.schema.sections[sectionKey].fields[fieldKey].props['error-messages'] = this.$t(e.data.errors[errorKey].message);
                                        }
                                    }
                                }
                            }
                            this.key += 1;
                        } else {
                            let errorMessage = e.data && e.data.errorMessage ? e.data.errorMessage : this.$t('errorAPIform');
                            this.setAlert({
                                message: errorMessage,
                                type: "error",
                                timeout: 3000
                            });
                        }
                        setTimeout(() => {
                            this.loader = false
                        }, 200);
                    });
            }
        },
        validateForm(saveAndClose = false, url = false, onlycheckErrors = false) {
            this.loader = true
            // Supprime les objects vides
            for (var fieldName in this.form.model) {
                let field = this.getFieldByFieldName(fieldName); // Récupère le field
                if(field && Array.isArray(this.form.model[fieldName])) {
                    this.form.model[fieldName] = this.form.model[fieldName].filter(function(value) {
                        // Check pour le VArray qu'on est bien tout les champs de remplis
                        if(field && field['component'] === 'VArray' && (Object.keys(value).length === 0 || Object.keys(value).some((k) => value[k] === "" || typeof(value[k]) === 'undefined')))
                            return false;
                        return true;
                    }, this);
                }
                if(field && field['component'] === 'VBtnToggle' && field.props.required){
                    let ToggleValid = false;
                    field.properties.forEach(property => {
                        if(this.form.model[fieldName] == property.value){
                            ToggleValid = true;
                        }
                    });
                    if(!ToggleValid){
                        this.errorToggle[fieldName] = true;
                        this.loader = false
                    } else {
                        if(this.errorToggle.hasOwnProperty(fieldName)){
                            delete this.errorToggle[fieldName];
                        }
                    }
                        
                }
            }
            if(Object.keys(this.errorToggle).length > 0){
                this.loader = false
               
                return false
            }
            if (this.$refs.formQualif.validate() && Object.keys(this.errorToggle).length == 0 && !onlycheckErrors) {
                this.confirmAction(saveAndClose, url)
            } else {
                this.loader = false
            }
        },
        getFieldByFieldName(fieldName) {
            for (var [sectionName, section] of Object.entries(this.form.schema.sections)) {
                if(section.fields.hasOwnProperty(fieldName)){ // Permet de récupérer le champ qui vient d'être update
                    return section.fields[fieldName];
                }
            }
            return null;
        },
        showHideConditionalFields() {
            let forcedHide = [];
            
            for (var [sectionName, section] of Object.entries(this.form.schema.sections)) {
                if(section.hasOwnProperty('fields')) {
                    for (var [fieldName, field] of Object.entries(section.fields)) {
                        if(field.hasOwnProperty('conditional_fields')) {
                            for (var [conditionalFieldsSectionName, conditionalFieldsSection] of Object.entries(field.conditional_fields)) {
                                for (var conditionalField of conditionalFieldsSection) {
                                    if(this.form.schema.sections[conditionalFieldsSectionName] && this.form.schema.sections[conditionalFieldsSectionName].hasOwnProperty('fields') && this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField]) {
                                        if(this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField].hasOwnProperty('conditional_of')) {
                                            var fieldCanBeVisible = true;
                                            for (var conditionalOf in this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField].conditional_of) {
                                                if(conditionalOf === fieldName) {
                                                    // Check sur la value
                                                    let fieldNameCondition = 'value'; // valeur par défaut
                                                    if(this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField].conditional_of[conditionalOf].hasOwnProperty('field')){ //si field est défini on remplace value par un autre champ
                                                        fieldNameCondition = this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField].conditional_of[conditionalOf].field
                                                    }
                                                    if(this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField].conditional_of[conditionalOf].hasOwnProperty(fieldNameCondition)) {
                                                        if(typeof this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField].conditional_of[conditionalOf][fieldNameCondition] === 'string') {
                                                            let valueModel = typeof this.model[fieldName] === 'object' && this.model[fieldName] !== null ? (this.model[fieldName][fieldNameCondition] ? this.model[fieldName][fieldNameCondition] : null) : this.model[fieldName];
                                                            if(valueModel !== this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField].conditional_of[conditionalOf][fieldNameCondition]) {
                                                                fieldCanBeVisible = false;
                                                            }
                                                        } else if (typeof this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField].conditional_of[conditionalOf][fieldNameCondition] === 'object') {
                                                            let valueModel = typeof this.model[fieldName] === 'object' && this.model[fieldName] !== null ? (this.model[fieldName][fieldNameCondition] ? this.model[fieldName][fieldNameCondition] : null) : this.model[fieldName];
                                                            if(!this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField].conditional_of[conditionalOf][fieldNameCondition].includes(valueModel)) {
                                                                fieldCanBeVisible = false;
                                                            }
                                                        } else if (typeof this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField].conditional_of[conditionalOf][fieldNameCondition] === 'boolean') {
                                                            let valueModel = typeof this.model[fieldName] === 'object' && this.model[fieldName] !== null ? (this.model[fieldName][fieldNameCondition] ? this.model[fieldName][fieldNameCondition] : null) : this.model[fieldName];

                                                            if(valueModel !== this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField].conditional_of[conditionalOf][fieldNameCondition]) {
                                                                if(typeof valueModel !== "undefined" || (typeof valueModel === "undefined" && this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField].conditional_of[conditionalOf][fieldNameCondition] === true)) {
                                                                    fieldCanBeVisible = false;
                                                                }
                                                            }
                                                        }
                                                    } else if(Array.isArray(this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField].conditional_of[conditionalOf])) {
                                                        let neededValues = this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField].conditional_of[conditionalOf].reduce(function (filtered, field) {
                                                            if(field.value)
                                                                filtered.push(field.value);
                                                            return filtered;
                                                        }, []);

                                                        if(!neededValues.includes(this.model[fieldName]))
                                                            fieldCanBeVisible = false;
                                                    }
                                                }
                                            }
                                            if(!fieldCanBeVisible || forcedHide[conditionalField] === true) {
                                                this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField].hide = true;
                                                forcedHide[conditionalField] = true;
                                            } else {
                                                this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField].hide = false;
                                            }
                                        } else if(this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField].hasOwnProperty('conditional_property')){
                                            this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField].properties.forEach(property => {
                                                if(property.hasOwnProperty('conditional_of')){
                                                    var propertyCanBeVisible = true;
                                                    for (var conditionalOf in property.conditional_of){

                                                        if(conditionalOf === fieldName) {
                                                            let fieldNameCondition = 'value';
                                                            if(property.conditional_of[conditionalOf].hasOwnProperty('value')){
                                                                if(typeof property.conditional_of[conditionalOf][fieldNameCondition] === 'string') {
                                                                    if(this.model[fieldName] !== property.conditional_of[conditionalOf][fieldNameCondition]) {
                                                                        propertyCanBeVisible = false;
                                                                    }
                                                                } else if (typeof property.conditional_of[conditionalOf][fieldNameCondition] === 'object') {
                                                                    if(!property.conditional_of[conditionalOf][fieldNameCondition].includes(this.model[fieldName])) {
                                                                        propertyCanBeVisible = false;
                                                                    }
                                                                } else if (typeof property.conditional_of[conditionalOf][fieldNameCondition] === 'boolean') {
                                                                    if(this.model[fieldName] !== property.conditional_of[conditionalOf][fieldNameCondition]) {
                                                                        if(typeof this.model[fieldName] !== "undefined" || (typeof this.model[fieldName] === "undefined" && property.conditional_of[conditionalOf][fieldNameCondition] === true)) {
                                                                            propertyCanBeVisible = false;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                    if(!fieldCanBeVisible) {
                                                        property.hide = true;
                                                    } else {
                                                        property.hide = false;
                                                    }
                                                }
                                            });
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    },
};
</script>

<style lang="scss">
    .form {
        .containerForm {
            overflow: hidden !important;
        }
        .section {
            .title {
                width: 100%;
                display: inline-block;
            }
        }
        .required {
            label.v-label:after {
                content: '*';
                color: var(--v-error-base);
                margin-left: 5px;
            }
        }
        .registerForm {
            position:absolute;
            right:13px;
            top:10px;
        }
        .custom_divider {
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        }
    }
</style>
